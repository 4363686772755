// src/components/ManagerDashboard/ManagerElements/ProjectList.tsx

import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { apiEndpoints } from "../../../../utils/apiEndpoints";
import { callApi } from "../../../../utils/apiHelper";
import { Project, RawMaterial, Sku, Transaction, User } from "./types";
import ProjectList from "./projectList";
interface ProjectComponentProps {
  users: User[];
  skus: Sku[];
  rawMaterials: RawMaterial[];
}

const ProjectComponent: React.FC<ProjectComponentProps> = ({
  users,
  skus,
  rawMaterials,
}) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const fetchProjectInfo = async () => {
    try {
      const response = await callApi<Project[]>(
        apiEndpoints.getProjectInfo,
        "GET",
      );
      if (response.data) {
        console.log("Project Info:", response.data);
        setProjects((response.data as any).projects);
      } else if (response.error) {
        console.error("Error fetching project info:", response.error);
      }
    } catch (error) {
      console.error("Error in fetchProjectInfo:", error);
    }
  };

  useEffect(() => {
    fetchProjectInfo();
  }, []);
  return (
    <ProjectList
      users={users}
      skus={skus}
      rawMaterials={rawMaterials}
      projects={projects}
    />
  );
};
export default ProjectComponent;
