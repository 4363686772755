import React, { useState } from "react";
import styled from "styled-components";
import { ApiRawMaterial, User, Notification } from "./types";
import { callApi } from "../../../utils/apiHelper";
import { apiEndpoints } from "../../../utils/apiEndpoints";

interface WastageIncreaseNotificationProps {
  notification: Notification;
  allRawMaterials: ApiRawMaterial[];
  allUsers: User[];
  onProcessed: () => void;
}

const WastageIncreaseNotification: React.FC<
  WastageIncreaseNotificationProps
> = ({ notification, allRawMaterials, allUsers, onProcessed }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});

  const dataDump = JSON.parse(notification.DataDump);
  const requestType = dataDump.RequestType;
  const projectId = dataDump.ProjectId;
  const requestedBy =
    allUsers.find((user) => user.UserId === dataDump.RequestedBy)?.FullName ||
    "Unknown";

  const handleQuantityChange = (rawMaterialId: string, value: string) => {
    setQuantities((prev) => ({
      ...prev,
      [rawMaterialId]: Number(value),
    }));
  };

  const handleSubmit = async (accept: boolean) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await callApi(
        apiEndpoints.NulookAcceptRejectWasteRequest,
        "POST",
        {
          NotificationId: notification.NotificationId,
          accept,
          raw_materials: quantities,
        },
      );

      if (response.error) {
        throw new Error(response.error.message);
      }

      onProcessed();
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <h3>
        {requestType === "increase wastage" ? "Increase" : "Decrease"} Wastage
        Request
      </h3>
      <p>Project ID: {projectId}</p>
      <p>Requested By: {requestedBy}</p>
      <MaterialList>
        {dataDump.RawMaterials.map(
          (material: { RawMaterialId: string; Quantity: number }) => {
            const rawMaterial = allRawMaterials.find(
              (rm) => rm.RawMaterialId === material.RawMaterialId,
            );
            return (
              <MaterialItem key={material.RawMaterialId}>
                <span>{rawMaterial?.Name || "Unknown Material"}</span>
                <span>
                  Requested: {material.Quantity} {rawMaterial?.BaseUnit}
                </span>
                <Input
                  type="number"
                  min="0"
                  max={material.Quantity}
                  value={quantities[material.RawMaterialId] || ""}
                  onChange={(e) =>
                    handleQuantityChange(material.RawMaterialId, e.target.value)
                  }
                  placeholder="Enter quantity"
                />
              </MaterialItem>
            );
          },
        )}
      </MaterialList>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <ButtonGroup>
        <Button onClick={() => handleSubmit(true)} disabled={isLoading}>
          Accept
        </Button>
        <Button onClick={() => handleSubmit(false)} disabled={isLoading}>
          Reject
        </Button>
      </ButtonGroup>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
`;

const MaterialList = styled.div`
  margin-top: 10px;
`;

const MaterialItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

export default WastageIncreaseNotification;
