import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  createTheme,
  ThemeProvider,
  Box,
  CircularProgress,
} from "@mui/material";
import { Sku } from "./types";

interface CartTabProps {
  cart: Sku[];
  placeOrder: () => Promise<void>;
}

const blackAndWhiteTheme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
    text: {
      primary: "#000000",
      secondary: "#404040",
    },
  },
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
          "&:hover": {
            boxShadow:
              "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
          },
        },
      },
    },
  },
});

const CartTab: React.FC<CartTabProps> = ({ cart, placeOrder }) => {
  const [isLoading, setIsLoading] = useState(false);

  const calculateTotal = () => {
    return cart.reduce(
      (total, item) => total + item.Price * item.orderQuantity,
      0,
    );
  };

  const handlePlaceOrder = async () => {
    setIsLoading(true);
    try {
      await placeOrder();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={blackAndWhiteTheme}>
      <Box sx={{ textAlign: "center", position: "relative" }}>
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {cart.length === 0 ? (
          <Box sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h6" color="textSecondary">
              Your cart is empty. Add some items to place an order.
            </Typography>
          </Box>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Subtotal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cart.map((item) => (
                    <TableRow key={item.SkuId}>
                      <TableCell component="th" scope="row">
                        {item.Name}
                      </TableCell>
                      <TableCell align="right">₹{item.Price}</TableCell>
                      <TableCell align="right">{item.orderQuantity}</TableCell>
                      <TableCell align="right">
                        ₹{(item.Price * item.orderQuantity).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ mt: 2, textAlign: "right" }}>
              <Typography variant="h6">
                Total: ₹{calculateTotal().toFixed(2)}
              </Typography>
            </Box>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handlePlaceOrder}
                disabled={isLoading}
              >
                {isLoading ? "Placing Order..." : "Place Order"}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default CartTab;
