import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { callApi } from "../../../utils/apiHelper";
import { apiEndpoints } from "../../../utils/apiEndpoints";
import PurchaseOrderNotification from "./PurchaseOrderNotification";
import RawMaterialRequestNotification from "./RawMaterialRequestNotification";
import RawMaterialReturnNotification from "./RawMaterialReturnNotification";
import { Notification, ApiRawMaterial, User } from "./types";

interface NotificationProcessingProps {
  allRawMaterials: ApiRawMaterial[];
}

const NotificationProcessing: React.FC<NotificationProcessingProps> = ({
  allRawMaterials,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchNotifications();
    fetchAllUsers();
  }, []);

  const fetchNotifications = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await callApi(
        apiEndpoints.NulookGetUserNotifications,
        "GET",
      );
      let notifications = (response.data as any).notifications;
      if (notifications && Array.isArray(notifications)) {
        setNotifications(notifications);
      } else {
        setError("Invalid response format");
      }
    } catch (error) {
      setError("Error fetching notifications");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const response = await callApi(apiEndpoints.getAllVisibleUsers, "POST", {
        operation: "getAllVisibleUsers",
      });
      if (response.data) {
        setAllUsers((response.data as any).users);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getNotificationType = (dataDump: string): string => {
    try {
      const parsedData = JSON.parse(dataDump);
      if ("PurchaseOrderId" in parsedData) {
        return "Accept Purchase Order";
      } else if (parsedData.RequestType === "RawMaterialRequest") {
        return "Raw Material Request";
      } else if (parsedData.RequestType === "ReturnRawMaterials") {
        return "Raw Material Return";
      }
      return "Unknown";
    } catch {
      return "Invalid";
    }
  };

  return (
    <Container>
      <h2>Warehouse Notifications</h2>
      {isLoading && <LoadingMessage>Loading...</LoadingMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {notifications.length === 0 && !isLoading && (
        <NoNotificationsMessage>
          No notifications available
        </NoNotificationsMessage>
      )}
      {notifications.map((notification) => {
        const notificationType = getNotificationType(notification.DataDump);
        switch (notificationType) {
          case "Accept Purchase Order":
            return (
              <PurchaseOrderNotification
                key={notification.NotificationId}
                notification={notification}
                allRawMaterials={allRawMaterials}
                allUsers={allUsers}
                onProcessed={() => {
                  // Refresh notifications or update state as needed
                  fetchNotifications();
                }}
              />
            );
          case "Raw Material Request":
            return (
              <RawMaterialRequestNotification
                key={notification.NotificationId}
                notification={notification}
                allRawMaterials={allRawMaterials}
                allUsers={allUsers}
                onProcessed={() => {
                  // Refresh notifications or update state as needed
                  fetchNotifications();
                }}
              />
            );
          case "Raw Material Return":
            return (
              <RawMaterialReturnNotification
                key={notification.NotificationId}
                notification={notification}
                allRawMaterials={allRawMaterials}
                allUsers={allUsers}
                onProcessed={() => {
                  // Refresh notifications or update state as needed
                  fetchNotifications();
                }}
              />
            );
          default:
            return null;
        }
      })}
    </Container>
  );
};

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem;
`;

const LoadingMessage = styled.p`
  text-align: center;
  font-style: italic;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const NoNotificationsMessage = styled.p`
  text-align: center;
  font-style: italic;
`;

export default NotificationProcessing;
