import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  CardMedia,
  Box,
  MobileStepper,
  styled,
  IconButton,
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { Sku } from "./types";

interface SkuCardProps {
  parentId: string;
  skus: Sku[];
  quantities: {
    [key: string]: { orderQuantity: number; orderBQuantity: number };
  };
  handleQuantityChange: (
    skuId: string,
    type: "orderQuantity" | "orderBQuantity",
    value: number,
  ) => void;
  handleExpandImage: (skuId: string, index: number) => void;
  selectedChildSku: { [key: string]: string };
  handleChildSkuSelect: (parentId: string, childSkuId: string) => void;
}

const SkuCard: React.FC<SkuCardProps> = ({
  parentId,
  skus,
  quantities,
  handleQuantityChange,
  handleExpandImage,
  selectedChildSku,
  handleChildSkuSelect,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const touchStartX = React.useRef<number | null>(null);
  const touchEndX = React.useRef<number | null>(null);

  const parentSku = skus[0];
  const childSkus = skus.slice(1);
  const hasChildSkus = childSkus.length > 0;

  const selectedSku = hasChildSkus
    ? skus.find((sku) => sku.SkuId === selectedChildSku[parentId]) ||
      childSkus[0]
    : parentSku;

  const displayImages =
    selectedSku.Images.length > 0 ? selectedSku.Images : parentSku.Images;

  const handleNext = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep + 1) % displayImages.length,
    );
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) =>
        (prevActiveStep - 1 + displayImages.length) % displayImages.length,
    );
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current !== null && touchEndX.current !== null) {
      const diff = touchStartX.current - touchEndX.current;
      if (Math.abs(diff) > 50) {
        if (diff > 0) {
          handleNext();
        } else {
          handleBack();
        }
      }
    }
    touchStartX.current = null;
    touchEndX.current = null;
  };

  return (
    <StyledCard elevation={3}>
      <CardMediaContainer>
        <SwipeableCardMedia
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onClick={() => handleExpandImage(parentId, activeStep)}
        >
          <CardMedia
            component="img"
            height="200"
            image={
              displayImages[activeStep] || "https://via.placeholder.com/200"
            }
            alt={selectedSku.Name}
          />
        </SwipeableCardMedia>
        <ExpandButton
          onClick={() => handleExpandImage(parentId, activeStep)}
          size="small"
        >
          <ZoomInIcon />
        </ExpandButton>
        {displayImages.length > 1 && (
          <StyledMobileStepper
            steps={displayImages.length}
            position="static"
            activeStep={activeStep}
            nextButton={
              <IconButton size="small" onClick={handleNext}>
                <KeyboardArrowRight />
              </IconButton>
            }
            backButton={
              <IconButton size="small" onClick={handleBack}>
                <KeyboardArrowLeft />
              </IconButton>
            }
          />
        )}
      </CardMediaContainer>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {parentSku.Name}
        </Typography>
        <Typography variant="body2" color="text.secondary" paragraph>
          {parentSku.Description}
        </Typography>

        {hasChildSkus && (
          <AttributesContainer>
            {childSkus.map((sku) => (
              <AttributeBox
                key={sku.SkuId}
                isSelected={selectedChildSku[parentId] === sku.SkuId}
                onClick={() => handleChildSkuSelect(parentId, sku.SkuId)}
              >
                {Object.entries(sku.Attributes).map(([key, value]) => (
                  <AttributeItem key={key}>
                    <AttributeKey>{key}:</AttributeKey>
                    <AttributeValue>{value}</AttributeValue>
                  </AttributeItem>
                ))}
              </AttributeBox>
            ))}
          </AttributesContainer>
        )}

        <SkuItem>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6" color="primary">
              ₹{selectedSku.Price.toFixed(2)}
            </Typography>
            {selectedSku.NewRelease && (
              <NewReleaseTag>New Release</NewReleaseTag>
            )}
          </Box>
          <TextField
            type="number"
            label="Quantity"
            size="small"
            fullWidth
            value={quantities[selectedSku.SkuId]?.orderQuantity || 0}
            onChange={(e) =>
              handleQuantityChange(
                selectedSku.SkuId,
                "orderQuantity",
                parseInt(e.target.value) || 0,
              )
            }
            InputProps={{
              inputProps: {
                min: 0,
                max: selectedSku.Quantity - selectedSku.PendingOrderQuantity,
                step: 1,
              },
            }}
          />
          {selectedSku.Quantity - selectedSku.PendingOrderQuantity <= 0 && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              Out of Stock
            </Typography>
          )}
        </SkuItem>
      </CardContent>
    </StyledCard>
  );
};

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: theme.shadows[10],
  },
}));

const CardMediaContainer = styled(Box)({
  position: "relative",
});

const SwipeableCardMedia = styled(Box)({
  touchAction: "pan-y",
  userSelect: "none",
  cursor: "pointer",
});

const ExpandButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: 8,
  right: 8,
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  color: theme.palette.grey[900],
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
}));

const StyledMobileStepper = styled(MobileStepper)(({ theme }) => ({
  backgroundColor: "transparent",
  position: "absolute",
  bottom: 0,
  width: "100%",
  "& .MuiMobileStepper-dot": {
    backgroundColor: theme.palette.grey[300],
  },
  "& .MuiMobileStepper-dotActive": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const SkuItem = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  paddingTop: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const NewReleaseTag = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.common.black}`,
  color: theme.palette.common.black,
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  fontSize: "0.75rem",
  fontWeight: "bold",
  textTransform: "uppercase",
  letterSpacing: "0.5px",
}));

const AttributesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const AttributeBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  border: `1px solid ${isSelected ? theme.palette.primary.main : theme.palette.divider}`,
  backgroundColor: isSelected ? theme.palette.action.selected : "transparent",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  cursor: "pointer",
  transition: theme.transitions.create(["border-color", "background-color"]),
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
}));

const AttributeItem = styled(Box)({
  display: "flex",
  fontSize: "0.875rem",
});

const AttributeKey = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  marginRight: theme.spacing(0.5),
}));

const AttributeValue = styled("span")(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export default SkuCard;
