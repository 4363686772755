import React, { useState, useEffect } from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { Home, Inventory, Notifications } from "@mui/icons-material";
import styled from "styled-components";
import { callApi, logout } from "../../utils/apiHelper";
import { apiEndpoints } from "../../utils/apiEndpoints";
import CustomerOrdersSection from "./CustomerOrdersSection";
import ProjectPassingRequestsSection from "./ProjectPassingRequestsSection";
import { CustomerOrder, Sku, User, Notification } from "./types";
import GodownInventorySection from "./inventory";

const GodownDashboard: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [customerOrders, setCustomerOrders] = useState<CustomerOrder[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [skus, setSkus] = useState<Sku[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const companyName = process.env.REACT_APP_COMPANY_NAME || "Company Name";

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    setLoading(true);
    setError(null);
    try {
      await Promise.all([
        fetchCustomerOrders(),
        fetchNotifications(),
        fetchSkus(),
        fetchUsers(),
      ]);
    } catch (err) {
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomerOrders = async () => {
    const response = await callApi<{ orders: CustomerOrder[] }>(
      apiEndpoints.GodownManageCustomerOrder,
      "POST",
      { operation: "get_orders" },
    );
    if (response.data) {
      setCustomerOrders(
        response.data.orders.filter((order) => order.Status === "pending"),
      );
    } else if (response.error) {
      throw new Error(response.error.message);
    }
  };

  const fetchNotifications = async () => {
    const response = await callApi<{ notifications: Notification[] }>(
      apiEndpoints.NulookGetUserNotifications,
    );
    if (response.data) {
      const projectPassingRequests = response.data.notifications.filter(
        (notification) =>
          JSON.parse(notification.DataDump).Type === "PassProject",
      );
      setNotifications(projectPassingRequests);
    } else if (response.error) {
      throw new Error(response.error.message);
    }
  };

  const fetchSkus = async () => {
    const response = await callApi<{ skus: Sku[] }>(
      apiEndpoints.NulookGetSku,
      "POST",
      {
        operation: "get_godown_wise_sku",
      },
    );
    if (response.data) {
      setSkus(response.data.skus);
    } else if (response.error) {
      throw new Error(response.error.message);
    }
  };

  const fetchUsers = async () => {
    const response = await callApi<{ users: User[] }>(
      apiEndpoints.getAllVisibleUsers,
    );
    if (response.data) {
      setUsers(response.data.users);
    } else if (response.error) {
      throw new Error(response.error.message);
    }
  };

  const handleOrderAction = async (
    orderId: string,
    accept: boolean,
    skulist?: Array<{ SkuId: string; Quantity: number; BQuantity: number }>,
    billNo?: string,
    billImage?: string,
  ) => {
    try {
      const payload = {
        operation: "accept_reject_order",
        CustomerOrderReturnId: orderId,
        accept,
        ...(accept && { skulist, billNo, billImage }),
      };
      const response = await callApi(
        apiEndpoints.GodownManageCustomerOrder,
        "POST",
        payload,
      );
      if (response.error) {
        setError(response.error.message);
      } else {
        fetchCustomerOrders();
      }
    } catch (err) {
      setError("Failed to process order");
    }
  };

  const handleProjectPassingRequest = async (
    notificationId: string,
    accept: boolean,
  ) => {
    try {
      const response = await callApi(
        apiEndpoints.GodownAcceptPassProject,
        "POST",
        { NotificationId: notificationId, accept },
      );
      if (response.error) {
        setError(response.error.message);
      } else {
        fetchNotifications();
      }
    } catch (err) {
      setError("Failed to process project passing request");
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      // Redirect to login page or perform any other necessary actions
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <DashboardContainer>
      <StyledHeader>
        <CompanyName>{companyName}</CompanyName>
        <NavMenu>
          <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        </NavMenu>
      </StyledHeader>
      <ContentArea>
        {currentTab === 0 && (
          <div>
            <CustomerOrdersSection
              orders={customerOrders}
              onOrderAction={handleOrderAction}
              skus={skus}
              users={users}
            />
          </div>
        )}
        {currentTab === 1 && (
          <div>
            <GodownInventorySection skus={skus} />
          </div>
        )}
        {currentTab === 2 && (
          <div>
            <ProjectPassingRequestsSection
              notifications={notifications}
              onRequestAction={handleProjectPassingRequest}
              users={users}
            />
          </div>
        )}
      </ContentArea>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={currentTab}
          onChange={(event, newValue) => {
            setCurrentTab(newValue);
          }}
          style={{ backgroundColor: "#f5f5f5" }}
        >
          <BottomNavigationAction label="Orders" icon={<Home />} />
          <BottomNavigationAction label="Inventory" icon={<Inventory />} />
          <BottomNavigationAction label="Requests" icon={<Notifications />} />
        </BottomNavigation>
      </Paper>
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentArea = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  padding-bottom: 56px; // Height of the bottom navigation
`;

const StyledHeader = styled.header`
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompanyName = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #000;
`;

const NavMenu = styled.nav`
  display: flex;
  gap: 1rem;
`;

const LogoutButton = styled.button`
  background-color: #000;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;

  &:hover {
    background-color: #333;
  }
`;

export default GodownDashboard;
