import { callApi } from "./apiHelper";
import { apiEndpoints } from "./apiEndpoints";

interface User {
  // Define user properties
}

interface RawMaterial {
  // Define raw material properties
}

interface SKU {
  // Define SKU properties
}

interface Notification {
  // Define notification properties
}

interface UserRoleResponse {
  Role: string;
}

export async function getVisibleCompanyUsers() {
  return callApi<User[]>(apiEndpoints.NulookErpManageUsers);
}

export async function getVisibleRawMaterials() {
  return callApi<RawMaterial[]>(apiEndpoints.NulookRawMaterialManagement);
}

export async function getVisibleSKUs() {
  return callApi<SKU[]>(apiEndpoints.NulookGetSku);
}

export async function getNotifications() {
  return callApi<Notification[]>(apiEndpoints.NulookGetUserNotifications);
}

export async function getUserRole() {
  try {
    const response = await callApi<UserRoleResponse>(
      apiEndpoints.NulookGetUserRole,
    );

    if (response.error) {
      throw new Error(response.error.message);
    }

    if (!response.data) {
      throw new Error("No data received from the server");
    }

    return response.data.Role;
  } catch (error) {
    console.error("Error fetching user role:", error);
    throw error;
  }
}
