import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { callApi } from "../../../../utils/apiHelper";
import { apiEndpoints } from "../../../../utils/apiEndpoints";
import { RawMaterial, User, WarehouseStorage } from "./types";

interface WarehouseProps {
  users: User[];
  rawMaterials: RawMaterial[];
}

type SortField = "updatedAt" | "quantity";
type SortOrder = "asc" | "desc";

const Warehouse: React.FC<WarehouseProps> = ({ users, rawMaterials }) => {
  const [rawMaterialStock, setRawMaterialStock] = useState<WarehouseStorage[]>(
    [],
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<SortField>("updatedAt");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");

  async function getRawMaterialStock() {
    try {
      setLoading(true);
      const response = await callApi<any>(apiEndpoints.DataFetcher, "POST", {
        operation: "getRawMaterialStock",
      });
      if (response.data) {
        setRawMaterialStock(response.data.data);
      } else if (response.error) {
        throw new Error(response.error.message);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getRawMaterialStock();
  }, []);

  const getRawMaterialInfo = (rawMaterialId: string) => {
    const rawMaterial = rawMaterials.find(
      (rm) => rm.RawMaterialId === rawMaterialId,
    );
    return rawMaterial
      ? { name: rawMaterial.Name, baseUnit: rawMaterial.BaseUnit }
      : { name: "Unknown Raw Material", baseUnit: "N/A" };
  };

  const getWarehouseName = (warehouseId: string) => {
    const user = users.find((u) => u.UserId === warehouseId);
    return user ? user.FullName : warehouseId;
  };

  const formatDate = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  const sortedAndFilteredStock = useMemo(() => {
    let filteredStock = rawMaterialStock.filter((stock) => {
      const searchTermLower = searchTerm.toLowerCase();
      const { name } = getRawMaterialInfo(stock.RawMaterialId);
      const rawMaterialNameMatch = name.toLowerCase().includes(searchTermLower);
      const warehouseNameMatch = getWarehouseName(stock.WarehouseId)
        .toLowerCase()
        .includes(searchTermLower);
      return rawMaterialNameMatch || warehouseNameMatch;
    });

    return filteredStock.sort((a, b) => {
      const compareValues = (valA: any, valB: any) => {
        if (valA < valB) return sortOrder === "asc" ? -1 : 1;
        if (valA > valB) return sortOrder === "asc" ? 1 : -1;
        return 0;
      };

      switch (sortField) {
        case "updatedAt":
          return compareValues(a.UpdatedAt, b.UpdatedAt);
        case "quantity":
          return compareValues(a.Quantity, b.Quantity);
        default:
          return 0;
      }
    });
  }, [rawMaterialStock, searchTerm, sortField, sortOrder, rawMaterials, users]);

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const SortButton = ({
    field,
    children,
  }: {
    field: SortField;
    children: React.ReactNode;
  }) => (
    <SortButtonStyled onClick={() => handleSort(field)}>
      {children} {sortField === field && (sortOrder === "asc" ? "▲" : "▼")}
    </SortButtonStyled>
  );

  if (loading) {
    return <div>Loading warehouse stock...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <WarehouseContainer>
      <h2>Warehouse Stock</h2>
      <SearchBar>
        <input
          type="text"
          placeholder="Search by Raw Material Name or Warehouse Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchBar>
      {sortedAndFilteredStock.length === 0 ? (
        <p>No stock found.</p>
      ) : (
        <ResponsiveTableContainer>
          <ResponsiveTable>
            <thead>
              <tr>
                <th>
                  <SortButton field="updatedAt">Updated At</SortButton>
                </th>
                <th>Raw Material Name</th>
                <th>Base Unit</th>
                <th>Warehouse Name</th>
                <th>
                  <SortButton field="quantity">Quantity</SortButton>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedAndFilteredStock.map((stock) => {
                const { name, baseUnit } = getRawMaterialInfo(
                  stock.RawMaterialId,
                );
                return (
                  <tr key={stock.RawMaterialStorageId}>
                    <td data-label="Updated At">
                      {formatDate(stock.UpdatedAt)}
                    </td>
                    <td data-label="Raw Material Name">{name}</td>
                    <td data-label="Base Unit">{baseUnit}</td>
                    <td data-label="Warehouse Name">
                      {getWarehouseName(stock.WarehouseId)}
                    </td>
                    <td data-label="Quantity">
                      {stock.Quantity} {baseUnit}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </ResponsiveTable>
        </ResponsiveTableContainer>
      )}
    </WarehouseContainer>
  );
};

const WarehouseContainer = styled.div`
  margin-top: 2rem;
  color: #333;
  font-family: Arial, sans-serif;
`;

const SearchBar = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 600px;

  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      font-size: 0.9rem;
      padding: 0.4rem;
    }
  }
`;

const ResponsiveTableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background-color: #fff;
  font-size: 0.9rem;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f8f8f8;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;

    th,
    td {
      padding: 6px;
    }
  }
`;

const SortButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export default Warehouse;
