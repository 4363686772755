import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { callApi } from "../../../../utils/apiHelper";
import { apiEndpoints } from "../../../../utils/apiEndpoints";
import { EntryExitPerforma, User } from "./types";

interface EntryExitPerformaComponentProps {
  users: User[];
}

type SortField = "createdAt" | "type";
type SortOrder = "asc" | "desc";

const EntryExitPerformaComponent: React.FC<EntryExitPerformaComponentProps> = ({
  users,
}) => {
  const [allPerformas, setAllPerformas] = useState<EntryExitPerforma[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<SortField>("createdAt");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");

  async function getAllPerformas() {
    try {
      setLoading(true);
      const response = await callApi<any>(apiEndpoints.DataFetcher, "POST", {
        operation: "getEntryExitPerformas",
      });
      if (response.data) {
        setAllPerformas(response.data.data);
      } else if (response.error) {
        throw new Error(response.error.message);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllPerformas();
  }, []);

  const formatDate = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  const getUserName = (userId: string) => {
    const user = users.find((u) => u.UserId === userId);
    return user ? user.FullName : userId;
  };

  const sortedAndFilteredPerformas = useMemo(() => {
    let filteredPerformas = allPerformas.filter((performa) => {
      const searchTermLower = searchTerm.toLowerCase();
      return (
        performa.Type.toLowerCase().includes(searchTermLower) ||
        getUserName(performa.CreatedBy).toLowerCase().includes(searchTermLower)
      );
    });

    return filteredPerformas.sort((a, b) => {
      const compareValues = (valA: any, valB: any) => {
        if (valA < valB) return sortOrder === "asc" ? -1 : 1;
        if (valA > valB) return sortOrder === "asc" ? 1 : -1;
        return 0;
      };

      switch (sortField) {
        case "createdAt":
          return compareValues(a.CreatedAt, b.CreatedAt);
        case "type":
          return compareValues(a.Type, b.Type);
        default:
          return 0;
      }
    });
  }, [allPerformas, searchTerm, sortField, sortOrder, users]);

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const SortButton = ({
    field,
    children,
  }: {
    field: SortField;
    children: React.ReactNode;
  }) => (
    <SortButtonStyled onClick={() => handleSort(field)}>
      {children} {sortField === field && (sortOrder === "asc" ? "▲" : "▼")}
    </SortButtonStyled>
  );

  if (loading) {
    return <div>Loading entry/exit performas...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <EntryExitPerformaContainer>
      <h2>Entry/Exit Performas</h2>
      <SearchBar>
        <input
          type="text"
          placeholder="Search by Type or Created By"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchBar>
      {sortedAndFilteredPerformas.length === 0 ? (
        <p>No performas found.</p>
      ) : (
        <ResponsiveTableContainer>
          <ResponsiveTable>
            <thead>
              <tr>
                <th>
                  <SortButton field="createdAt">Created At</SortButton>
                </th>
                <th>Performa ID</th>
                <th>
                  <SortButton field="type">Type</SortButton>
                </th>
                <th>Created By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedAndFilteredPerformas.map((performa) => (
                <tr key={performa.EntryExitId}>
                  <td data-label="Created At">
                    {formatDate(performa.CreatedAt)}
                  </td>
                  <td data-label="Performa ID">{performa.EntryExitId}</td>
                  <td data-label="Type">{performa.Type}</td>
                  <td data-label="Created By">
                    {getUserName(performa.CreatedBy)}
                  </td>
                  <td data-label="Actions">
                    <ViewPerformaButton
                      onClick={() => window.open(performa.HtmlUrl, "_blank")}
                    >
                      View Performa
                    </ViewPerformaButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </ResponsiveTable>
        </ResponsiveTableContainer>
      )}
    </EntryExitPerformaContainer>
  );
};

const EntryExitPerformaContainer = styled.div`
  margin-top: 2rem;
  color: #333;
  font-family: Arial, sans-serif;
`;

const SearchBar = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 600px;

  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      font-size: 0.9rem;
      padding: 0.4rem;
    }
  }
`;

const ResponsiveTableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background-color: #fff;
  font-size: 0.9rem;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f8f8f8;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;

    th,
    td {
      padding: 6px;
    }
  }
`;

const SortButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const ViewPerformaButton = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 2px 2px;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: #45a049;
  }
`;

export default EntryExitPerformaComponent;
