import React, { useState } from "react";
import styled from "styled-components";
import { callApi } from "../../../utils/apiHelper";
import { apiEndpoints } from "../../../utils/apiEndpoints";
import { Sku, User } from "./types";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

interface CreateProjectProps {
  skus: Sku[];
  processingDepartments: User[];
  onCreateProject: () => void;
}

// Create a custom theme with black and white colors
const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#ffffff",
      paper: "#f5f5f5",
    },
    text: {
      primary: "#000000",
      secondary: "#555555",
    },
  },
});

const CreateProject: React.FC<CreateProjectProps> = ({
  skus,
  processingDepartments,
  onCreateProject,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedSku, setSelectedSku] = useState("");
  const [quantity, setQuantity] = useState("");
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");
  const [managerComment, setManagerComment] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setSelectedSku("");
    setQuantity("");
    setExpectedDeliveryDate("");
    setManagerComment("");
    setSelectedDepartment("");
    setError("");
    setSuccess("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    if (
      !selectedSku ||
      !quantity ||
      !expectedDeliveryDate ||
      !selectedDepartment
    ) {
      setError("Please fill all required fields");
      setLoading(false);
      return;
    }

    const projectData = {
      SkuId: selectedSku,
      StartFreshQuantity: parseFloat(quantity),
      expectedDeliveryDate,
      CurrentLocationId: selectedDepartment,
      ManagerComment: managerComment,
    };

    try {
      const response = await callApi(
        apiEndpoints.createProject,
        "POST",
        projectData,
      );
      if (response.error) {
        setError(response.error.message);
      } else {
        setSuccess("Project created successfully!");
        onCreateProject();
        setTimeout(() => {
          handleClose();
        }, 2000);
      }
    } catch (err) {
      setError("An error occurred while creating the project");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ButtonContainer>
        <Button
          variant="contained"
          onClick={handleOpen}
          startIcon={<AddIcon />}
          sx={{
            color: "white",
            backgroundColor: "black",
            "&:hover": { backgroundColor: "#333" },
            borderRadius: "20px",
            padding: "8px 16px",
            fontSize: "0.875rem",
          }}
        >
          New Project
        </Button>
      </ButtonContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={getModalStyle(isPhone)}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2, fontSize: "1.1rem" }}
          >
            Create New Project
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="sku-label">SKU</InputLabel>
              <Select
                labelId="sku-label"
                value={selectedSku}
                label="SKU"
                onChange={(e) => setSelectedSku(e.target.value)}
                required
                size="small"
              >
                {skus.map((sku) => (
                  <MenuItem key={sku.SkuId} value={sku.SkuId}>
                    {sku.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="dense"
              label="Quantity"
              type="number"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              required
              InputProps={{ inputProps: { min: 1 } }}
              size="small"
            />
            <TextField
              fullWidth
              margin="dense"
              label="Expected Delivery Date"
              type="date"
              value={expectedDeliveryDate}
              onChange={(e) => setExpectedDeliveryDate(e.target.value)}
              required
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: new Date().toISOString().split("T")[0] }}
              size="small"
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="department-label">
                Processing Department
              </InputLabel>
              <Select
                labelId="department-label"
                value={selectedDepartment}
                label="Processing Department"
                onChange={(e) => setSelectedDepartment(e.target.value)}
                required
                size="small"
              >
                {processingDepartments.map((dept) => (
                  <MenuItem key={dept.UserId} value={dept.UserId}>
                    {dept.FullName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="dense"
              label="Manager Comment"
              multiline
              rows={2}
              value={managerComment}
              onChange={(e) => setManagerComment(e.target.value)}
              size="small"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{
                mt: 2,
                color: "white",
                "&:hover": { backgroundColor: "#333" },
                fontSize: "0.875rem",
              }}
              size="small"
            >
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Create Project"
              )}
            </Button>
          </form>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {success && <SuccessMessage>{success}</SuccessMessage>}
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

const getModalStyle = (isPhone: boolean) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isPhone ? "85%" : 320,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  maxHeight: "90vh",
  overflow: "auto",
  borderRadius: "8px",
});

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

const ErrorMessage = styled.p`
  color: #ff0000;
  margin-top: 8px;
  font-size: 0.875rem;
`;

const SuccessMessage = styled.p`
  color: #008000;
  margin-top: 8px;
  font-size: 0.875rem;
`;

export default CreateProject;
