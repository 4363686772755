// ExpandedImageModal.tsx
import React, { useRef } from "react";
import {
  Modal,
  IconButton,
  MobileStepper,
  styled,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

interface ExpandedImageModalProps {
  open: boolean;
  onClose: () => void;
  images: string[];
  currentIndex: number;
  onChangeImage: (index: number) => void;
}

const ExpandedImageModal: React.FC<ExpandedImageModalProps> = ({
  open,
  onClose,
  images,
  currentIndex,
  onChangeImage,
}) => {
  const theme = useTheme();
  const touchStartX = useRef<number | null>(null);
  const touchEndX = useRef<number | null>(null);

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current !== null && touchEndX.current !== null) {
      const diff = touchStartX.current - touchEndX.current;
      if (Math.abs(diff) > 50) {
        if (diff > 0) {
          handleNext();
        } else {
          handleBack();
        }
      }
    }
    touchStartX.current = null;
    touchEndX.current = null;
  };

  const handleNext = () => {
    onChangeImage((currentIndex + 1) % images.length);
  };

  const handleBack = () => {
    onChangeImage((currentIndex - 1 + images.length) % images.length);
  };

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      aria-labelledby="expanded-image-modal"
      aria-describedby="expanded-image-modal-description"
    >
      <ModalContent>
        <CloseButton onClick={onClose} size="large">
          <CloseIcon />
        </CloseButton>
        <SwipeableContainer
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <LargeImage
            src={images[currentIndex]}
            alt={`Expanded view ${currentIndex + 1}`}
          />
        </SwipeableContainer>
        <StyledMobileStepper
          steps={images.length}
          position="static"
          activeStep={currentIndex}
          nextButton={
            <IconButton
              onClick={handleNext}
              disabled={currentIndex === images.length - 1}
            >
              <KeyboardArrowRight />
            </IconButton>
          }
          backButton={
            <IconButton onClick={handleBack} disabled={currentIndex === 0}>
              <KeyboardArrowLeft />
            </IconButton>
          }
        />
      </ModalContent>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ModalContent = styled("div")(({ theme }) => ({
  position: "relative",
  width: "90vw",
  height: "90vh",
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[24],
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
}));

const SwipeableContainer = styled("div")(({ theme }) => ({
  touchAction: "pan-y",
  userSelect: "none",
  width: "100%",
  height: "calc(100% - 48px)", // Adjust based on your MobileStepper height
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.grey[100],
}));

const LargeImage = styled("img")({
  maxWidth: "100%",
  maxHeight: "100%",
  objectFit: "contain",
});

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.grey[800],
  zIndex: 1,
}));

const StyledMobileStepper = styled(MobileStepper)(({ theme }) => ({
  backgroundColor: "transparent",
  "& .MuiMobileStepper-dot": {
    backgroundColor: theme.palette.grey[400],
  },
  "& .MuiMobileStepper-dotActive": {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default ExpandedImageModal;
