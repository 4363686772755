import React, { useState, useEffect } from "react";
import { signIn } from "aws-amplify/auth";
import styled, { keyframes } from "styled-components";

import { useNavigate } from "react-router-dom";
import { checkCurrentAuthState } from "./utils/apiHelper";
import { getUserRole } from "./utils/commonApis";

const Container = styled.div`
  font-family: "Space Grotesk", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f8f8;
`;

const LoginBox = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  margin-bottom: 2rem;
  text-align: center;

  h2 {
    color: #2c3e50;
    font-size: 1.5rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const Button = styled.button`
  background-color: #000000;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #4a4d4b;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
`;

const ErrorMessage = styled.p`
  color: #f44336;
  text-align: center;
  margin-bottom: 1rem;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;

interface UserTypeResponse {
  userId: string;
  fullName: string;
  role: {
    number: number;
    name: string;
  };
  companyId: string;
  departments: string[];
  status: string;
}

const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const companyName = process.env.REACT_APP_COMPANY_NAME || "Our Company";
  const companyId = process.env.REACT_APP_COMPANY_ID || "";

  useEffect(() => {
    checkCurrentAuthState({
      onAuthenticated: redirectBasedOnUserType,
      onUnauthenticated: () => setIsLoading(false),
    });
  }, []);

  const redirectBasedOnUserType = async () => {
    try {
      const role = await getUserRole();
      console.log("role", typeof role);
      if (parseInt(role) === 0) {
        navigate("/admin");
      }
      if (parseInt(role) === 1) {
        navigate("/manager");
      }
      if (parseInt(role) === 6) {
        navigate("/security");
      }
      if (parseInt(role) === 3) {
        navigate("/vendor");
      }
      if (parseInt(role) === 4) {
        navigate("/warehouse");
      }
      if (parseInt(role) === 5) {
        navigate("/Processing");
      }
      if (parseInt(role) === 2) {
        navigate("/customer");
      }
      if (parseInt(role) === 8) {
        navigate("/godown");
      }
    } catch (error) {
      console.error("Error fetching user role:", error);
      setError("Failed to fetch user role");
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError("");
    setIsLoading(true);

    const attemptLogin = async (loginUsername: string) => {
      try {
        const { isSignedIn, nextStep } = await signIn({
          username: loginUsername,
          password,
        });
        if (isSignedIn) {
          console.log("Login successful");
          redirectBasedOnUserType();
          return true;
        } else {
          console.log("Additional steps required", nextStep);
          return false;
        }
      } catch (error) {
        console.error("Login attempt failed", error);
        return false;
      }
    };

    let loginSuccess = await attemptLogin(username);

    if (!loginSuccess && companyId) {
      const companyUsername = `${username}_${companyId.toLowerCase()}`;
      loginSuccess = await attemptLogin(companyUsername);
    }

    if (!loginSuccess) {
      setError("Failed to login. Please check your credentials.");
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <LoadingOverlay>
        <LoadingSpinner />
      </LoadingOverlay>
    );
  }

  return (
    <Container>
      <LoginBox>
        <Header>
          <h2>Welcome to {companyName}</h2>
        </Header>
        <Form onSubmit={handleSubmit}>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit">Login</Button>
        </Form>
      </LoginBox>
    </Container>
  );
};

export default Login;
