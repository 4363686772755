import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkCurrentAuthState, logout } from "../../utils/apiHelper";
import Header from "./AdminElements/Header";
import UserManagement from "./AdminElements/UserManagement";
import SkuManagement from "./AdminElements/SkuManagement";
import RawMaterialManagement from "./AdminElements/RawMaterialManagement";
import PurchaseOrderManagement from "./AdminElements/PurchaseOrderManagement";
import ManageNotification from "./AdminElements/ManangeNotification";
import ManualTransaction from "./AdminElements/ManualTransaction";
import DataManagement from "./AdminElements/Data/DataManagement";
const AdminDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("users");
  const navigate = useNavigate();

  useEffect(() => {
    checkCurrentAuthState({
      onAuthenticated: async () => {
        // User is authenticated, do nothing
      },
      onUnauthenticated: () => {
        navigate("/login");
      },
    });
  }, [navigate]);

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case "users":
        return <UserManagement />;
      case "skus":
        return <SkuManagement />;
      case "rawMaterials":
        return <RawMaterialManagement />;
      case "purchaseOrders":
        return <PurchaseOrderManagement />;
      case "notifications":
        return <ManageNotification />;
      case "transactions":
        return <ManualTransaction />;
      case "data":
        return <DataManagement />;
      default:
        return <UserManagement />;
    }
  };

  return (
    <div className="admin-dashboard">
      <Header
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        onLogout={handleLogout}
      />
      <main className="dashboard-content">{renderActiveTab()}</main>
    </div>
  );
};
export default AdminDashboard;
