import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { callApi } from "../../../../utils/apiHelper";
import { apiEndpoints } from "../../../../utils/apiEndpoints";
import { Transaction, RawMaterial, User, Sku } from "./types";

interface TransactionPageProps {
  rawMaterials: RawMaterial[];
  users: User[];
  skus: Sku[];
}

type SortField = "createdAt" | "fromType" | "toType";
type SortOrder = "asc" | "desc";

const TransactionPage: React.FC<TransactionPageProps> = ({
  rawMaterials,
  users,
  skus,
}) => {
  const [transactionData, setTransactionData] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<SortField>("createdAt");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");

  async function getTransaction() {
    try {
      setLoading(true);
      const response = await callApi<any>(apiEndpoints.DataFetcher, "POST", {
        operation: "getStockTransactions",
      });
      if (response.data) {
        setTransactionData(response.data.data);
      } else if (response.error) {
        throw new Error(response.error.message);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getTransaction();
  }, []);

  const formatDate = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  const getMaterialName = (id: string, type: string) => {
    if (type.toLowerCase() === "sku") {
      const sku = skus.find((s) => s.SkuId === id);
      return sku ? sku.Name : id;
    } else {
      const rawMaterial = rawMaterials.find((rm) => rm.RawMaterialId === id);
      return rawMaterial ? rawMaterial.Name : id;
    }
  };

  const getUserName = (userId: string) => {
    const user = users.find((u) => u.UserId === userId);
    return user ? user.FullName : userId;
  };

  const sortedAndFilteredTransactions = useMemo(() => {
    let filteredTransactions = transactionData.filter((transaction) => {
      const searchTermLower = searchTerm.toLowerCase();
      return (
        transaction.FromType.toLowerCase().includes(searchTermLower) ||
        transaction.ToType.toLowerCase().includes(searchTermLower) ||
        getUserName(transaction.FromWarehouseId)
          .toLowerCase()
          .includes(searchTermLower) ||
        getUserName(transaction.ToWarehouseId)
          .toLowerCase()
          .includes(searchTermLower) ||
        transaction.TypeOfMaterial.toLowerCase().includes(searchTermLower)
      );
    });

    return filteredTransactions.sort((a, b) => {
      const compareValues = (valA: any, valB: any) => {
        if (valA < valB) return sortOrder === "asc" ? -1 : 1;
        if (valA > valB) return sortOrder === "asc" ? 1 : -1;
        return 0;
      };

      switch (sortField) {
        case "createdAt":
          return compareValues(a.CreatedAt, b.CreatedAt);
        case "fromType":
          return compareValues(a.FromType, b.FromType);
        case "toType":
          return compareValues(a.ToType, b.ToType);
        default:
          return 0;
      }
    });
  }, [transactionData, searchTerm, sortField, sortOrder, users]);

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const SortButton = ({
    field,
    children,
  }: {
    field: SortField;
    children: React.ReactNode;
  }) => (
    <SortButtonStyled onClick={() => handleSort(field)}>
      {children} {sortField === field && (sortOrder === "asc" ? "▲" : "▼")}
    </SortButtonStyled>
  );

  if (loading) {
    return <div>Loading transactions...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <TransactionContainer>
      <h2>Stock Transactions</h2>
      <SearchBar>
        <input
          type="text"
          placeholder="Search by From Type, To Type, Warehouse, or Material Type"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchBar>
      {sortedAndFilteredTransactions.length === 0 ? (
        <p>No transactions found.</p>
      ) : (
        <ResponsiveTableContainer>
          <ResponsiveTable>
            <thead>
              <tr>
                <th>
                  <SortButton field="createdAt">Created At</SortButton>
                </th>
                <th>
                  <SortButton field="fromType">From Type</SortButton>
                </th>
                <th>From Warehouse</th>
                <th>
                  <SortButton field="toType">To Type</SortButton>
                </th>
                <th>To Warehouse</th>
                <th>Material Type</th>
                <th>Materials</th>
              </tr>
            </thead>
            <tbody>
              {sortedAndFilteredTransactions.map((transaction) => (
                <tr key={transaction.StockTransactionId}>
                  <td data-label="Created At">
                    {formatDate(transaction.CreatedAt)}
                  </td>
                  <td data-label="From Type">{transaction.FromType}</td>
                  <td data-label="From Warehouse">
                    {getUserName(transaction.FromWarehouseId)}
                  </td>
                  <td data-label="To Type">{transaction.ToType}</td>
                  <td data-label="To Warehouse">
                    {getUserName(transaction.ToWarehouseId)}
                  </td>
                  <td data-label="Material Type">
                    {transaction.TypeOfMaterial}
                  </td>
                  <td data-label="Materials">
                    {transaction.Materials.map((material, index) => (
                      <div key={index}>
                        {"RawMaterialId" in material
                          ? `${getMaterialName(material.RawMaterialId, transaction.TypeOfMaterial)}: ${material.Quantity}`
                          : "BQuantity" in material
                            ? `${getMaterialName(material.Id, transaction.TypeOfMaterial)}: ${material.Quantity} (B: ${material.BQuantity})`
                            : `${getMaterialName(material.Id, transaction.TypeOfMaterial)}: ${material.Quantity}`}
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </ResponsiveTable>
        </ResponsiveTableContainer>
      )}
    </TransactionContainer>
  );
};

const TransactionContainer = styled.div`
  margin-top: 2rem;
  color: #333;
  font-family: Arial, sans-serif;
`;

const SearchBar = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 600px;

  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      font-size: 0.9rem;
      padding: 0.4rem;
    }
  }
`;

const ResponsiveTableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background-color: #fff;
  font-size: 0.9rem;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f8f8f8;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;

    th,
    td {
      padding: 6px;
    }
  }
`;

const SortButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export default TransactionPage;
