import React, { useState } from "react";
import { User, Notification, Sku } from "./types";
import { callApi } from "../../../utils/apiHelper";
import { apiEndpoints } from "../../../utils/apiEndpoints";

interface ProcessNotificationProps {
  users: User[];
  notifications: Notification[];
  skus: Sku[];
  onNotificationProcessed: () => void;
}

const ProcessNotification: React.FC<ProcessNotificationProps> = ({
  users,
  notifications,
  skus,
  onNotificationProcessed,
}) => {
  const getUserName = (userId: string) => {
    const user = users.find((u) => u.UserId === userId);
    return user ? user.FullName : "Unknown User";
  };

  const formatDate = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  return (
    <div>
      <h2>Notifications</h2>
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.NotificationId}
          notification={notification}
          users={users}
          getUserName={getUserName}
          formatDate={formatDate}
          onNotificationProcessed={onNotificationProcessed}
        />
      ))}
    </div>
  );
};

interface NotificationItemProps {
  notification: Notification;
  users: User[];
  getUserName: (userId: string) => string;
  formatDate: (timestamp: number) => string;
  onNotificationProcessed: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  users,
  getUserName,
  formatDate,
  onNotificationProcessed,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const dataDump = JSON.parse(notification.DataDump);

  const handlePassRequest = async (accept: boolean) => {
    setIsProcessing(true);
    setError(null);

    try {
      const response = await callApi(apiEndpoints.AcceptPassProject, "POST", {
        operation: "accept_passing_request",
        NotificationId: notification.NotificationId,
        Accept: accept,
      });

      if (response.error) {
        throw new Error(response.error.message);
      }

      onNotificationProcessed();
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setIsProcessing(false);
    }
  };

  if (dataDump.Type === "PassProject" && notification.Status === "pending") {
    return (
      <div className="pass-request-handler notification-item">
        <h3>Pass Project Request</h3>
        <p>From: {getUserName(notification.CreatedBy)}</p>
        <p>Project ID: {dataDump.ProjectId}</p>
        <p>Fresh Quantity: {dataDump.FreshQuantity}</p>
        <p>B Quantity: {dataDump.BQuantity}</p>
        <p>Created At: {formatDate(notification.CreatedAt)}</p>

        {error && <p className="error-message">{error}</p>}

        <div className="action-buttons">
          <button
            onClick={() => handlePassRequest(true)}
            disabled={isProcessing}
          >
            Accept
          </button>
          <button
            onClick={() => handlePassRequest(false)}
            disabled={isProcessing}
          >
            Reject
          </button>
        </div>

        {isProcessing && <p>Processing request...</p>}
      </div>
    );
  }

  return (
    <div className="notification-item">
      <h3>Notification ID: {notification.NotificationId}</h3>
      <p>Company ID: {notification.CompanyId}</p>
      <p>Created By: {getUserName(notification.CreatedBy)}</p>
      <p>Notification For: {getUserName(notification.NotificationFor)}</p>
      <p>Status: {notification.Status}</p>
      <p>Created At: {formatDate(notification.CreatedAt)}</p>
      <div className="data-dump">
        <h4>Data Dump:</h4>
        <p>Project ID: {dataDump.ProjectId}</p>
        <p>Type: {dataDump.Type}</p>
        <p>Fresh Quantity: {dataDump.FreshQuantity}</p>
        <p>B Quantity: {dataDump.BQuantity}</p>
      </div>
    </div>
  );
};

export default ProcessNotification;
