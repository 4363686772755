// src/components/ManagerDashboard/ManagerDashboard.tsx

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { checkCurrentAuthState, logout, callApi } from "../../utils/apiHelper";
import { apiEndpoints } from "../../utils/apiEndpoints";
import Header from "./ManagerElements/Header";
import CreateProject from "./ManagerElements/CreateProject";
import { Project, User, RawMaterial, Sku } from "./ManagerElements/types";
import ProjectList from "./ManagerElements/ProjectList";

const ManagerDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<Project[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [rawMaterials, setRawMaterials] = useState<RawMaterial[]>([]);
  const [skus, setSkus] = useState<Sku[]>([]);

  useEffect(() => {
    checkCurrentAuthState({
      onAuthenticated: async () => {
        // User is authenticated, fetch all data
        fetchProjectInfo();
        fetchAllVisibleUsers();
        fetchAllVisibleRawMaterials();
        fetchAllVisibleSkus();
      },
      onUnauthenticated: () => {
        navigate("/login");
      },
    });
  }, [navigate]);

  const fetchProjectInfo = async () => {
    try {
      const response = await callApi<Project[]>(
        apiEndpoints.getProjectInfo,
        "GET",
      );
      if (response.data) {
        console.log("Project Info:", response.data);
        setProjects((response.data as any).projects);
      } else if (response.error) {
        console.error("Error fetching project info:", response.error);
      }
    } catch (error) {
      console.error("Error in fetchProjectInfo:", error);
    }
  };

  const fetchAllVisibleUsers = async () => {
    try {
      const response = await callApi<User[]>(
        apiEndpoints.getAllVisibleUsers,
        "GET",
      );
      if (response.data) {
        setUsers((response.data as any).users);
      } else if (response.error) {
        console.error("Error fetching all visible users:", response.error);
      }
    } catch (error) {
      console.error("Error in fetchAllVisibleUsers:", error);
    }
  };

  const fetchAllVisibleRawMaterials = async () => {
    try {
      const response = await callApi<RawMaterial[]>(
        apiEndpoints.getAllVisibleRawMaterials,
        "GET",
      );
      if (response.data) {
        console.log("All Visible Raw Materials:", response.data);
        setRawMaterials((response.data as any).rawMaterials);
      } else if (response.error) {
        console.error(
          "Error fetching all visible raw materials:",
          response.error,
        );
      }
    } catch (error) {
      console.error("Error in fetchAllVisibleRawMaterials:", error);
    }
  };

  const fetchAllVisibleSkus = async () => {
    try {
      const response = await callApi<Sku[]>(apiEndpoints.NulookGetSku, "GET");
      if (response.data) {
        setSkus((response.data as any).skus);
      } else if (response.error) {
        console.error("Error fetching all visible SKUs:", response.error);
      }
    } catch (error) {
      console.error("Error in fetchAllVisibleSkus:", error);
    }
  };

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <DashboardContainer>
      <Header onLogout={handleLogout} />
      <main>
        <ProjectList
          projects={projects}
          users={users}
          skus={skus}
          rawMaterials={rawMaterials}
          onCreateProject={fetchProjectInfo}
        />
      </main>
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  font-family: "Space Grotesk", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex: 1;
    padding: 1rem;
  }
`;

export default ManagerDashboard;
