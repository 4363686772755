import React from "react";
import { User, Notification } from "./types";

interface Props {
  notifications: Notification[];
  onRequestAction: (notificationId: string, accept: boolean) => void;
  users: User[];
}

const ProjectPassingRequestsSection: React.FC<Props> = ({
  notifications,
  onRequestAction,
  users,
}) => {
  const getUserName = (userId: string) => {
    const user = users.find((u) => u.UserId === userId);
    return user ? user.FullName : "Unknown User";
  };

  return (
    <section className="project-passing-requests">
      <h2>Project Passing Requests</h2>
      {notifications.map((notification) => {
        const data = JSON.parse(notification.DataDump);
        console.log(data);
        return (
          <div key={notification.NotificationId} className="notification-card">
            <p>Project ID: {data.ProjectId}</p>
            <p>Created By: {getUserName(notification.CreatedBy)}</p>
            <p>
              Created At:{" "}
              {new Date(notification.CreatedAt * 1000).toLocaleString()}
            </p>
            <p>B Quantity: {data.BQuantity}</p>
            <p>Fresh Quantity: {data.FreshQuantity}</p>
            <button
              onClick={() => onRequestAction(notification.NotificationId, true)}
            >
              Accept
            </button>
            <button
              onClick={() =>
                onRequestAction(notification.NotificationId, false)
              }
            >
              Reject
            </button>
          </div>
        );
      })}
    </section>
  );
};

export default ProjectPassingRequestsSection;
