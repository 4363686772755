import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemText,
  createTheme,
  ThemeProvider,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { callApi } from "../../utils/apiHelper";
import { apiEndpoints } from "../../utils/apiEndpoints";
import { Sku } from "./types";

interface Order {
  CustomerOrderReturnId: string;
  CreatedAt: number;
  Status: string;
  TotalPrice: number;
  TotalQuantity: number;
  TotalBQuantity: number;
  Sku: Array<{ SkuId: string; Quantity: number }>;
  BillImageUrl?: string;
  ConfirmedSku?: Array<{ SkuId: string; Quantity: number }>;
}

interface OrderHistoryTabProps {
  skuData: { skus: Sku[] };
}

const blackAndWhiteTheme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
    text: {
      primary: "#000000",
      secondary: "#404040",
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
          "&:hover": {
            boxShadow:
              "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
          },
        },
      },
    },
  },
});

const OrderHistoryTab: React.FC<OrderHistoryTabProps> = ({ skuData }) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [expandedOrders, setExpandedOrders] = useState<{
    [key: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [cancellingOrderId, setCancellingOrderId] = useState<string | null>(
    null,
  );

  useEffect(() => {
    fetchOrderHistory();
  }, []);

  const fetchOrderHistory = async () => {
    setIsLoading(true);
    try {
      const response = await callApi<{ orders: Order[] }>(
        apiEndpoints.CustomermanageCustomerOrder,
        "POST",
        {
          operation: "get_all",
        },
      );

      if (response.data) {
        setOrders(response.data.orders);
        console.log("orders", response.data.orders);
      } else {
        console.error("Failed to fetch order history:", response.error);
      }
    } catch (error) {
      console.error("Error fetching order history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelOrder = async (orderId: string) => {
    setCancellingOrderId(orderId);
    try {
      await callApi(apiEndpoints.CustomermanageCustomerOrder, "POST", {
        operation: "cancel",
        CustomerOrderReturnId: orderId,
        customer_comment: "Order canceled by customer",
      });
      await fetchOrderHistory();
    } catch (error) {
      console.error("Error canceling order:", error);
    } finally {
      setCancellingOrderId(null);
    }
  };

  const toggleOrderExpansion = (orderId: string) => {
    setExpandedOrders((prev) => ({ ...prev, [orderId]: !prev[orderId] }));
  };

  const openBillImage = (imageUrl: string) => {
    window.open(imageUrl, "_blank");
  };

  const getSkuName = (skuId: string) => {
    const sku = skuData.skus.find((s) => s.SkuId === skuId);
    return sku ? sku.Name : "Unknown SKU";
  };

  const getStatusColor = (status: string) => {
    if (status === "cancelled" || status === "rejected") return "error";
    if (status === "pending") return "warning";
    return "success";
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={blackAndWhiteTheme}>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h6" gutterBottom>
          Order History
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {orders.map((order) => (
            <Grid
              item
              xs={12}
              sm={10}
              md={8}
              lg={6}
              key={order.CustomerOrderReturnId}
            >
              <Card>
                <CardContent>
                  <Typography color="textSecondary">
                    Date: {new Date(order.CreatedAt * 1000).toLocaleString()}
                  </Typography>
                  <Box mt={2} mb={2}>
                    <Chip
                      label={order.Status}
                      color={getStatusColor(order.Status.toLowerCase())}
                    />
                  </Box>
                  <Typography variant="body1">
                    Total Price: ₹{order.TotalPrice.toFixed(2)}
                  </Typography>
                  <Typography variant="body1">
                    Total Quantity: {order.TotalQuantity}
                  </Typography>
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    gap={2}
                  >
                    {order.Status === "pending" && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => cancelOrder(order.CustomerOrderReturnId)}
                        disabled={
                          cancellingOrderId === order.CustomerOrderReturnId
                        }
                      >
                        {cancellingOrderId === order.CustomerOrderReturnId ? (
                          <CircularProgress size={24} />
                        ) : (
                          "Cancel Order"
                        )}
                      </Button>
                    )}
                    <Button
                      variant="text"
                      onClick={() =>
                        toggleOrderExpansion(order.CustomerOrderReturnId)
                      }
                      endIcon={
                        expandedOrders[order.CustomerOrderReturnId] ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )
                      }
                    >
                      {expandedOrders[order.CustomerOrderReturnId]
                        ? "Hide Details"
                        : "Show Details"}
                    </Button>
                  </Box>
                  <Collapse in={expandedOrders[order.CustomerOrderReturnId]}>
                    <Box mt={2}>
                      <Typography variant="subtitle1">Ordered SKUs:</Typography>
                      <List>
                        {order.Sku.map((sku) => (
                          <ListItem key={sku.SkuId}>
                            <ListItemText
                              primary={getSkuName(sku.SkuId)}
                              secondary={`Quantity: ${sku.Quantity}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                      {order.ConfirmedSku && (
                        <Typography variant="subtitle1">
                          Approved SKUs:
                        </Typography>
                      )}
                      <List>
                        {order.ConfirmedSku &&
                          order.ConfirmedSku.map((sku) => (
                            <ListItem key={sku.SkuId}>
                              <ListItemText
                                primary={getSkuName(sku.SkuId)}
                                secondary={`Quantity: ${sku.Quantity}`}
                              />
                            </ListItem>
                          ))}
                      </List>
                      {order.BillImageUrl && (
                        <Button
                          variant="outlined"
                          onClick={() => openBillImage(order.BillImageUrl!)}
                        >
                          View Bill Image
                        </Button>
                      )}
                    </Box>
                  </Collapse>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default OrderHistoryTab;
