import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./Login";

import AdminDashboard from "./components/adminDashboard/AdminDashboard";
import SecurityDashboard from "./components/securityDashBoard/SecurityDashboard";
import WarehouseDashboard from "./components/WarehouseDashboard.tsx/WarehouseDashboard";
import ManagerDashboard from "./components/Manager Dashboard/ManagerDashboard";
import ProcessingDashboard from "./components/ProcessingDashboard/ProcessingDashboard";
import CustomerDashboard from "./components/customDashboard/CustomerDashboard";
import GodownDashboard from "./components/godownDashboard.tsx/godownDashboard";

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallButton(true);
    });
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
        setShowInstallButton(false);
      });
    }
  };

  return (
    <Router>
      <div className="App">
        {showInstallButton && (
          <div className="install-prompt">
            <button className="install-button" onClick={handleInstallClick}>
              <span className="install-icon">📱</span>
              Install App
            </button>
          </div>
        )}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/security" element={<SecurityDashboard />} />
          <Route path="/warehouse" element={<WarehouseDashboard />} />
          <Route path="/manager" element={<ManagerDashboard />} />
          <Route path="/Processing" element={<ProcessingDashboard />} />
          <Route path="/customer" element={<CustomerDashboard />} />
          <Route path="/godown" element={<GodownDashboard />} />
          <Route path="/*" element={<Login />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
