import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Box,
  TablePagination,
  createTheme,
  ThemeProvider,
  Chip,
  useMediaQuery,
  Card,
  CardContent,
  Grid,
  Button,
} from "@mui/material";
import { ApiRawMaterial } from "./types";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface InventoryDisplayProps {
  allRawMaterials: ApiRawMaterial[];
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#ffffff",
      paper: "#f5f5f5",
    },
    text: {
      primary: "#000000",
      secondary: "#404040",
    },
  },
});

type SortField = "name" | "baseUnit" | "quantity";
type SortOrder = "asc" | "desc";

const InventoryDisplay: React.FC<InventoryDisplayProps> = ({
  allRawMaterials,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterBy, setFilterBy] = useState<"all" | "inStock" | "outOfStock">(
    "all",
  );
  const [sortField, setSortField] = useState<SortField>("name");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const filteredAndSortedMaterials = useMemo(() => {
    return allRawMaterials
      .filter((material) => {
        const matchesSearch =
          material.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          material.Code.toLowerCase().includes(searchTerm.toLowerCase());

        if (filterBy === "inStock") {
          return matchesSearch && material.Quantity > 0;
        } else if (filterBy === "outOfStock") {
          return matchesSearch && material.Quantity === 0;
        }
        return matchesSearch;
      })
      .sort((a, b) => {
        let comparison = 0;
        switch (sortField) {
          case "name":
            comparison = a.Name.localeCompare(b.Name);
            break;
          case "baseUnit":
            comparison = a.BaseUnit.localeCompare(b.BaseUnit);
            break;
          case "quantity":
            comparison = a.Quantity - b.Quantity;
            break;
        }
        return sortOrder === "asc" ? comparison : -comparison;
      });
  }, [allRawMaterials, searchTerm, filterBy, sortField, sortOrder]);

  const MobileCard: React.FC<{ material: ApiRawMaterial }> = ({ material }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">
          {material.Name}
        </Typography>
        <Typography color="text.secondary" gutterBottom>
          ID: {material.Code}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2">
              Base Unit: {material.BaseUnit}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              Quantity: {material.Quantity}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Chip
              label={material.Quantity > 0 ? "In Stock" : "Out of Stock"}
              color={material.Quantity > 0 ? "success" : "error"}
              size="small"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const SortButton: React.FC<{ field: SortField; label: string }> = ({
    field,
    label,
  }) => (
    <Button
      variant="text"
      size="small"
      onClick={() => handleSort(field)}
      sx={{
        minWidth: "auto",
        padding: 0,
        justifyContent: "flex-start",
        fontWeight: "bold",
      }}
      endIcon={
        sortField === field ? (
          sortOrder === "asc" ? (
            <ArrowUpwardIcon fontSize="small" />
          ) : (
            <ArrowDownwardIcon fontSize="small" />
          )
        ) : (
          <ArrowUpwardIcon fontSize="small" sx={{ opacity: 0.5 }} />
        )
      }
    >
      {label}
    </Button>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Raw Material Inventory
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Search Raw Materials"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Button
              variant={filterBy === "all" ? "contained" : "outlined"}
              onClick={() => setFilterBy("all")}
              sx={{ flex: 1, mr: 1 }}
            >
              All
            </Button>
            <Button
              variant={filterBy === "inStock" ? "contained" : "outlined"}
              onClick={() => setFilterBy("inStock")}
              sx={{ flex: 1, mr: 1 }}
            >
              In Stock
            </Button>
            <Button
              variant={filterBy === "outOfStock" ? "contained" : "outlined"}
              onClick={() => setFilterBy("outOfStock")}
              sx={{ flex: 1 }}
            >
              Out of Stock
            </Button>
          </Box>
          {isMobile && (
            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <SortButton field="name" label="Name" />
              </Grid>
              <Grid item xs={4}>
                <SortButton field="baseUnit" label="Base Unit" />
              </Grid>
              <Grid item xs={4}>
                <SortButton field="quantity" label="Quantity" />
              </Grid>
            </Grid>
          )}
        </Box>
        {isMobile ? (
          <>
            {filteredAndSortedMaterials
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((material) => (
                <MobileCard key={material.RawMaterialId} material={material} />
              ))}
          </>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="inventory table">
              <TableHead>
                <TableRow>
                  <TableCell>Raw Material ID</TableCell>
                  <TableCell>
                    <SortButton field="name" label="Name" />
                  </TableCell>
                  <TableCell>
                    <SortButton field="baseUnit" label="Base Unit" />
                  </TableCell>
                  <TableCell>
                    <SortButton field="quantity" label="Quantity" />
                  </TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAndSortedMaterials
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((material) => (
                    <TableRow key={material.RawMaterialId}>
                      <TableCell component="th" scope="row">
                        {material.Code}
                      </TableCell>
                      <TableCell>{material.Name}</TableCell>
                      <TableCell>{material.BaseUnit}</TableCell>
                      <TableCell>{material.Quantity}</TableCell>
                      <TableCell>
                        <Chip
                          label={
                            material.Quantity > 0 ? "In Stock" : "Out of Stock"
                          }
                          color={material.Quantity > 0 ? "success" : "error"}
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredAndSortedMaterials.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </ThemeProvider>
  );
};

export default InventoryDisplay;
