import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { callApi } from "../../../../utils/apiHelper";
import { apiEndpoints } from "../../../../utils/apiEndpoints";
import { AcceptedBill, RawMaterial, User } from "./types";

interface AcceptedBillsComponentProps {
  rawMaterials: RawMaterial[];
  users: User[];
}

type SortField = "createdAt" | "totalAmount" | "billtype";
type SortOrder = "asc" | "desc";

const AcceptedBillsComponent: React.FC<AcceptedBillsComponentProps> = ({
  rawMaterials,
  users,
}) => {
  const [allBills, setAllBills] = useState<AcceptedBill[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<SortField>("createdAt");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");

  async function getAllBills() {
    try {
      setLoading(true);
      const response = await callApi<any>(apiEndpoints.DataFetcher, "POST", {
        operation: "getAcceptedBills",
      });
      if (response.data) {
        setAllBills(response.data.data);
      } else if (response.error) {
        throw new Error(response.error.message);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllBills();
  }, []);

  const formatDate = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  const getRawMaterialName = (id: string) => {
    const rawMaterial = rawMaterials.find((rm) => rm.RawMaterialId === id);
    return rawMaterial ? rawMaterial.Name : id;
  };

  const getUserName = (userId: string) => {
    const user = users.find((u) => u.UserId === userId);
    return user ? user.FullName : userId;
  };

  const sortedAndFilteredBills = useMemo(() => {
    let filteredBills = allBills.filter((bill) => {
      const searchTermLower = searchTerm.toLowerCase();
      return (
        bill.billtype.toLowerCase().includes(searchTermLower) ||
        getUserName(bill.vendorId).toLowerCase().includes(searchTermLower) ||
        getUserName(bill.CreatedBy).toLowerCase().includes(searchTermLower) ||
        bill.rawMaterials.some((rm) =>
          getRawMaterialName(rm.RawMaterialId)
            .toLowerCase()
            .includes(searchTermLower),
        )
      );
    });

    return filteredBills.sort((a, b) => {
      const compareValues = (valA: any, valB: any) => {
        if (valA < valB) return sortOrder === "asc" ? -1 : 1;
        if (valA > valB) return sortOrder === "asc" ? 1 : -1;
        return 0;
      };

      switch (sortField) {
        case "createdAt":
          return compareValues(a.CreatedAt, b.CreatedAt);
        case "totalAmount":
          return compareValues(a.totalAmount, b.totalAmount);
        case "billtype":
          return compareValues(a.billtype, b.billtype);
        default:
          return 0;
      }
    });
  }, [allBills, searchTerm, sortField, sortOrder, users, rawMaterials]);

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const SortButton = ({
    field,
    children,
  }: {
    field: SortField;
    children: React.ReactNode;
  }) => (
    <SortButtonStyled onClick={() => handleSort(field)}>
      {children} {sortField === field && (sortOrder === "asc" ? "▲" : "▼")}
    </SortButtonStyled>
  );

  if (loading) {
    return <div>Loading accepted bills...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <AcceptedBillsContainer>
      <h2>Accepted Bills</h2>
      <SearchBar>
        <input
          type="text"
          placeholder="Search by Bill Type, Vendor, Creator, or Raw Material"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchBar>
      {sortedAndFilteredBills.length === 0 ? (
        <p>No bills found.</p>
      ) : (
        <ResponsiveTableContainer>
          <ResponsiveTable>
            <thead>
              <tr>
                <th>
                  <SortButton field="createdAt">Created At</SortButton>
                </th>
                <th>Bill ID</th>
                <th>
                  <SortButton field="billtype">Bill Type</SortButton>
                </th>
                <th>Vendor</th>
                <th>Created By</th>
                <th>
                  <SortButton field="totalAmount">Total Amount</SortButton>
                </th>
                <th>Raw Materials</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedAndFilteredBills.map((bill) => (
                <tr key={bill.AcceptedBillId}>
                  <td data-label="Created At">{formatDate(bill.CreatedAt)}</td>
                  <td data-label="Bill ID">{bill.billId}</td>
                  <td data-label="Bill Type">{bill.billtype}</td>
                  <td data-label="Vendor">{getUserName(bill.vendorId)}</td>
                  <td data-label="Created By">{getUserName(bill.CreatedBy)}</td>
                  <td data-label="Total Amount">{bill.totalAmount}</td>
                  <td data-label="Raw Materials">
                    {bill.rawMaterials.map((rm, index) => (
                      <div key={index}>
                        {getRawMaterialName(rm.RawMaterialId)}: {rm.Quantity} (₹
                        {rm.Price})
                      </div>
                    ))}
                  </td>
                  <td data-label="Actions">
                    <ViewBillButton
                      onClick={() => window.open(bill.billUrl, "_blank")}
                    >
                      View Bill
                    </ViewBillButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </ResponsiveTable>
        </ResponsiveTableContainer>
      )}
    </AcceptedBillsContainer>
  );
};

const AcceptedBillsContainer = styled.div`
  margin-top: 2rem;
  color: #333;
  font-family: Arial, sans-serif;
`;

const SearchBar = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 600px;

  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      font-size: 0.9rem;
      padding: 0.4rem;
    }
  }
`;

const ResponsiveTableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background-color: #fff;
  font-size: 0.9rem;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f8f8f8;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;

    th,
    td {
      padding: 6px;
    }
  }
`;

const SortButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const ViewBillButton = styled.button`
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 2px 2px;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: #45a049;
  }
`;

export default AcceptedBillsComponent;
