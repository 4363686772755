import { ResourcesConfig } from "@aws-amplify/core";

const awsConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: "ap-south-1_Y0cNA1rEK",
      userPoolClientId: "52qs8epigkl4hrq9h0g864optp",
      loginWith: {
        email: true,
        phone: false,
        username: true,
      },
    },
  },
};

export default awsConfig;
