// src/components/ManagerDashboard/ManagerElements/ProjectList.tsx

import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { Project, User, Sku, RawMaterial, Transaction } from "./types";
import CreateProject from "./CreateProject";

interface ProjectListProps {
  projects: Project[];
  users: User[];
  skus: Sku[];
  rawMaterials: RawMaterial[];
  onCreateProject: () => void;
}

type SortField =
  | "sku"
  | "status"
  | "currentLocation"
  | "currentLocationId"
  | "freshQuantity"
  | "bQuantity"
  | "createdBy"
  | "createdAt"
  | "expectedDeliveryDate"
  | "actualDeliveryDate";
type SortOrder = "asc" | "desc";

const ProjectList: React.FC<ProjectListProps> = ({
  projects,
  users,
  skus,
  rawMaterials,
  onCreateProject,
}) => {
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const [expandedTransactions, setExpandedTransactions] = useState<Set<string>>(
    new Set(),
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<SortField>("createdAt");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");

  const getSkuName = (skuId: string) => {
    const sku = skus.find((s) => s.SkuId === skuId);
    return sku ? sku.Name : "Unknown SKU";
  };

  const getUserName = (userId: string) => {
    const user = users.find((u) => u.UserId === userId);
    return user ? user.FullName : "Unknown User";
  };

  const getRawMaterialName = (rawMaterialId: string) => {
    const rawMaterial = rawMaterials.find(
      (rm) => rm.RawMaterialId === rawMaterialId,
    );
    return rawMaterial ? rawMaterial.Name : "Unknown Raw Material";
  };

  const formatDate = (timestamp: number) => {
    return new Date(timestamp).toLocaleString();
  };

  const toggleRowExpansion = (projectId: string) => {
    setExpandedRows((prevState) => {
      const newState = new Set(prevState);
      if (newState.has(projectId)) {
        newState.delete(projectId);
      } else {
        newState.add(projectId);
      }
      return newState;
    });
  };

  const toggleTransactions = (locationId: string) => {
    setExpandedTransactions((prevState) => {
      const newState = new Set(prevState);
      if (newState.has(locationId)) {
        newState.delete(locationId);
      } else {
        newState.add(locationId);
      }
      return newState;
    });
  };

  const renderConsumptionData = (data: { [key: string]: number }) => {
    return Object.entries(data).map(([key, value]) => (
      <div key={key}>{`${getRawMaterialName(key)}: ${value}`}</div>
    ));
  };

  const renderConsumptionLimit = (data: { [key: string]: number }) => {
    return Object.entries(data).map(([key, value]) => (
      <div key={key}>{`${getRawMaterialName(key)}: ${value}`}</div>
    ));
  };

  const renderTransactions = (transactions: Transaction[]) => {
    return transactions.map((transaction, index) => (
      <TransactionItem key={index}>
        <strong>Transaction {index + 1}</strong>
        <div>From: {getUserName(transaction.FromWarehouseId)}</div>
        <div>To: {getUserName(transaction.ToWarehouseId)}</div>
        <div>Type: {transaction.TypeOfMaterial}</div>
        <div>Created At: {formatDate(transaction.CreatedAt)}</div>
        <div>Materials:</div>
        <ul>
          {transaction.Materials.map((material, idx) => (
            <li key={idx}>
              {getRawMaterialName(material.RawMaterialId)}: {material.Quantity}
            </li>
          ))}
        </ul>
      </TransactionItem>
    ));
  };

  const renderPastLocations = (pastLocations: Project["PastLocation"]) => {
    return pastLocations.map((location, index) => {
      const locationId = `${location.LocationId}-${index}`;
      return (
        <PastLocationBox key={locationId}>
          <strong>{location.LocationName}</strong>
          <div>Location ID: {getUserName(location.LocationId)}</div>
          <div>Start: {formatDate(location.StartTime)}</div>
          <div>
            End: {location.EndTime ? formatDate(location.EndTime) : "Ongoing"}
          </div>
          <div>Fresh Quantity: {location.FreshQuantity}</div>
          <div>B Quantity: {location.BQuantity}</div>
          <TransactionToggle onClick={() => toggleTransactions(locationId)}>
            {expandedTransactions.has(locationId)
              ? "Hide Transactions"
              : "Show Transactions"}
          </TransactionToggle>
          {expandedTransactions.has(locationId) && (
            <TransactionsContainer>
              {renderTransactions(location.Transactions)}
            </TransactionsContainer>
          )}
        </PastLocationBox>
      );
    });
  };

  const sortedAndFilteredProjects = useMemo(() => {
    let filteredProjects = projects.filter((project) => {
      const skuName = getSkuName(project.SkuId).toLowerCase();
      const currentLocationName = getUserName(
        project.CurrentLocationId,
      ).toLowerCase();
      const searchLower = searchTerm.toLowerCase();
      return (
        skuName.includes(searchLower) ||
        currentLocationName.includes(searchLower)
      );
    });

    return filteredProjects.sort((a, b) => {
      const compareValues = (valA: any, valB: any) => {
        if (valA < valB) return sortOrder === "asc" ? -1 : 1;
        if (valA > valB) return sortOrder === "asc" ? 1 : -1;
        return 0;
      };

      switch (sortField) {
        case "sku":
          return compareValues(getSkuName(a.SkuId), getSkuName(b.SkuId));
        case "status":
          return compareValues(a.Status, b.Status);
        case "currentLocation":
          return compareValues(a.CurrentLocation, b.CurrentLocation);
        case "currentLocationId":
          return compareValues(
            getUserName(a.CurrentLocationId),
            getUserName(b.CurrentLocationId),
          );
        case "freshQuantity":
          return compareValues(a.FreshQuantity, b.FreshQuantity);
        case "bQuantity":
          return compareValues(a.BQuantity, b.BQuantity);
        case "createdBy":
          return compareValues(
            getUserName(a.CreatedBy),
            getUserName(b.CreatedBy),
          );
        case "createdAt":
          return compareValues(a.CreatedAt, b.CreatedAt);
        case "expectedDeliveryDate":
          return compareValues(
            new Date(a.expectedDeliveryDate),
            new Date(b.expectedDeliveryDate),
          );
        case "actualDeliveryDate":
          // Handle cases where ActualDeliveryDate might be null or undefined
          if (!a.ActualDeliveryDate && !b.ActualDeliveryDate) return 0;
          if (!a.ActualDeliveryDate) return sortOrder === "asc" ? 1 : -1;
          if (!b.ActualDeliveryDate) return sortOrder === "asc" ? -1 : 1;
          return compareValues(
            new Date(a.ActualDeliveryDate),
            new Date(b.ActualDeliveryDate),
          );
        default:
          return 0;
      }
    });
  }, [projects, searchTerm, sortField, sortOrder]);

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const SortButton = ({
    field,
    children,
  }: {
    field: SortField;
    children: React.ReactNode;
  }) => (
    <SortButtonStyled onClick={() => handleSort(field)}>
      {children} {sortField === field && (sortOrder === "asc" ? "▲" : "▼")}
    </SortButtonStyled>
  );

  return (
    <ProjectListContainer>
      <h2>Project List</h2>
      <CreateProject
        skus={skus}
        processingDepartments={users.filter((user) => user.Role === 5)}
        onCreateProject={onCreateProject}
      />
      <SearchBar>
        <input
          type="text"
          placeholder="Search by SKU name or Current Location"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchBar>
      {sortedAndFilteredProjects.length === 0 ? (
        <p>No projects found.</p>
      ) : (
        <ResponsiveTableContainer>
          <ResponsiveTable>
            <thead>
              <tr>
                <th>
                  <SortButton field="sku">SKU</SortButton>
                </th>
                <th>
                  <SortButton field="status">Status</SortButton>
                </th>
                <th>
                  <SortButton field="currentLocation">Location</SortButton>
                </th>
                <th>
                  <SortButton field="freshQuantity">Fresh Qty</SortButton>
                </th>
                <th>
                  <SortButton field="bQuantity">B Qty</SortButton>
                </th>
                <th>
                  <SortButton field="createdAt">Created</SortButton>
                </th>
                <th>
                  <SortButton field="expectedDeliveryDate">Expected</SortButton>
                </th>
                <th>
                  <SortButton field="actualDeliveryDate">Actual</SortButton>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedAndFilteredProjects.map((project) => (
                <React.Fragment key={project.ProductionProjectId}>
                  <tr>
                    <td data-label="SKU">{getSkuName(project.SkuId)}</td>
                    <td data-label="Status">{project.Status}</td>
                    <td data-label="Location">{project.CurrentLocation}</td>
                    <td data-label="Fresh Qty">{project.FreshQuantity}</td>
                    <td data-label="B Qty">{project.BQuantity}</td>
                    <td data-label="Created">
                      {formatDate(project.CreatedAt)}
                    </td>
                    <td data-label="Expected">
                      {new Date(
                        project.expectedDeliveryDate,
                      ).toLocaleDateString()}
                    </td>
                    <td data-label="Actual">
                      {project.ActualDeliveryDate
                        ? new Date(
                            project.ActualDeliveryDate,
                          ).toLocaleDateString()
                        : "N/A"}
                    </td>
                    <td data-label="Actions">
                      <ExpandButton
                        onClick={() =>
                          toggleRowExpansion(project.ProductionProjectId)
                        }
                      >
                        {expandedRows.has(project.ProductionProjectId)
                          ? "Hide"
                          : "Details"}
                      </ExpandButton>
                    </td>
                  </tr>
                  {expandedRows.has(project.ProductionProjectId) && (
                    <tr>
                      <td colSpan={9}>
                        <ExpandedContent>
                          <DetailItem>
                            <strong>Project ID:</strong>{" "}
                            {project.ProductionProjectId}
                          </DetailItem>
                          <DetailItem>
                            <strong>Current Location ID:</strong>{" "}
                            {getUserName(project.CurrentLocationId)}
                          </DetailItem>
                          <DetailItem>
                            <strong>Created By:</strong>{" "}
                            {getUserName(project.CreatedBy)}
                          </DetailItem>
                          <h4>Current Consumption</h4>
                          {renderConsumptionData(project.CurrentConsumption)}
                          <h4>Current Wastage</h4>
                          {renderConsumptionData(project.CurrentWastage)}
                          <h4>Consumption Limit</h4>
                          {renderConsumptionLimit(project.ConsumptionLimit)}
                          <h4>Past Locations</h4>
                          {renderPastLocations(project.PastLocation)}
                          <h4>Manager Comment</h4>
                          <div>{project.DataDump.ManagerComment}</div>
                        </ExpandedContent>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </ResponsiveTable>
        </ResponsiveTableContainer>
      )}
    </ProjectListContainer>
  );
};

const ProjectListContainer = styled.div`
  margin-top: 2rem;
  color: #333;
  font-family: Arial, sans-serif;
`;

const SearchBar = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 600px; // Set a maximum width for larger screens

  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; // Ensures padding doesn't affect overall width

    @media (max-width: 767px) {
      font-size: 0.9rem;
      padding: 0.4rem;
    }
  }
`;

const ResponsiveTableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background-color: #fff;
  font-size: 0.9rem;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f8f8f8;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;

    th,
    td {
      padding: 6px;
    }
  }
`;

const SortButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const ExpandButton = styled.button`
  background-color: #333;
  color: #fff;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 0.8rem;

  &:hover {
    background-color: #555;
  }
`;

const ExpandedContent = styled.div`
  padding: 1rem;
  background-color: #f8f8f8;

  h4 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: #333;
  }
`;

const DetailItem = styled.div`
  margin-bottom: 0.5rem;
`;

const PastLocationBox = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
`;

const TransactionToggle = styled.button`
  background-color: #333;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: #555;
  }
`;

const TransactionsContainer = styled.div`
  margin-top: 10px;
`;

const TransactionItem = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: #f9f9f9;
`;

export default ProjectList;
