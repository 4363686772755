import React, { useState } from "react";
import styled from "styled-components";
import { Notification, ApiRawMaterial, User } from "./types";
import { callApi } from "../../../utils/apiHelper";
import { apiEndpoints } from "../../../utils/apiEndpoints";

interface AcceptPurchaseOrderNotificationProps {
  notification: Notification;
  allRawMaterials: ApiRawMaterial[];
  allUsers: User[];
  onProcessed: () => void;
}

const AcceptPurchaseOrderNotification: React.FC<
  AcceptPurchaseOrderNotificationProps
> = ({ notification, allRawMaterials, allUsers, onProcessed }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [adminComments, setAdminComments] = useState("");
  const [editableRawMaterials, setEditableRawMaterials] = useState<any[]>([]);
  const dataDump = JSON.parse(notification.DataDump);

  React.useEffect(() => {
    setEditableRawMaterials(
      dataDump.RawMaterials.map((material: any) => ({
        ...material,
        Quantity: parseFloat(material.Quantity),
        Price: parseFloat(material.Price),
      })),
    );
  }, [notification]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const getRawMaterialName = (id: string) => {
    const material = allRawMaterials.find((m) => m.RawMaterialId === id);
    return material ? material.Name : "Unknown";
  };

  const getUserName = (id: string) => {
    const user = allUsers.find((u) => u.UserId === id);
    return user ? user.FullName : id;
  };

  const handleQuantityChange = (index: number, value: string) => {
    const newMaterials = [...editableRawMaterials];
    newMaterials[index].Quantity = parseFloat(value) || 0;
    setEditableRawMaterials(newMaterials);
  };

  const handlePriceChange = (index: number, value: string) => {
    const newMaterials = [...editableRawMaterials];
    newMaterials[index].Price = parseFloat(value) || 0;
    setEditableRawMaterials(newMaterials);
  };

  const handleAccept = async () => {
    if (
      window.confirm("Are you sure you want to accept this purchase order?")
    ) {
      await processNotification(true);
    }
  };

  const handleReject = async () => {
    if (adminComments.trim() === "") {
      alert("Please provide admin comments before rejecting.");
      return;
    }
    if (
      window.confirm("Are you sure you want to reject this purchase order?")
    ) {
      await processNotification(false);
    }
  };

  const processNotification = async (accept: boolean) => {
    setIsLoading(true);
    try {
      const response = await callApi(
        apiEndpoints.ProcessInventoryAcceptReject,
        "POST",
        {
          operation: accept ? "accept" : "reject",
          NotificationId: notification.NotificationId,
          rawMaterials: accept ? editableRawMaterials : undefined,
          rejectionComments: accept ? undefined : adminComments,
        },
      );

      if (response.error) {
        throw new Error(response.error.message);
      }

      alert(
        accept
          ? "Purchase order accepted successfully"
          : "Purchase order rejected successfully",
      );
      onProcessed();
    } catch (error) {
      console.error("Error processing purchase order:", error);
      alert(
        "An error occurred while processing the purchase order. Please try again.",
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NotificationCard>
      <NotificationHeader>
        <NotificationId>{notification.NotificationId}</NotificationId>
        <NotificationType>Accept Purchase Order</NotificationType>
      </NotificationHeader>
      <ExpandButton onClick={toggleExpand} isExpanded={isExpanded}>
        {isExpanded ? "Collapse" : "Expand"}
        <Arrow isExpanded={isExpanded} />
      </ExpandButton>
      <Drawer isExpanded={isExpanded}>
        <InfoGrid>
          <InfoItem>
            <InfoLabel>Purchase Order ID</InfoLabel>
            <InfoValue>{dataDump.PurchaseOrderId}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>Entry/Exit ID</InfoLabel>
            <InfoValue>{dataDump.EntryExitId}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>Vendor Bill ID</InfoLabel>
            <InfoValue>{dataDump.VendorBillId}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>Created By</InfoLabel>
            <InfoValue>{getUserName(notification.CreatedBy)}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>Assigned To</InfoLabel>
            <InfoValue>{getUserName(dataDump.AssignedTo)}</InfoValue>
          </InfoItem>
          <InfoItem>
            <InfoLabel>Time of Confirmation</InfoLabel>
            <InfoValue>
              {new Date(notification.CreatedAt * 1000).toLocaleString()}
            </InfoValue>
          </InfoItem>
        </InfoGrid>
        <RawMaterialsList>
          <h4>Raw Materials:</h4>
          <RawMaterialTable>
            <TableHeader>
              <tr>
                <TableHeaderCell>Name</TableHeaderCell>
                <TableHeaderCell>Quantity</TableHeaderCell>
                <TableHeaderCell>Price</TableHeaderCell>
              </tr>
            </TableHeader>
            <TableBody>
              {editableRawMaterials.map((material: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>
                    {getRawMaterialName(material.RawMaterialId)}
                  </TableCell>
                  <TableCell>
                    <input
                      type="number"
                      value={material.Quantity}
                      onChange={(e) =>
                        handleQuantityChange(index, e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="number"
                      value={material.Price}
                      onChange={(e) => handlePriceChange(index, e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </RawMaterialTable>
        </RawMaterialsList>
        <BillLink
          href={dataDump.VendorBillImage}
          target="_blank"
          rel="noreferrer"
        >
          View Bill
        </BillLink>
        <PerformaLink href={dataDump.HtmlUrl} target="_blank" rel="noreferrer">
          View Entry/Exit Performa
        </PerformaLink>
        <SecurityComments>
          <InfoLabel>Security Comments:</InfoLabel>
          <InfoValue>{dataDump.SecurityComments}</InfoValue>
        </SecurityComments>
        <CommentsTextArea
          placeholder="Enter admin comments (required for rejection)"
          value={adminComments}
          onChange={(e) => setAdminComments(e.target.value)}
        />
        <ButtonGroup>
          <AcceptButton onClick={handleAccept} disabled={isLoading}>
            Accept
          </AcceptButton>
          <RejectButton onClick={handleReject} disabled={isLoading}>
            Reject
          </RejectButton>
        </ButtonGroup>
      </Drawer>
      {isLoading && (
        <LoadingOverlay>
          <Spinner />
        </LoadingOverlay>
      )}
    </NotificationCard>
  );
};
const NotificationCard = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  font-family: "Space Grotesk", sans-serif;
  overflow: hidden;
  position: relative;
`;

const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const NotificationId = styled.span`
  font-weight: bold;
  color: #2c3e50;
`;

const NotificationType = styled.span`
  color: #7f8c8d;
  font-size: 0.9rem;
`;

const ExpandButton = styled.button<{ isExpanded: boolean }>`
  background-color: transparent;
  color: #2c3e50;
  border: none;
  padding: 0.5rem 0;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: ${(props) => (props.isExpanded ? "1rem" : "0")};

  &:hover {
    background-color: #f8f8f8;
  }
`;

const Arrow = styled.span<{ isExpanded: boolean }>`
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #2c3e50;
  transition: transform 0.3s ease;
  transform: ${(props) => (props.isExpanded ? "rotate(180deg)" : "rotate(0)")};
`;

const Drawer = styled.div<{ isExpanded: boolean }>`
  max-height: ${(props) => (props.isExpanded ? "1000px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoLabel = styled.strong`
  font-size: 0.9rem;
  color: #7f8c8d;
`;

const InfoValue = styled.span`
  font-size: 1rem;
  color: #2c3e50;
`;

const RawMaterialsList = styled.div`
  margin-top: 1rem;

  h4 {
    color: #2c3e50;
    margin-bottom: 0.5rem;
  }
`;

const RawMaterialTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const TableHeader = styled.thead`
  background-color: #f8f8f8;
`;

const TableHeaderCell = styled.th`
  text-align: left;
  padding: 0.75rem 1rem;
  font-weight: 600;
  color: #2c3e50;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.5px;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled.td`
  padding: 0.75rem 1rem;
  border-top: 1px solid #e0e0e0;
  color: #34495e;
`;

const BillLink = styled.a`
  display: inline-block;
  margin-top: 1rem;
  margin-right: 1rem;
  color: #3498db;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const PerformaLink = styled(BillLink)``;

const SecurityComments = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f8f8f8;
  border-radius: 4px;
`;

const CommentsTextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  resize: vertical;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const ActionButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const AcceptButton = styled(ActionButton)`
  background-color: #4caf50;
  color: white;

  &:hover:not(:disabled) {
    background-color: #45a049;
  }
`;

const RejectButton = styled(ActionButton)`
  background-color: #f44336;
  color: white;

  &:hover:not(:disabled) {
    background-color: #d32f2f;
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default AcceptPurchaseOrderNotification;
