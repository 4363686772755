import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { callApi } from "../../../utils/apiHelper";
import { apiEndpoints } from "../../../utils/apiEndpoints";
import { Project, User, Sku } from "./types";

interface PassProjectModalProps {
  project: Project;
  onClose: () => void;
  rawMaterials: any[];
  sku: Sku | undefined;
  users: User[];
}

const PassProjectModal: React.FC<PassProjectModalProps> = ({
  project,
  onClose,
  users,
  sku,
}) => {
  const [toLocationId, setToLocationId] = useState("");
  const [passingFreshQuantity, setPassingFreshQuantity] = useState("");
  const [passingBQuantity, setPassingBQuantity] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handlePassProject = async () => {
    if (
      !project.ProductionProjectId ||
      !toLocationId ||
      !passingFreshQuantity ||
      !passingBQuantity
    ) {
      setError("Please fill in all fields");
      return;
    }

    try {
      const response = await callApi(apiEndpoints.AcceptPassProject, "POST", {
        operation: "pass_project",
        ProjectId: project.ProductionProjectId,
        ToLocationId: toLocationId,
        PassingFreshQuantity: parseFloat(passingFreshQuantity),
        PassingBQuantity: parseFloat(passingBQuantity),
      });

      if (response.error) {
        setError(response.error.message);
      } else {
        // Success
        alert("Project passed successfully");
        onClose();
      }
    } catch (error) {
      setError("An error occurred while passing the project");
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Pass Project</DialogTitle>
      <DialogContent>
        <Box my={2}>
          <Typography variant="body2" gutterBottom>
            Project ID: {project.ProductionProjectId}
          </Typography>
          {sku && (
            <Typography variant="body2" gutterBottom>
              SKU: {sku.Name}
            </Typography>
          )}
        </Box>

        <Box my={2}>
          <Select
            value={toLocationId}
            onChange={(e) => setToLocationId(e.target.value as string)}
            fullWidth
            variant="outlined"
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Destination
            </MenuItem>
            {users
              .filter((user) => [5, 8].includes(user.Role))
              .map((user) => (
                <MenuItem key={user.UserId} value={user.UserId}>
                  {user.FullName} ({user.Role === 5 ? "Processing" : "Godown"})
                </MenuItem>
              ))}
          </Select>
        </Box>

        <Box my={2}>
          <TextField
            type="number"
            label="Passing Fresh Quantity"
            value={passingFreshQuantity}
            onChange={(e) => setPassingFreshQuantity(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Box>

        <Box my={2}>
          <TextField
            type="number"
            label="Passing B Quantity"
            value={passingBQuantity}
            onChange={(e) => setPassingBQuantity(e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Box>

        {error && (
          <Typography color="error" variant="body2" gutterBottom>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button onClick={handlePassProject} color="primary" variant="contained">
          Pass Project
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PassProjectModal;
