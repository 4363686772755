export const apiEndpoints = {
  //newfresh ones

  NulookGetUserRole:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/NulookGetUserRole",
  NulookErpManageUsers:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/NulookErpManageUsers",

  NulookSkuManagement:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/NulookSkuManagement",
  NulookRawMaterialManagement:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/NulookRawMaterialManagement",
  NulookErpPurchaseOrderManagement:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/NulookErpPurchaseOrderManagement",
  NulookErpProcessPurchaseOrder:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/NulookErpProcessPurchaseOrder",
  NulookGetUserNotifications:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/NulookErpNewGetUserNotifications",
  NulookErpAcceptRejectFreshInventory:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/NulookErpAcceptRejectFreshInventory",
  ProcessInventoryAcceptReject:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/ProcessInventoryAcceptReject",
  NulookGetSku:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/NulookGetSku",
  createProject:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/createProject",
  requestReturnProjectMaterial:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/requestReturnProjectMaterial",
  AcceptRejectRawMaterialRequestProject:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/AcceptRejectRawMaterialRequestProject",
  RequestWastage:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/requestWastage",
  NulookAcceptRejectWasteRequest:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/acceptRejectWaste",
  AcceptPassProject:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/Accept_PassProject",
  GodownAcceptPassProject:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/RejectacceptPassRequest",
  CustomermanageCustomerOrder:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/CustomermanageCustomerOrder",
  getAllVisibleUsers:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/getCompanyUsers",
  getAllVisibleRawMaterials:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/getCompanyRawMaterials",
  getProjectInfo:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/GetProjectInfo",
  CreateTransaction:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/bruteforce_transaction",
  GodownManageCustomerOrder:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/godown_manageCustomerOrder",
  InventoryDisplay:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/InventoryDisplay",
  DataFetcher:
    "https://jn141k8da8.execute-api.ap-south-1.amazonaws.com/prod/DataFetcher",
};
