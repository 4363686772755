import React, { useState, useMemo, useEffect } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Box,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SkuCard from "./SkuCard";
import ExpandedImageModal from "./ExpandedImageModal";
import { FilterType, Sku } from "./types";

interface HomeTabProps {
  skuData: { skus: Sku[] };
  addToCart: (sku: Sku, orderQuantity: number, orderBQuantity: number) => void;
  cart: Sku[];
}

const blackAndWhiteTheme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
    text: {
      primary: "#000000",
      secondary: "#404040",
    },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: "1px solid #000000",
          color: "#000000",
          "&.Mui-selected": {
            backgroundColor: "#000000",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#333333",
            },
          },
          "&:hover": {
            backgroundColor: "#f0f0f0",
          },
        },
      },
    },
  },
});

const HomeTab: React.FC<HomeTabProps> = ({ skuData, addToCart, cart }) => {
  const [quantities, setQuantities] = useState<{
    [key: string]: { orderQuantity: number; orderBQuantity: number };
  }>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<FilterType>("all");
  const [expandedImageIndex, setExpandedImageIndex] = useState<number>(0);
  const [expandedSkuId, setExpandedSkuId] = useState<string | null>(null);
  const [selectedChildSku, setSelectedChildSku] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    const initialQuantities = cart.reduce(
      (acc, item) => {
        acc[item.SkuId] = {
          orderQuantity: item.orderQuantity,
          orderBQuantity: item.orderBQuantity,
        };
        return acc;
      },
      {} as {
        [key: string]: { orderQuantity: number; orderBQuantity: number };
      },
    );
    setQuantities(initialQuantities);
  }, [cart]);

  const handleQuantityChange = (
    skuId: string,
    type: "orderQuantity" | "orderBQuantity",
    value: number,
  ) => {
    const newQuantities = {
      ...quantities,
      [skuId]: {
        ...quantities[skuId],
        [type]: Math.max(0, value),
      },
    };
    setQuantities(newQuantities);

    const sku = skuData.skus.find((s) => s.SkuId === skuId);
    if (sku) {
      addToCart(
        sku,
        newQuantities[skuId].orderQuantity,
        newQuantities[skuId].orderBQuantity,
      );
    }
  };

  const handleFilterChange = (
    event: React.MouseEvent<HTMLElement>,
    newFilter: FilterType,
  ) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const getImagesToDisplay = (skuId: string) => {
    if (!skuId) return [];
    const parentSku = groupedSkus[skuId][0];
    const selectedChildSkuId = selectedChildSku[skuId];
    const selectedSku =
      groupedSkus[skuId].find((sku) => sku.SkuId === selectedChildSkuId) ||
      parentSku;

    return selectedSku.Images.length > 0
      ? selectedSku.Images
      : parentSku.Images;
  };

  const handleExpandImage = (skuId: string, index: number) => {
    setExpandedSkuId(skuId);
    setExpandedImageIndex(index);
  };

  const handleCloseExpandedImage = () => {
    setExpandedSkuId(null);
    setExpandedImageIndex(0);
  };

  const handleChangeExpandedImage = (index: number) => {
    if (expandedSkuId) {
      const images = getImagesToDisplay(expandedSkuId);
      setExpandedImageIndex((index + images.length) % images.length);
    }
  };

  const handleChildSkuSelect = (parentId: string, childSkuId: string) => {
    setSelectedChildSku((prev) => ({ ...prev, [parentId]: childSkuId }));
  };

  const filteredSkus = useMemo(() => {
    return skuData.skus.filter((sku) => {
      const searchLower = searchTerm.toLowerCase();
      const matchesSearch =
        sku.Name.toLowerCase().includes(searchLower) ||
        sku.Code.toLowerCase().includes(searchLower) ||
        sku.Description.toLowerCase().includes(searchLower) ||
        Object.values(sku.Attributes).some((attr) =>
          attr.toLowerCase().includes(searchLower),
        );

      const availableQuantity = sku.Quantity - sku.PendingOrderQuantity;
      const availableBQuantity = sku.BQuantity - sku.PendingBOrderQuantity;
      const isInStock = availableQuantity > 0 || availableBQuantity > 0;

      switch (filter) {
        case "in-stock":
          return matchesSearch && isInStock;
        case "out-of-stock":
          return matchesSearch && !isInStock;
        case "new-release":
          return matchesSearch && sku.NewRelease;
        default:
          return matchesSearch;
      }
    });
  }, [skuData.skus, searchTerm, filter]);

  const groupedSkus = useMemo(() => {
    return filteredSkus.reduce(
      (acc, sku) => {
        const key = sku.ParentSkuId || sku.SkuId;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(sku);
        return acc;
      },
      {} as { [key: string]: Sku[] },
    );
  }, [filteredSkus]);

  useEffect(() => {
    const initialSelection = Object.entries(groupedSkus).reduce(
      (acc, [parentId, skus]) => {
        if (skus.length > 1) {
          acc[parentId] = skus[1].SkuId;
        } else {
          acc[parentId] = skus[0].SkuId;
        }
        return acc;
      },
      {} as { [key: string]: string },
    );
    setSelectedChildSku(initialSelection);
  }, [groupedSkus]);

  return (
    <ThemeProvider theme={blackAndWhiteTheme}>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom align="center">
          Product Catalog
        </Typography>
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Name, Code, or Attributes"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mb: 3, display: "flex", justifyContent: "center" }}>
          <ToggleButtonGroup
            value={filter}
            exclusive
            onChange={handleFilterChange}
            aria-label="SKU filter"
          >
            <ToggleButton value="all" aria-label="all SKUs">
              All
            </ToggleButton>
            <ToggleButton value="in-stock" aria-label="in stock">
              In Stock
            </ToggleButton>
            <ToggleButton value="out-of-stock" aria-label="out of stock">
              Out of Stock
            </ToggleButton>
            <ToggleButton value="new-release" aria-label="new release">
              New Release
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Grid container spacing={3}>
          {Object.entries(groupedSkus).map(([parentId, skus]) => (
            <Grid item xs={12} sm={6} md={4} key={parentId}>
              <SkuCard
                parentId={parentId}
                skus={skus}
                quantities={quantities}
                handleQuantityChange={handleQuantityChange}
                handleExpandImage={handleExpandImage}
                selectedChildSku={selectedChildSku}
                handleChildSkuSelect={handleChildSkuSelect}
              />
            </Grid>
          ))}
        </Grid>
        <ExpandedImageModal
          open={!!expandedSkuId}
          onClose={handleCloseExpandedImage}
          images={expandedSkuId ? getImagesToDisplay(expandedSkuId) : []}
          currentIndex={expandedImageIndex}
          onChangeImage={handleChangeExpandedImage}
        />
      </Box>
    </ThemeProvider>
  );
};

export default HomeTab;
