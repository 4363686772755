import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { callApi } from "../../../utils/apiHelper";
import { apiEndpoints } from "../../../utils/apiEndpoints";

interface PurchaseOrderRawMaterial {
  RawMaterialId: string;
  Quantity: number;
  Price: number;
}

interface ApiRawMaterial {
  RawMaterialId: string;
  Name: string;
  BaseUnit: string;
  Price: number;
}

interface PurchaseOrder {
  PurchaseOrderId: string;
  VendorName: string;
  TotalPrice: number;
  Status: string;
  CreatedAt: number;
  RawMaterials: PurchaseOrderRawMaterial[];
}

interface RawMaterialsResponse {
  rawMaterials: ApiRawMaterial[];
}
interface User {
  UserId: string;
  FullName: string;
  PhoneNumber: string;
  Role: number;
}

interface UserResponse {
  users: User[];
}

const PurchaseOrderProcessing: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseOrderId, setPurchaseOrderId] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder | null>(
    null,
  );
  const [error, setError] = useState("");
  const [vendorBillId, setVendorBillId] = useState("");
  const [vendorBillImage, setVendorBillImage] = useState<string | null>(null);
  const [securityComments, setSecurityComments] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [allRawMaterials, setAllRawMaterials] = useState<ApiRawMaterial[]>([]);
  const [warehouseUsers, setWarehouseUsers] = useState<User[]>([]);

  useEffect(() => {
    fetchAllRawMaterials();
    fetchWarehouseUsers();
  }, []);

  const fetchAllRawMaterials = async () => {
    try {
      const response = await callApi<RawMaterialsResponse>(
        apiEndpoints.getAllVisibleRawMaterials,
        "GET",
      );
      if (response.data && response.data.rawMaterials) {
        setAllRawMaterials(response.data.rawMaterials);
      }
    } catch (error) {
      console.error("Error fetching raw materials:", error);
    }
  };
  const fetchWarehouseUsers = async () => {
    try {
      const response = await callApi<UserResponse>(
        apiEndpoints.getAllVisibleUsers,
        "POST",
        {
          operation: "getAllVisibleUsers",
        },
      );
      if (response.data) {
        setWarehouseUsers(
          response.data.users.filter((user) => user.Role === 4),
        );
      }
    } catch (error) {
      console.error("Error fetching warehouse users:", error);
    }
  };

  const handlePurchaseOrderSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    setPurchaseOrder(null);

    try {
      const response = await callApi(
        apiEndpoints.NulookErpProcessPurchaseOrder,
        "POST",
        {
          operation: "get_purchase_order",
          PurchaseOrderId: purchaseOrderId,
        },
      );

      if (response.data) {
        setPurchaseOrder(response.data as PurchaseOrder);
      } else {
        setError(
          (response.error?.message as any).error || "Invalid Purchase Order ID",
        );
      }
    } catch (error) {
      setError(
        error instanceof Error
          ? error.message
          : "Error fetching purchase order",
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleRawMaterialChange = (
    index: number,
    field: string,
    value: string,
  ) => {
    if (purchaseOrder) {
      const updatedRawMaterials = [...purchaseOrder.RawMaterials];
      updatedRawMaterials[index] = {
        ...updatedRawMaterials[index],
        [field]:
          field === "Quantity" || field === "Price" ? parseFloat(value) : value,
      };
      setPurchaseOrder({ ...purchaseOrder, RawMaterials: updatedRawMaterials });
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setVendorBillImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleProcessPurchaseOrder = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const response = await callApi(
        apiEndpoints.NulookErpProcessPurchaseOrder,
        "POST",
        {
          operation: "accept_purchase_order",
          PurchaseOrderId: purchaseOrderId,
          raw_materials: purchaseOrder?.RawMaterials,
          vendor_bill_id: vendorBillId,
          vendor_bill_image: vendorBillImage,
          security_comments: securityComments,
          AssignedTo: assignedTo,
        },
      );

      if (response.data) {
        alert("Purchase Order processed successfully");
        // Reset form
        setPurchaseOrderId("");
        setPurchaseOrder(null);
        setVendorBillId("");
        setVendorBillImage(null);
        setSecurityComments("");
        setAssignedTo("");
      } else {
        setError((response.error as any).message.error);
      }
    } catch (error) {
      setError("Error processing purchase order");
    } finally {
      setIsLoading(false);
    }
  };

  const getRawMaterialDetails = (rawMaterialId: string) => {
    return allRawMaterials.find((rm) => rm.RawMaterialId === rawMaterialId);
  };

  return (
    <NotificationCard>
      {isLoading && (
        <LoadingOverlay>
          <Spinner />
        </LoadingOverlay>
      )}
      <NotificationHeader>
        <h2>Purchase Order Processing</h2>
      </NotificationHeader>
      <form onSubmit={handlePurchaseOrderSubmit}>
        <Input
          type="text"
          value={purchaseOrderId}
          onChange={(e) => setPurchaseOrderId(e.target.value)}
          placeholder="Enter Purchase Order ID"
          required
        />
        <ExpandButton type="submit">Fetch Purchase Order</ExpandButton>
      </form>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {purchaseOrder && (
        <Drawer>
          <form onSubmit={handleProcessPurchaseOrder}>
            <InfoGrid>
              <InfoItem>
                <InfoLabel>Vendor</InfoLabel>
                <InfoValue>{purchaseOrder.VendorName}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Total Price</InfoLabel>
                <InfoValue>Rs {purchaseOrder.TotalPrice.toFixed(2)}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Status</InfoLabel>
                <InfoValue>{purchaseOrder.Status}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Created At</InfoLabel>
                <InfoValue>
                  {new Date(purchaseOrder.CreatedAt * 1000).toLocaleString()}
                </InfoValue>
              </InfoItem>
            </InfoGrid>

            <RawMaterialsList>
              <h4>Raw Materials:</h4>
              <RawMaterialTable>
                <TableHeader>
                  <tr>
                    <TableHeaderCell>Name</TableHeaderCell>
                    <TableHeaderCell>Quantity</TableHeaderCell>
                    <TableHeaderCell>Price</TableHeaderCell>
                  </tr>
                </TableHeader>
                <TableBody>
                  {purchaseOrder.RawMaterials.map((material, index) => {
                    const apiMaterial = getRawMaterialDetails(
                      material.RawMaterialId,
                    );
                    return (
                      <TableRow key={material.RawMaterialId}>
                        <TableCell>{apiMaterial?.Name || "Unknown"}</TableCell>
                        <TableCell>
                          <QuantityInputWrapper>
                            <Input
                              type="number"
                              value={material.Quantity}
                              onChange={(e) =>
                                handleRawMaterialChange(
                                  index,
                                  "Quantity",
                                  e.target.value,
                                )
                              }
                              required
                              style={{ paddingRight: "30px" }}
                            />
                            <BaseUnitLabel>
                              {apiMaterial?.BaseUnit || ""}
                            </BaseUnitLabel>
                          </QuantityInputWrapper>
                        </TableCell>
                        <TableCell>
                          <PriceInputWrapper>
                            <PricePrefix>Rs</PricePrefix>
                            <Input
                              type="number"
                              value={material.Price}
                              onChange={(e) =>
                                handleRawMaterialChange(
                                  index,
                                  "Price",
                                  e.target.value,
                                )
                              }
                              style={{
                                paddingLeft: "25px",
                                textAlign: "right",
                                width: "100%",
                              }}
                              required
                            />
                          </PriceInputWrapper>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </RawMaterialTable>
            </RawMaterialsList>

            <Input
              type="text"
              value={vendorBillId}
              onChange={(e) => setVendorBillId(e.target.value)}
              placeholder="Vendor Bill ID"
              required
              style={{ width: "100%", margin: "0.5rem 0" }}
            />

            <FileInput
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              required
              style={{ width: "100%", margin: "0.5rem 0", border: "none" }}
            />

            <CommentsTextArea
              value={securityComments}
              onChange={(e) => setSecurityComments(e.target.value)}
              placeholder="Security Comments"
              required
            />

            <Select
              value={assignedTo}
              onChange={(e) => setAssignedTo(e.target.value)}
              required
            >
              <option value="">Select Warehouse User</option>
              {warehouseUsers.map((user) => (
                <option key={user.UserId} value={user.UserId}>
                  {user.FullName} ({user.UserId})
                </option>
              ))}
            </Select>

            <ButtonGroup>
              <ProcessButton type="submit">
                Process Purchase Order
              </ProcessButton>
            </ButtonGroup>
          </form>
        </Drawer>
      )}
    </NotificationCard>
  );
};

const NotificationCard = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  font-family: "Space Grotesk", sans-serif;
  overflow: hidden;
`;

const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
`;

const ExpandButton = styled.button`
  background-color: #2c3e50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 1rem;

  &:hover {
    background-color: #34495e;
  }

  &:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
`;

const Drawer = styled.div`
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoLabel = styled.strong`
  font-size: 0.9rem;
  color: #7f8c8d;
`;

const InfoValue = styled.span`
  font-size: 1rem;
  color: #2c3e50;
`;

const RawMaterialsList = styled.div`
  margin-top: 1rem;

  h4 {
    color: #2c3e50;
    margin-bottom: 0.5rem;
  }
`;

const RawMaterialTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: white;
  border-radius: 8px;
  overflow-x: auto;
  display: block;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const TableHeader = styled.thead`
  background-color: #f8f8f8;
`;

const TableHeaderCell = styled.th`
  text-align: left;
  padding: 0.75rem 0.5rem;
  font-weight: 600;
  color: #2c3e50;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.5px;

  @media (max-width: 768px) {
    font-size: 0.7rem;
    padding: 0.5rem 0.25rem;
  }
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled.td`
  padding: 0.75rem 0.5rem;
  border-top: 1px solid #e0e0e0;
  color: #34495e;
  vertical-align: middle;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 120px;
`;

const QuantityInputWrapper = styled(InputWrapper)``;
const PriceInputWrapper = styled(InputWrapper)``;

const InputLabel = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #7f8c8d;
  font-size: 0.9rem;
  pointer-events: none;
`;

const BaseUnitLabel = styled(InputLabel)`
  right: 10px;
`;

const PricePrefix = styled(InputLabel)`
  left: 10px;
`;

const FileInput = styled(Input)`
  padding: 0.5rem 0;
`;

const CommentsTextArea = styled.textarea`
  width: 98%;
  min-height: 100px;

  border: 1px solid #e0e0e0;
  border-radius: 4px;
  resize: vertical;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const ProcessButton = styled(ExpandButton)`
  width: auto;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 1rem;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default PurchaseOrderProcessing;
