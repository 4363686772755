import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { callApi } from "../../../../utils/apiHelper";
import { apiEndpoints } from "../../../../utils/apiEndpoints";
import { User, Sku, CustomerOrder } from "./types";

interface CustomerOrderComponentProps {
  users: User[];
  skus: Sku[];
}

type SortField =
  | "createdAt"
  | "status"
  | "totalQuantity"
  | "totalPrice"
  | "type";
type SortOrder = "asc" | "desc";

const CustomerOrderComponent: React.FC<CustomerOrderComponentProps> = ({
  users,
  skus,
}) => {
  const [orders, setOrders] = useState<CustomerOrder[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<SortField>("createdAt");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  const fetchCustomerOrders = async () => {
    try {
      setLoading(true);
      const response = await callApi<{ orders: CustomerOrder[] }>(
        apiEndpoints.GodownManageCustomerOrder,
        "POST",
        { operation: "get_orders" },
      );
      if (response.data) {
        setOrders(response.data.orders);
      } else if (response.error) {
        throw new Error(response.error.message);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerOrders();
  }, []);

  const getSkuName = (skuId: string) => {
    const sku = skus.find((s) => s.SkuId === skuId);
    return sku ? sku.Name : "Unknown SKU";
  };

  const getUserName = (userId: string) => {
    const user = users.find((u) => u.UserId === userId);
    return user ? user.FullName : "Unknown User";
  };

  const formatDate = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleString(); // Convert Unix timestamp to milliseconds
  };

  const toggleRowExpansion = (orderId: string) => {
    setExpandedRows((prevState) => {
      const newState = new Set(prevState);
      if (newState.has(orderId)) {
        newState.delete(orderId);
      } else {
        newState.add(orderId);
      }
      return newState;
    });
  };

  const sortedAndFilteredOrders = useMemo(() => {
    let filteredOrders = orders.filter((order) => {
      const searchTermLower = searchTerm.toLowerCase();
      const orderIdMatch =
        order.CustomerOrderReturnId.toLowerCase().includes(searchTermLower);
      const createdByMatch = getUserName(order.CreatedBy)
        .toLowerCase()
        .includes(searchTermLower);
      const skuMatch = order.Sku.some((sku) =>
        getSkuName(sku.SkuId).toLowerCase().includes(searchTermLower),
      );
      const statusMatch = order.Status.toLowerCase().includes(searchTermLower);
      return orderIdMatch || createdByMatch || skuMatch || statusMatch;
    });

    return filteredOrders.sort((a, b) => {
      const compareValues = (valA: any, valB: any) => {
        if (valA < valB) return sortOrder === "asc" ? -1 : 1;
        if (valA > valB) return sortOrder === "asc" ? 1 : -1;
        return 0;
      };

      switch (sortField) {
        case "createdAt":
          return compareValues(a.CreatedAt, b.CreatedAt);
        case "status":
          return compareValues(a.Status, b.Status);
        case "totalQuantity":
          return compareValues(a.TotalQuantity, b.TotalQuantity);
        case "totalPrice":
          return compareValues(a.TotalPrice, b.TotalPrice);
        case "type":
          return compareValues(a.Type, b.Type);
        default:
          return 0;
      }
    });
  }, [orders, searchTerm, sortField, sortOrder, users, skus]);

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const SortButton = ({
    field,
    children,
  }: {
    field: SortField;
    children: React.ReactNode;
  }) => (
    <SortButtonStyled onClick={() => handleSort(field)}>
      {children} {sortField === field && (sortOrder === "asc" ? "▲" : "▼")}
    </SortButtonStyled>
  );

  if (loading) {
    return <div>Loading customer orders...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <CustomerOrderContainer>
      <h2>Customer Orders</h2>
      <SearchBar>
        <input
          type="text"
          placeholder="Search by Order ID, Created By, SKU Name, or Status"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchBar>
      {sortedAndFilteredOrders.length === 0 ? (
        <p>No orders found.</p>
      ) : (
        <ResponsiveTableContainer>
          <ResponsiveTable>
            <thead>
              <tr>
                <th>
                  <SortButton field="createdAt">Created At</SortButton>
                </th>
                <th>Order ID</th>
                <th>
                  <SortButton field="status">Status</SortButton>
                </th>
                <th>
                  <SortButton field="totalQuantity">Total Quantity</SortButton>
                </th>
                <th>
                  <SortButton field="totalPrice">Total Price</SortButton>
                </th>
                <th>
                  <SortButton field="type">Type</SortButton>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedAndFilteredOrders.map((order) => (
                <React.Fragment key={order.CustomerOrderReturnId}>
                  <tr>
                    <td data-label="Created At">
                      {formatDate(order.CreatedAt)}
                    </td>
                    <td data-label="Order ID">{order.CustomerOrderReturnId}</td>
                    <td data-label="Status">{order.Status}</td>
                    <td data-label="Total Quantity">{order.TotalQuantity}</td>
                    <td data-label="Total Price">{order.TotalPrice}</td>
                    <td data-label="Type">{order.Type}</td>
                    <td data-label="Actions">
                      <ExpandButton
                        onClick={() =>
                          toggleRowExpansion(order.CustomerOrderReturnId)
                        }
                      >
                        {expandedRows.has(order.CustomerOrderReturnId)
                          ? "Hide"
                          : "Details"}
                      </ExpandButton>
                    </td>
                  </tr>
                  {expandedRows.has(order.CustomerOrderReturnId) && (
                    <tr>
                      <td colSpan={7}>
                        <ExpandedContent>
                          <DetailItem>
                            <strong>Created By:</strong>{" "}
                            {getUserName(order.CreatedBy)}
                          </DetailItem>
                          <DetailItem>
                            <strong>Created At:</strong>{" "}
                            {formatDate(order.CreatedAt)}
                          </DetailItem>
                          <DetailItem>
                            <strong>Updated At:</strong>{" "}
                            {formatDate(order.UpdatedAt)}
                          </DetailItem>
                          <DetailItem>
                            <strong>Bill No:</strong> {order.BillNo}
                          </DetailItem>
                          <DetailItem>
                            <strong>Bill Image:</strong>{" "}
                            {order.BillImageUrl ? (
                              <ViewBillButton
                                onClick={() =>
                                  window.open(order.BillImageUrl, "_blank")
                                }
                              >
                                View Bill Image
                              </ViewBillButton>
                            ) : (
                              "Not available"
                            )}
                          </DetailItem>
                          <h4>Requested SKUs</h4>
                          <ul>
                            {order.Sku.map((sku, index) => (
                              <li key={index}>
                                {getSkuName(sku.SkuId)}: Quantity -{" "}
                                {sku.Quantity}, B Quantity - {sku.BQuantity}
                              </li>
                            ))}
                          </ul>
                          {order.ConfirmedSku &&
                            order.ConfirmedSku.length > 0 && (
                              <>
                                <h4>Approved SKUs</h4>
                                <ul>
                                  {order.ConfirmedSku.map((sku, index) => (
                                    <li key={index}>
                                      {getSkuName(sku.SkuId)}: Quantity -{" "}
                                      {sku.Quantity}
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}
                        </ExpandedContent>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </ResponsiveTable>
        </ResponsiveTableContainer>
      )}
    </CustomerOrderContainer>
  );
};

const CustomerOrderContainer = styled.div`
  margin-top: 2rem;
  color: #333;
  font-family: Arial, sans-serif;
`;

const SearchBar = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 600px;

  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      font-size: 0.9rem;
      padding: 0.4rem;
    }
  }
`;

const ResponsiveTableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background-color: #fff;
  font-size: 0.9rem;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f8f8f8;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;

    th,
    td {
      padding: 6px;
    }
  }
`;

const SortButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const ExpandButton = styled.button`
  background-color: #333;
  color: #fff;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 0.8rem;

  &:hover {
    background-color: #555;
  }
`;

const ExpandedContent = styled.div`
  padding: 1rem;
  background-color: #f8f8f8;

  h4 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: #333;
  }
`;

const DetailItem = styled.div`
  margin-bottom: 0.5rem;
`;

const ViewBillButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: #45a049;
  }
`;

export default CustomerOrderComponent;
