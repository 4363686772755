import React, { useState } from "react";
import { CustomerOrder, Sku, User } from "./types";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Grid,
  Collapse,
  createTheme,
  ThemeProvider,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface Props {
  orders: CustomerOrder[];
  onOrderAction: (
    orderId: string,
    accept: boolean,
    skulist?: Array<{ SkuId: string; Quantity: number; BQuantity: number }>,
    billNo?: string,
    billImage?: string,
  ) => Promise<void>;
  skus: Sku[];
  users: User[];
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#ffffff",
      paper: "#f5f5f5",
    },
    text: {
      primary: "#000000",
      secondary: "#404040",
    },
  },
});

const CustomerOrdersSection: React.FC<Props> = ({
  orders,
  onOrderAction,
  skus,
  users,
}) => {
  console.log(orders, skus);
  const [billNo, setBillNo] = useState<string>("");
  const [billImage, setBillImage] = useState<string>("");
  const [editingSkus, setEditingSkus] = useState<{ [key: string]: boolean }>(
    {},
  );
  const [updatedSkus, setUpdatedSkus] = useState<{
    [key: string]: { Quantity: number; BQuantity: number };
  }>({});
  const [expandedOrders, setExpandedOrders] = useState<{
    [key: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error" | "info" | "warning";
  }>({
    open: false,
    message: "",
    severity: "info",
  });

  const getSkuName = (skuId: string) => {
    const sku = skus.find((s) => s.SkuId === skuId);
    return sku ? sku.Name : "Unknown SKU";
  };

  const getUserName = (userId: string) => {
    const user = users.find((u) => u.UserId === userId);
    return user ? user.FullName : "Unknown User";
  };

  const handleAccept = async (order: CustomerOrder) => {
    setIsLoading(true);
    const updatedSkuList = order.Sku.map((sku) => ({
      ...sku,
      ...(updatedSkus[sku.SkuId] || {}),
    }));
    try {
      await onOrderAction(
        order.CustomerOrderReturnId,
        true,
        updatedSkuList,
        billNo,
        billImage,
      );
      setSnackbar({
        open: true,
        message: "Order accepted successfully",
        severity: "success",
      });
      setBillNo("");
      setBillImage("");
      setUpdatedSkus({});
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to accept order",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReject = async (orderId: string) => {
    setIsLoading(true);
    try {
      await onOrderAction(orderId, false);
      setSnackbar({
        open: true,
        message: "Order rejected successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to reject order",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setBillImage(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleSkuEdit = (orderId: string, skuId: string) => {
    setEditingSkus((prev) => ({
      ...prev,
      [`${orderId}-${skuId}`]: !prev[`${orderId}-${skuId}`],
    }));
  };

  const getMaxQuantity = (skuId: string, orderQuantity: number) => {
    const sku = skus.find((s) => s.SkuId === skuId);
    if (!sku) return 0;
    return Math.min(orderQuantity, sku.Quantity);
  };
  const getSkuQuantity = (
    skuId: string,
  ): { Quantity: number; BQuantity: number } => {
    const sku = skus.find((s) => s.SkuId === skuId);
    return sku
      ? { Quantity: sku.Quantity, BQuantity: sku.BQuantity }
      : { Quantity: 0, BQuantity: 0 };
  };

  const getMaxBQuantity = (skuId: string, orderBQuantity: number) => {
    const sku = skus.find((s) => s.SkuId === skuId);
    if (!sku) return 0;
    return Math.min(orderBQuantity, sku.BQuantity);
  };

  const updateSkuQuantities = (
    skuId: string,
    quantity: number,
    bQuantity: number,
  ) => {
    setUpdatedSkus((prev) => ({
      ...prev,
      [skuId]: {
        Quantity: Math.max(0, quantity),
        BQuantity: Math.max(0, bQuantity),
      },
    }));
  };

  const toggleOrderExpansion = (orderId: string) => {
    setExpandedOrders((prev) => ({ ...prev, [orderId]: !prev[orderId] }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: 3, position: "relative" }}>
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1000,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Typography variant="h4" gutterBottom>
          Customer Orders
        </Typography>
        {orders.map((order) => (
          <Card key={order.CustomerOrderReturnId} sx={{ mb: 3 }}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={3}>
                  <Chip
                    label={order.Status}
                    color={getStatusColor(order.Status)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="body2">
                    Total Price: ₹{order.TotalPrice}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    {new Date(order.CreatedAt * 1000).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body2">
                    Ordered By: <strong>{getUserName(order.CreatedBy)}</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={1}>
                  <IconButton
                    onClick={() =>
                      toggleOrderExpansion(order.CustomerOrderReturnId)
                    }
                  >
                    {expandedOrders[order.CustomerOrderReturnId] ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>

              <Collapse in={expandedOrders[order.CustomerOrderReturnId]}>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    SKUs:
                  </Typography>
                  <List>
                    {order.Sku.map((sku) => (
                      <ListItem key={sku.SkuId} divider>
                        <ListItemText
                          primary={getSkuName(sku.SkuId)}
                          secondary={`Available: ${getSkuQuantity(sku.SkuId).Quantity} (B: ${getSkuQuantity(sku.SkuId).BQuantity})`}
                        />
                        {editingSkus[
                          `${order.CustomerOrderReturnId}-${sku.SkuId}`
                        ] ? (
                          <>
                            <TextField
                              type="number"
                              label="Quantity"
                              value={
                                updatedSkus[sku.SkuId]?.Quantity ?? sku.Quantity
                              }
                              onChange={(e) =>
                                updateSkuQuantities(
                                  sku.SkuId,
                                  Math.min(
                                    Number(e.target.value),
                                    getSkuQuantity(sku.SkuId).Quantity,
                                    sku.Quantity,
                                  ),
                                  updatedSkus[sku.SkuId]?.BQuantity ??
                                    sku.BQuantity,
                                )
                              }
                              size="small"
                              sx={{ width: 100, mr: 1 }}
                              InputProps={{
                                inputProps: {
                                  min: 0,
                                  max: Math.min(
                                    getSkuQuantity(sku.SkuId).Quantity,
                                    sku.Quantity,
                                  ),
                                },
                              }}
                            />

                            <IconButton
                              onClick={() =>
                                toggleSkuEdit(
                                  order.CustomerOrderReturnId,
                                  sku.SkuId,
                                )
                              }
                              color="primary"
                            >
                              <SaveIcon />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <Typography variant="body2" sx={{ mr: 2 }}>
                              Quantity:{" "}
                              {updatedSkus[sku.SkuId]?.Quantity ?? sku.Quantity}
                            </Typography>
                            <Typography variant="body2" sx={{ mr: 2 }}>
                              B-Quantity:{" "}
                              {updatedSkus[sku.SkuId]?.BQuantity ??
                                sku.BQuantity}
                            </Typography>
                            <IconButton
                              onClick={() =>
                                toggleSkuEdit(
                                  order.CustomerOrderReturnId,
                                  sku.SkuId,
                                )
                              }
                              color="primary"
                            >
                              <EditIcon />
                            </IconButton>
                          </>
                        )}
                      </ListItem>
                    ))}
                  </List>
                  {order.Status === "pending" && (
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        fullWidth
                        label="Bill Number"
                        value={billNo}
                        onChange={(e) => setBillNo(e.target.value)}
                        sx={{ mb: 2 }}
                      />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        style={{ display: "none" }}
                        id={`file-upload-${order.CustomerOrderReturnId}`}
                      />
                      <label
                        htmlFor={`file-upload-${order.CustomerOrderReturnId}`}
                      >
                        <Button
                          variant="outlined"
                          component="span"
                          startIcon={<FileUploadIcon />}
                          fullWidth
                          sx={{ mb: 2 }}
                        >
                          Upload Bill Image
                        </Button>
                      </label>
                      {billImage && (
                        <Box sx={{ mb: 2, textAlign: "center" }}>
                          <img
                            src={billImage}
                            alt="Bill"
                            style={{ maxWidth: "100%", maxHeight: 200 }}
                          />
                        </Box>
                      )}
                      <Box display="flex" justifyContent="space-between">
                        <Button
                          variant="contained"
                          onClick={() => handleAccept(order)}
                          disabled={!billNo || !billImage || isLoading}
                          sx={{ flex: 1, mr: 1 }}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            handleReject(order.CustomerOrderReturnId)
                          }
                          disabled={isLoading}
                          sx={{ flex: 1, ml: 1 }}
                        >
                          Reject
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Collapse>
            </CardContent>
          </Card>
        ))}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

const getStatusColor = (
  status: string,
):
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning" => {
  switch (status) {
    case "pending":
      return "warning";
    case "out for delivery":
      return "success";
    case "cancelled":
    case "rejected":
      return "error";
    default:
      return "default";
  }
};

export default CustomerOrdersSection;
