import { fetchAuthSession, getCurrentUser, signOut } from "aws-amplify/auth";

interface ApiResponse<T> {
  data: T | null;
  error: {
    code: number | null;
    message: string;
  } | null;
}

export async function callApi<T>(
  endpoint: string,
  method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
  body?: any,
): Promise<ApiResponse<T>> {
  try {
    const { tokens } = await fetchAuthSession();
    const token = tokens?.idToken?.toString();

    if (!token) {
      throw new Error("No authentication token available");
    }

    const response = await fetch(endpoint, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: body ? JSON.stringify(body) : undefined,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        JSON.stringify({
          code: response.status,
          message: errorData || `HTTP error! status: ${response.status}`,
        }),
      );
    }

    const data = await response.json();
    return { data, error: null };
  } catch (error) {
    console.error("API call failed:", error);
    let errorMessage = "An unknown error occurred";
    let errorCode = null;

    if (error instanceof Error) {
      try {
        const parsedError = JSON.parse(error.message);
        errorMessage = parsedError.message;
        errorCode = parsedError.code;
      } catch {
        errorMessage = error.message;
      }
    }

    return {
      data: null,
      error: {
        code: errorCode,
        message: errorMessage,
      },
    };
  }
}
interface AuthStateCallbacks {
  onAuthenticated: () => Promise<void>;
  onUnauthenticated?: () => void;
}
export async function checkCurrentAuthState(
  callbacks: AuthStateCallbacks,
): Promise<boolean> {
  try {
    const user = await getCurrentUser();
    if (user) {
      console.log("User is already signed in");
      await callbacks.onAuthenticated();
      return true;
    } else {
      console.log("No current user signed in");
      callbacks.onUnauthenticated?.();
      return false;
    }
  } catch (error) {
    console.log("Error checking auth state:", error);
    callbacks.onUnauthenticated?.();
    return false;
  }
}

export async function logout(): Promise<void> {
  try {
    await signOut();
    console.log("User signed out successfully");
    window.location.href = "/";
  } catch (error) {
    console.error("Error signing out:", error);
    throw error;
  }
}
