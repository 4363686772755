import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  TextField,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { callApi } from "../../../utils/apiHelper";
import { apiEndpoints } from "../../../utils/apiEndpoints";
import { Project, RawMaterial, Sku, User } from "./types";

interface WastageModalProps {
  project: Project;
  onClose: () => void;
  rawMaterials: RawMaterial[];
  sku: Sku | undefined;
  users: User[];
}

const WastageModal: React.FC<WastageModalProps> = ({
  project,
  onClose,
  rawMaterials,
  sku,
}) => {
  const [selectedMaterials, setSelectedMaterials] = useState<RawMaterial[]>([]);
  const [quantities, setQuantities] = useState<{ [key: string]: number }>({});
  const [operation, setOperation] = useState<"increase" | "decrease">(
    "increase",
  );
  const [selectedMaterial, setSelectedMaterial] = useState<string>("");
  const [maxQuantities, setMaxQuantities] = useState<{ [key: string]: number }>(
    {},
  );

  useEffect(() => {
    updateMaxQuantities();
  }, [rawMaterials, project, operation]);

  const updateMaxQuantities = () => {
    const newMaxQuantities: { [key: string]: number } = {};
    rawMaterials.forEach((material) => {
      newMaxQuantities[material.RawMaterialId] = calculateMaxQuantity(
        material.RawMaterialId,
      );
    });
    setMaxQuantities(newMaxQuantities);

    // Reset quantities when switching operations
    const resetQuantities: { [key: string]: number } = {};
    rawMaterials.forEach((material) => {
      resetQuantities[material.RawMaterialId] = 0;
    });
    setQuantities(resetQuantities);
  };

  const calculateMaxQuantity = (materialId: string): number => {
    const currentConsumption = project.CurrentConsumption[materialId] || 0;
    const currentWastage = project.CurrentWastage[materialId] || 0;
    if (operation === "increase") {
      return Math.max(0, currentConsumption - currentWastage);
    } else {
      return currentWastage;
    }
  };

  const handleAddMaterial = () => {
    if (
      selectedMaterial &&
      !selectedMaterials.some((m) => m.RawMaterialId === selectedMaterial)
    ) {
      const materialToAdd = rawMaterials.find(
        (m) => m.RawMaterialId === selectedMaterial,
      );
      if (materialToAdd) {
        setSelectedMaterials([...selectedMaterials, materialToAdd]);
        setQuantities({ ...quantities, [selectedMaterial]: 0 });
      }
    }
    setSelectedMaterial("");
  };

  const handleRemoveMaterial = (materialId: string) => {
    setSelectedMaterials(
      selectedMaterials.filter((m) => m.RawMaterialId !== materialId),
    );
    const newQuantities = { ...quantities };
    delete newQuantities[materialId];
    setQuantities(newQuantities);
  };

  const handleQuantityChange = (materialId: string, quantity: number) => {
    const maxQuantity = maxQuantities[materialId];
    const newQuantity = Math.min(Math.max(0, quantity), maxQuantity);
    setQuantities({ ...quantities, [materialId]: newQuantity });
  };

  const handleOperationChange = (newOperation: "increase" | "decrease") => {
    setOperation(newOperation);
    updateMaxQuantities();
  };

  const handleSubmit = async () => {
    const materialsToSubmit = selectedMaterials.map((material) => ({
      RawMaterialId: material.RawMaterialId,
      Quantity: quantities[material.RawMaterialId],
    }));

    try {
      const response = await callApi(apiEndpoints.RequestWastage, "POST", {
        ProjectId: project.ProductionProjectId,
        raw_materials: materialsToSubmit,
        request_type: operation,
      });

      if (response.error) {
        throw new Error(response.error.message);
      }

      alert(`Wastage ${operation} request submitted successfully`);
      onClose();
    } catch (error) {
      console.error("Error submitting wastage request:", error);
      // alert(`Error: ${error.message}`);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Wastage {operation === "increase" ? "Increase" : "Decrease"} Request
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          Project ID: {project.ProductionProjectId}
        </Typography>
        {sku && (
          <Typography variant="body2" gutterBottom>
            SKU: {sku.Name}
          </Typography>
        )}

        <Box my={2}>
          <Select
            value={operation}
            onChange={(e) =>
              handleOperationChange(e.target.value as "increase" | "decrease")
            }
            fullWidth
            variant="outlined"
          >
            <MenuItem value="increase">Increase Wastage</MenuItem>
            <MenuItem value="decrease">Decrease Wastage</MenuItem>
          </Select>
        </Box>

        <Box display="flex" my={2}>
          <Select
            value={selectedMaterial}
            onChange={(e) => setSelectedMaterial(e.target.value as string)}
            fullWidth
            variant="outlined"
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Raw Material
            </MenuItem>
            {rawMaterials.map((material) => (
              <MenuItem
                key={material.RawMaterialId}
                value={material.RawMaterialId}
              >
                {material.Name} ({material.BaseUnit})
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddMaterial}
            startIcon={<AddIcon />}
            style={{ marginLeft: "8px" }}
          >
            Add
          </Button>
        </Box>

        <List>
          {selectedMaterials.map((material, index) => (
            <React.Fragment key={material.RawMaterialId}>
              {index > 0 && <Divider />}
              <ListItem>
                <ListItemText
                  primary={material.Name}
                  secondary={`(${material.BaseUnit})`}
                />
                <TextField
                  type="number"
                  value={quantities[material.RawMaterialId]}
                  onChange={(e) =>
                    handleQuantityChange(
                      material.RawMaterialId,
                      parseFloat(e.target.value),
                    )
                  }
                  inputProps={{
                    min: 0,
                    max: maxQuantities[material.RawMaterialId],
                    step: 0.0000001,
                  }}
                  variant="outlined"
                  size="small"
                  style={{ width: "100px", marginRight: "8px" }}
                />
                <Typography variant="caption" style={{ marginRight: "8px" }}>
                  Max: {maxQuantities[material.RawMaterialId]}
                </Typography>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemoveMaterial(material.RawMaterialId)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WastageModal;
