import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Typography,
  Button,
  styled,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { Home, ShoppingCart, History } from "@mui/icons-material";
import { callApi, logout } from "../../utils/apiHelper";
import { apiEndpoints } from "../../utils/apiEndpoints";
import HomeTab from "./HomeTab";
import CartTab from "./CartTab";
import OrderHistoryTab from "./OrderHistoryTab";
import { Sku } from "./types";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#ffffff",
      paper: "#f5f5f5",
    },
    text: {
      primary: "#000000",
      secondary: "#404040",
    },
  },
});

const CustomerDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const [skuData, setSkuData] = useState<{ skus: Sku[] }>({ skus: [] });
  const [cart, setCart] = useState<Sku[]>([]);
  const companyName = process.env.REACT_APP_COMPANY_NAME || "Company Name";

  useEffect(() => {
    fetchSkuData();
  }, []);

  const fetchSkuData = async () => {
    const response = await callApi<{ skus: Sku[] }>(
      apiEndpoints.NulookGetSku,
      "POST",
      {
        operation: "get_sku_with_godown_stock",
      },
    );

    if (response.data) {
      console.log("SKU DATA", response.data);
      setSkuData(response.data);
    } else {
      console.error("Failed to fetch SKU data:", response.error);
    }
  };

  const addToCart = (
    sku: Sku,
    orderQuantity: number,
    orderBQuantity: number,
  ) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.findIndex(
        (item) => item.SkuId === sku.SkuId,
      );
      if (existingItemIndex !== -1) {
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex] = {
          ...updatedCart[existingItemIndex],
          orderQuantity,
          orderBQuantity,
        };
        return updatedCart;
      } else {
        return [...prevCart, { ...sku, orderQuantity, orderBQuantity }];
      }
    });
  };

  const placeOrder = async () => {
    const orderData = {
      operation: "create",
      skulist: cart.map((item) => ({
        SkuId: item.SkuId,
        Quantity: item.orderQuantity,
        BQuantity: 0,
      })),
      customer_comment: "",
    };

    const response = await callApi(
      apiEndpoints.CustomermanageCustomerOrder,
      "POST",
      orderData,
    );

    if (response.data) {
      console.log("Order placed successfully:", response.data);
      setCart([]);
    } else {
      console.error("Failed to place order:", response.error);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login"); // Navigate to login page after successful logout
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <DashboardContainer>
        <StyledHeader>
          <CompanyName>{companyName}</CompanyName>
          <NavMenu>
            <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
          </NavMenu>
        </StyledHeader>
        <ContentArea>
          {currentTab === 0 && (
            <HomeTab skuData={skuData} addToCart={addToCart} cart={cart} />
          )}
          {currentTab === 1 && <CartTab cart={cart} placeOrder={placeOrder} />}
          {currentTab === 2 && <OrderHistoryTab skuData={skuData} />}
        </ContentArea>
        <StyledPaper elevation={3}>
          <BottomNavigation
            showLabels
            value={currentTab}
            onChange={(event, newValue) => {
              setCurrentTab(newValue);
            }}
          >
            <BottomNavigationAction label="Home" icon={<Home />} />
            <BottomNavigationAction label="Cart" icon={<ShoppingCart />} />
            <BottomNavigationAction label="History" icon={<History />} />
          </BottomNavigation>
        </StyledPaper>
      </DashboardContainer>
    </ThemeProvider>
  );
};

const DashboardContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
});

const StyledHeader = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const CompanyName = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "bold",
  color: theme.palette.text.primary,
}));

const NavMenu = styled(Box)({
  display: "flex",
  gap: "1rem",
});

const LogoutButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  border: "none",
  padding: "0.5rem 1rem",
  fontSize: "0.9rem",
  cursor: "pointer",
  transition: "all 0.3s ease",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const ContentArea = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: "auto",
  padding: theme.spacing(2),
  paddingBottom: "56px", // Height of the bottom navigation
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
}));

export default CustomerDashboard;
