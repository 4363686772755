import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { callApi } from "../../../utils/apiHelper";
import { apiEndpoints } from "../../../utils/apiEndpoints";

interface Sku {
  SkuId: string;
  Name: string;
}

interface RawMaterial {
  RawMaterialId: string;
  Name: string;
}

interface User {
  UserId: string;
  FullName: string;
  Role: number;
}

interface TransactionPayload {
  TransactionType: "SKU" | "RawMaterial";
  ItemId: string;
  QuantityChange: number;
  StorageId: string;
  Reason: string;
  Comment: string;
  BQuantityChange?: number;
}

const ManualTransaction: React.FC = () => {
  const [skus, setSkus] = useState<Sku[]>([]);
  const [rawMaterials, setRawMaterials] = useState<RawMaterial[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [transactionType, setTransactionType] = useState<"SKU" | "RawMaterial">(
    "SKU",
  );
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [quantity, setQuantity] = useState("");
  const [bQuantity, setBQuantity] = useState("");
  const [reason, setReason] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    fetchSkus();
    fetchRawMaterials();
    fetchUsers();
  }, []);

  const fetchSkus = async () => {
    try {
      const response = await callApi<{ skus: Sku[] }>(
        apiEndpoints.NulookGetSku,
        "GET",
      );
      if (response.data) {
        setSkus(response.data.skus);
      }
    } catch (error) {
      console.error("Error fetching SKUs:", error);
    }
  };

  const fetchRawMaterials = async () => {
    try {
      const response = await callApi<{ rawMaterials: RawMaterial[] }>(
        apiEndpoints.getAllVisibleRawMaterials,
        "GET",
      );
      if (response.data) {
        setRawMaterials(response.data.rawMaterials);
      }
    } catch (error) {
      console.error("Error fetching raw materials:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await callApi<{ users: User[] }>(
        apiEndpoints.getAllVisibleUsers,
        "GET",
      );
      if (response.data) {
        setUsers(response.data.users);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const payload: TransactionPayload = {
        TransactionType: transactionType,
        ItemId: selectedItem,
        QuantityChange: parseFloat(quantity),
        StorageId: selectedUser,
        Reason: reason,
        Comment: comment,
      };

      if (transactionType === "SKU" && bQuantity) {
        payload.BQuantityChange = parseFloat(bQuantity);
      }

      const response = await callApi(
        apiEndpoints.CreateTransaction,
        "POST",
        payload,
      );
      if (response.data) {
        alert("Transaction created successfully");
        // Reset form
        setSelectedItem("");
        setSelectedUser("");
        setQuantity("");
        setBQuantity("");
        setReason("");
        setComment("");
      }
    } catch (error) {
      console.error("Error creating transaction:", error);
      alert("Failed to create transaction");
    }
  };

  const filteredUsers = users.filter(
    (user) =>
      (transactionType === "SKU" && user.Role === 8) ||
      (transactionType === "RawMaterial" && user.Role === 4),
  );

  return (
    <Container>
      <h2>Manual Transaction</h2>
      <Form onSubmit={handleSubmit}>
        <Label>
          Transaction Type:
          <Select
            value={transactionType}
            onChange={(e) =>
              setTransactionType(e.target.value as "SKU" | "RawMaterial")
            }
          >
            <option value="SKU">SKU</option>
            <option value="RawMaterial">Raw Material</option>
          </Select>
        </Label>
        <Label>
          {transactionType === "SKU" ? "SKU" : "Raw Material"}:
          <Select
            value={selectedItem}
            onChange={(e) => setSelectedItem(e.target.value)}
            required
          >
            <option value="">
              Select {transactionType === "SKU" ? "SKU" : "Raw Material"}
            </option>
            {transactionType === "SKU"
              ? skus.map((sku) => (
                  <option key={sku.SkuId} value={sku.SkuId}>
                    {sku.Name}
                  </option>
                ))
              : rawMaterials.map((rawMaterial) => (
                  <option
                    key={rawMaterial.RawMaterialId}
                    value={rawMaterial.RawMaterialId}
                  >
                    {rawMaterial.Name}
                  </option>
                ))}
          </Select>
        </Label>
        <Label>
          {transactionType === "SKU" ? "Godown" : "Warehouse"}:
          <Select
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            required
          >
            <option value="">
              Select {transactionType === "SKU" ? "Godown" : "Warehouse"}
            </option>
            {filteredUsers.map((user) => (
              <option key={user.UserId} value={user.UserId}>
                {user.FullName}
              </option>
            ))}
          </Select>
        </Label>
        <Label>
          Quantity:
          <Input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            required
            step="0.01"
          />
        </Label>
        {transactionType === "SKU" && (
          <Label>
            B Quantity:
            <Input
              type="number"
              value={bQuantity}
              onChange={(e) => setBQuantity(e.target.value)}
              step="0.01"
            />
          </Label>
        )}
        <Label>
          Reason:
          <Input
            type="text"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
          />
        </Label>
        <Label>
          Comment:
          <Textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Label>
        <Button type="submit">Create Transaction</Button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 400px;
  margin: 0 auto;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Select = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const Input = styled.input`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const Textarea = styled.textarea`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: vertical;
  min-height: 100px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

export default ManualTransaction;
