import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { callApi } from "../../../utils/apiHelper";
import { apiEndpoints } from "../../../utils/apiEndpoints";

interface RawMaterial {
  RawMaterialId: string;
  CompanyId: string;
  CreatedAt: number;
  UpdatedAt: number;
  BaseUnit: string;
  Code: string;
  Description: string;
  Name: string;
  Price: number;
  Units: string;
  Visibility: boolean;
  RawMaterialType: "auto allot" | "Fixed asset" | "admin controlled allot";
}

interface RawMaterialResponse {
  raw_materials: RawMaterial[];
}

const RawMaterialManagement: React.FC = () => {
  const [rawMaterials, setRawMaterials] = useState<RawMaterial[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<"create" | "edit">("create");
  const [selectedRawMaterial, setSelectedRawMaterial] =
    useState<RawMaterial | null>(null);
  const [formData, setFormData] = useState<Partial<RawMaterial>>({
    Name: "",
    Code: "",
    Description: "",
    BaseUnit: "",
    Units: "",
    Price: 0,
    Visibility: true,
    RawMaterialType: "auto allot",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
    type: "success" | "error";
  }>({
    show: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    fetchRawMaterials();
  }, []);

  const fetchRawMaterials = async () => {
    setIsLoading(true);
    try {
      const response = await callApi<RawMaterialResponse>(
        apiEndpoints.NulookRawMaterialManagement,
        "POST",
        { operation: "get_all" },
      );
      if (response.data) {
        setRawMaterials(response.data.raw_materials);
      }
    } catch (error) {
      showSnackbar("Failed to fetch raw materials", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "number"
          ? parseFloat(value)
          : type === "checkbox"
            ? (e.target as HTMLInputElement).checked
            : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const operation = modalMode === "create" ? "create" : "update";
      const payload = {
        operation,
        ...formData,
        RawMaterialId: selectedRawMaterial?.RawMaterialId,
      };

      await callApi(apiEndpoints.NulookRawMaterialManagement, "POST", payload);
      fetchRawMaterials();
      setIsModalOpen(false);
      setFormData({
        Name: "",
        Code: "",
        Description: "",
        BaseUnit: "",
        Units: "",
        Price: 0,
        Visibility: true,
        RawMaterialType: "auto allot",
      });
      showSnackbar(
        `Raw material ${operation === "create" ? "created" : "updated"} successfully`,
        "success",
      );
    } catch (error) {
      showSnackbar(`Failed to ${modalMode} raw material`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = (mode: "create" | "edit", rawMaterial?: RawMaterial) => {
    setModalMode(mode);
    setSelectedRawMaterial(rawMaterial || null);
    if (rawMaterial && mode === "edit") {
      setFormData({
        ...rawMaterial,
      });
    } else {
      setFormData({
        Name: "",
        Code: "",
        Description: "",
        BaseUnit: "",
        Units: "",
        Price: 0,
        Visibility: true,
        RawMaterialType: "auto allot",
      });
    }
    setIsModalOpen(true);
  };

  const showSnackbar = (message: string, type: "success" | "error") => {
    setSnackbar({ show: true, message, type });
    setTimeout(
      () => setSnackbar({ show: false, message: "", type: "success" }),
      3000,
    );
  };

  const filteredRawMaterials = rawMaterials.filter(
    (material) =>
      material.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      material.Code.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Container>
      <Header>
        <h2>Raw Material Management</h2>
        <SearchBar
          type="text"
          placeholder="Search Raw Materials..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button onClick={() => openModal("create")}>
          Create New Raw Material
        </Button>
      </Header>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Code</th>
              <th>Base Unit</th>
              <th>Price</th>
              <th>Visibility</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredRawMaterials.map((material) => (
              <tr key={material.RawMaterialId}>
                <td>{material.Name}</td>
                <td>{material.Code}</td>
                <td>{material.BaseUnit}</td>
                <td>Rs {material.Price.toFixed(2)}</td>
                <td>{material.Visibility ? "Visible" : "Hidden"}</td>
                <td>{material.RawMaterialType}</td>
                <td>
                  <ActionButtons>
                    <Button onClick={() => openModal("edit", material)}>
                      Edit
                    </Button>
                  </ActionButtons>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      {isModalOpen && (
        <Modal>
          <ModalContent>
            <h3>
              {modalMode === "create"
                ? "Create New Raw Material"
                : "Edit Raw Material"}
            </h3>
            <form onSubmit={handleSubmit}>
              <label>
                Name
                <input
                  type="text"
                  name="Name"
                  value={formData.Name}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Code
                <input
                  type="text"
                  name="Code"
                  value={formData.Code}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Description
                <input
                  type="text"
                  name="Description"
                  value={formData.Description}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Base Unit
                <input
                  type="text"
                  name="BaseUnit"
                  value={formData.BaseUnit}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Units
                <input
                  type="text"
                  name="Units"
                  value={formData.Units}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Price
                <input
                  type="number"
                  name="Price"
                  value={formData.Price}
                  onChange={handleInputChange}
                  required
                  step="0.01"
                />
              </label>
              <label>
                Visibility
                <select
                  name="Visibility"
                  value={formData.Visibility ? "Visible" : "Hidden"}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      Visibility: e.target.value === "Visible",
                    })
                  }
                  required
                >
                  <option value="Visible">Visible</option>
                  <option value="Hidden">Hidden</option>
                </select>
              </label>
              <label>
                Raw Material Type
                <select
                  name="RawMaterialType"
                  value={formData.RawMaterialType}
                  onChange={handleInputChange}
                  required
                >
                  <option value="auto allot">Auto Allot</option>
                  <option value="Fixed asset">Fixed Asset</option>
                  <option value="admin controlled allot">
                    Admin Controlled Allot
                  </option>
                </select>
              </label>
              <Button className="full-width" type="submit">
                {modalMode === "create" ? "Create" : "Update"}
              </Button>
            </form>
            <Button
              onClick={() => setIsModalOpen(false)}
              style={{
                marginTop: "1rem",
                width: "100%",
              }}
            >
              Cancel
            </Button>
          </ModalContent>
        </Modal>
      )}
      {isLoading && (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      )}
      {snackbar.show && (
        <Snackbar type={snackbar.type}>{snackbar.message}</Snackbar>
      )}
    </Container>
  );
};

const Container = styled.div`
  font-family: "Space Grotesk", sans-serif;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 1200px;
`;

const SearchBar = styled.input`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 25px;
  transition: all 0.3s ease;
  width: 200px;

  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
    width: 220px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Table = styled.table`
  width: 100%;
  max-width: 1200px;
  border-collapse: separate;
  border-spacing: 0;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  th,
  td {
    text-align: center;
    padding: 1rem;
    border-bottom: 1px solid #eee;
  }

  th {
    background-color: #f8f8f8;
    color: #2c3e50;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  tr:last-child td {
    border-bottom: none;
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const Button = styled.button`
  background-color: #000000;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px;
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #4a4d4b;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
    min-width: 100px;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    min-width: 80px;
    padding: 0.4rem 0.8rem;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  animation: modalFadeIn 0.3s ease;

  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  h3 {
    margin-bottom: 1rem;
    color: #2c3e50;
  }

  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    label {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      font-weight: 600;
      color: #2c3e50;
    }

    input,
    select {
      padding: 0.5rem;
      font-size: 1rem;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      transition: border-color 0.3s ease;

      &:focus {
        outline: none;
        border-color: #3498db;
      }
    }

    .full-width {
      grid-column: 1 / -1;
    }
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;

const Snackbar = styled.div<{ type: "success" | "error" }>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: ${(props) =>
    props.type === "success" ? "#4CAF50" : "#F44336"};
  color: white;
  border-radius: 4px;
  z-index: 1000;
`;

export default RawMaterialManagement;
