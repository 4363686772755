import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, CircularProgress } from "@mui/material";
import { apiEndpoints } from "../../../../utils/apiEndpoints";
import { callApi } from "../../../../utils/apiHelper";
import { Sku, RawMaterial, User } from "./types";
import ProjectComponent from "./projects";
import CustomerOrderComponent from "./customerOrder";
import Godown from "./Godown";
import Warehouse from "./warehouse";
import TransactionPage from "./Transaction";
import AcceptedBills from "./acceptedBills";
import EntryExitPerformaComponet from "./entryExitPerforma";
import NotificationComponent from "./notification";

// import Users from "./Tabs/Users";
// import SKUs from "./Tabs/SKUs";
// import RawMaterials from "./Tabs/RawMaterials";
// import PurchaseOrders from "./Tabs/PurchaseOrders";
// import EntryExitPerforma from "./Tabs/EntryExitPerforma";
// import Notifications from "./Tabs/Notifications";
// import Storage from "./Tabs/Storage";
// import StockTransactions from "./Tabs/StockTransactions";
// import ProductionProjects from "./Tabs/ProductionProjects";
// import CustomerOrderReturns from "./Tabs/CustomerOrderReturns";
// import AcceptedBills from "./Tabs/AcceptedBills";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const DataManagement: React.FC = () => {
  const [value, setValue] = useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const [rawMaterials, setRawMaterials] = useState<RawMaterial[]>([]);
  const [skus, setSkus] = useState<Sku[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const [usersResponse, rawMaterialsResponse, skusResponse] =
        await Promise.all([
          callApi<{ users: User[] }>(apiEndpoints.getAllVisibleUsers, "POST", {
            operation: "getAllVisibleUsers",
          }),
          callApi<{ rawMaterials: RawMaterial[] }>(
            apiEndpoints.getAllVisibleRawMaterials,
            "GET",
          ),
          callApi<{ skus: Sku[] }>(apiEndpoints.NulookGetSku, "GET"),
        ]);

      if (
        usersResponse.data &&
        rawMaterialsResponse.data &&
        skusResponse.data
      ) {
        setUsers(usersResponse.data.users);
        setRawMaterials(rawMaterialsResponse.data.rawMaterials);
        setSkus(skusResponse.data.skus);
      } else {
        setError("Failed to fetch data");
      }
    } catch (err) {
      setError("An error occurred while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="data management tabs"
        >
          <Tab label="Godown" />
          <Tab label="Storage" />
          <Tab label="Stock Transactions" />
          <Tab label="Production Projects" />
          <Tab label="Customer Orders" />
          <Tab label="Accepted Bills" />
          <Tab label="Entry/Exit Performa" />
          <Tab label="Notifications" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Godown skus={skus} users={users} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Warehouse users={users} rawMaterials={rawMaterials} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TransactionPage
          users={users}
          rawMaterials={rawMaterials}
          skus={skus}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ProjectComponent
          users={users}
          skus={skus}
          rawMaterials={rawMaterials}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CustomerOrderComponent users={users} skus={skus} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <AcceptedBills users={users} rawMaterials={rawMaterials} />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <EntryExitPerformaComponet users={users} />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <NotificationComponent users={users} />
      </TabPanel>
    </Box>
  );
};

export default DataManagement;
