import React, { useState, useEffect } from "react";
import { callApi } from "../../utils/apiHelper";
import { apiEndpoints } from "../../utils/apiEndpoints";
import { logout } from "../../utils/apiHelper";
import ProcessingDashboardUI from "./ProcessingElements/ProcessingDashboardUI";
import {
  Project,
  Sku,
  RawMaterial,
  User,
  Notification,
} from "./ProcessingElements/types";
import Header from "./ProcessingElements/Header";
import ProcessNotification from "./ProcessingElements/Notification";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import ListIcon from "@mui/icons-material/List";
import NotificationsIcon from "@mui/icons-material/Notifications";

const StyledBottomNavigation = styled(BottomNavigation)({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
});

const ContentWrapper = styled("div")({
  paddingBottom: "56px", // Height of the BottomNavigation
});

const ProcessingDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"projects" | "notifications">(
    "projects",
  );
  const [projects, setProjects] = useState<Project[]>([]);
  const [skus, setSkus] = useState<Sku[]>([]);
  const [rawMaterials, setRawMaterials] = useState<RawMaterial[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    fetchData();
    fetchUsers();
    fetchNotifications();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await callApi<{ users: User[] }>(
        apiEndpoints.getAllVisibleUsers,
        "POST",
        { operation: "getAllVisibleUsers" },
      );
      if (response.data) {
        const filteredUsers = response.data.users.filter(
          (user) => user.Role === 5 || user.Role === 8 || user.Role === 4,
        );
        setUsers(filteredUsers);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const [projectsResponse, skusResponse, rawMaterialsResponse] =
        await Promise.all([
          callApi<{ projects: Project[] }>(
            apiEndpoints.getProjectInfo,
            "POST",
            { operation: "get_all_projects" },
          ),
          callApi<{ skus: Sku[] }>(apiEndpoints.NulookGetSku, "GET"),
          callApi<{ rawMaterials: RawMaterial[] }>(
            apiEndpoints.getAllVisibleRawMaterials,
            "GET",
          ),
        ]);

      if (
        projectsResponse.data &&
        skusResponse.data &&
        rawMaterialsResponse.data
      ) {
        setProjects(projectsResponse.data.projects);
        setSkus(skusResponse.data.skus);
        setRawMaterials(rawMaterialsResponse.data.rawMaterials);
      } else {
        setError("Failed to fetch data");
      }
    } catch (err) {
      setError("An error occurred while fetching data");
    } finally {
      setIsLoading(false);
    }
  };
  const fetchNotifications = async () => {
    setIsLoading(true);
    setError("");
    try {
      const response = await callApi(
        apiEndpoints.NulookGetUserNotifications,
        "GET",
      );
      let notifications = (response.data as any).notifications;
      if (notifications && Array.isArray(notifications)) {
        setNotifications(notifications);
      } else {
        setError("Invalid response format");
      }
    } catch (error) {
      setError("Error fetching notifications");
    } finally {
      setIsLoading(false);
    }
  };
  const handleLogout = async () => {
    try {
      await logout();
      // Redirect to login page or handle logout success
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <>
      <Header onLogout={logout} />
      <ContentWrapper>
        {activeTab === "projects" && (
          <ProcessingDashboardUI
            projects={projects}
            skus={skus}
            rawMaterials={rawMaterials}
            isLoading={isLoading}
            error={error}
            users={users}
            onLogout={handleLogout}
          />
        )}

        {activeTab === "notifications" && (
          <ProcessNotification
            users={users}
            notifications={notifications}
            skus={skus}
            onNotificationProcessed={fetchNotifications}
          />
        )}
      </ContentWrapper>
      <Paper elevation={3}>
        <StyledBottomNavigation
          value={activeTab}
          onChange={(event, newValue) => {
            setActiveTab(newValue);
          }}
          showLabels
        >
          <BottomNavigationAction
            label="Projects"
            value="projects"
            icon={<ListIcon />}
          />
          <BottomNavigationAction
            label="Notifications"
            value="notifications"
            icon={<NotificationsIcon />}
          />
        </StyledBottomNavigation>
      </Paper>
    </>
  );
};

export default ProcessingDashboard;
