import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Box,
  Chip,
  TablePagination,
  createTheme,
  ThemeProvider,
  useMediaQuery,
  Card,
  CardContent,
  Grid,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Sku } from "./types";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

interface GodownInventorySectionProps {
  skus: Sku[];
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    background: {
      default: "#ffffff",
      paper: "#f5f5f5",
    },
    text: {
      primary: "#000000",
      secondary: "#404040",
    },
  },
});

type SortField =
  | "name"
  | "quantity"
  | "bQuantity"
  | "pendingOrderQuantity"
  | "pendingBOrderQuantity";
type SortOrder = "asc" | "desc";

const GodownInventorySection: React.FC<GodownInventorySectionProps> = ({
  skus,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterBy, setFilterBy] = useState<"all" | "inStock" | "outOfStock">(
    "all",
  );
  const [sortField, setSortField] = useState<SortField>("name");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const filteredAndSortedSkus = useMemo(() => {
    return skus
      .filter((sku) => {
        const matchesSearch =
          sku.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          sku.Code.toLowerCase().includes(searchTerm.toLowerCase());

        if (filterBy === "inStock") {
          return matchesSearch && sku.Quantity > 0;
        } else if (filterBy === "outOfStock") {
          return matchesSearch && sku.Quantity === 0;
        }
        return matchesSearch;
      })
      .sort((a, b) => {
        let comparison = 0;
        switch (sortField) {
          case "name":
            comparison = a.Name.localeCompare(b.Name);
            break;
          case "quantity":
            comparison = a.Quantity - b.Quantity;
            break;
          case "bQuantity":
            comparison = a.BQuantity - b.BQuantity;
            break;
          case "pendingOrderQuantity":
            comparison = a.PendingOrderQuantity - b.PendingOrderQuantity;
            break;
          case "pendingBOrderQuantity":
            comparison = a.PendingBOrderQuantity - b.PendingBOrderQuantity;
            break;
        }
        return sortOrder === "asc" ? comparison : -comparison;
      });
  }, [skus, searchTerm, filterBy, sortField, sortOrder]);

  const MobileCard: React.FC<{ sku: Sku }> = ({ sku }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" component="div">
          {sku.Name}
        </Typography>
        <Typography color="text.secondary" gutterBottom>
          SKU Code: {sku.Code}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2">
              Available Qty: {sku.Quantity}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              Available B-Qty: {sku.BQuantity}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              Pending Order Qty: {sku.PendingOrderQuantity}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              Pending B-Order Qty: {sku.PendingBOrderQuantity}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Chip
              label={sku.Quantity > 0 ? "In Stock" : "Out of Stock"}
              color={sku.Quantity > 0 ? "success" : "error"}
              size="small"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const SortButton: React.FC<{ field: SortField; label: string }> = ({
    field,
    label,
  }) => (
    <Button
      variant="outlined"
      size="small"
      onClick={() => handleSort(field)}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>{label}</span>
      {sortField === field ? (
        sortOrder === "asc" ? (
          <ArrowUpwardIcon fontSize="small" />
        ) : (
          <ArrowDownwardIcon fontSize="small" />
        )
      ) : (
        <ArrowUpwardIcon fontSize="small" sx={{ opacity: 0.5 }} />
      )}
    </Button>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Godown Inventory
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
          <TextField
            variant="outlined"
            label="Search SKUs"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Button
              variant={filterBy === "all" ? "contained" : "outlined"}
              onClick={() => setFilterBy("all")}
              sx={{ flex: 1, mr: 1 }}
            >
              All
            </Button>
            <Button
              variant={filterBy === "inStock" ? "contained" : "outlined"}
              onClick={() => setFilterBy("inStock")}
              sx={{ flex: 1, mr: 1 }}
            >
              In Stock
            </Button>
            <Button
              variant={filterBy === "outOfStock" ? "contained" : "outlined"}
              onClick={() => setFilterBy("outOfStock")}
              sx={{ flex: 1 }}
            >
              Out of Stock
            </Button>
          </Box>
          {isMobile && (
            <Grid container spacing={1} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <SortButton field="name" label="Name" />
              </Grid>
              <Grid item xs={6}>
                <SortButton field="quantity" label="Qty" />
              </Grid>
              <Grid item xs={6}>
                <SortButton field="bQuantity" label="B-Qty" />
              </Grid>
              <Grid item xs={6}>
                <SortButton field="pendingOrderQuantity" label="Pending" />
              </Grid>
              <Grid item xs={6}>
                <SortButton field="pendingBOrderQuantity" label="Pending B" />
              </Grid>
            </Grid>
          )}
        </Box>
        {isMobile ? (
          <>
            {filteredAndSortedSkus
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((sku) => (
                <MobileCard key={sku.SkuId} sku={sku} />
              ))}
          </>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="inventory table">
              <TableHead>
                <TableRow>
                  <TableCell>SKU Code</TableCell>
                  <TableCell>
                    <SortButton field="name" label="Name" />
                  </TableCell>
                  <TableCell align="right">
                    <SortButton field="quantity" label="Available Quantity" />
                  </TableCell>
                  <TableCell align="right">
                    <SortButton
                      field="bQuantity"
                      label="Available B-Quantity"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <SortButton
                      field="pendingOrderQuantity"
                      label="Pending Order Quantity"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <SortButton
                      field="pendingBOrderQuantity"
                      label="Pending B-Order Quantity"
                    />
                  </TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAndSortedSkus
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((sku) => (
                    <TableRow key={sku.SkuId}>
                      <TableCell component="th" scope="row">
                        {sku.Code}
                      </TableCell>
                      <TableCell>{sku.Name}</TableCell>
                      <TableCell align="right">{sku.Quantity}</TableCell>
                      <TableCell align="right">{sku.BQuantity}</TableCell>
                      <TableCell align="right">
                        {sku.PendingOrderQuantity}
                      </TableCell>
                      <TableCell align="right">
                        {sku.PendingBOrderQuantity}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={sku.Quantity > 0 ? "In Stock" : "Out of Stock"}
                          color={sku.Quantity > 0 ? "success" : "error"}
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredAndSortedSkus.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </ThemeProvider>
  );
};

export default GodownInventorySection;
