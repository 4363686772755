import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { callApi } from "../../../utils/apiHelper";
import { apiEndpoints } from "../../../utils/apiEndpoints";

interface User {
  UserId: string;
  FullName: string;
  PhoneNumber: string;
  Role: number;
  Status: boolean;
}

interface UserManagementResponse {
  users: User[];
}

const roles = [
  { value: 1, label: "Manager" },
  { value: 2, label: "Customer" },
  { value: 3, label: "Vendor" },
  { value: 4, label: "Warehouse" },
  { value: 5, label: "Processing" },
  { value: 6, label: "Security" },
  { value: 7, label: "Cleaning" },
  { value: 8, label: "Godown" },
];

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<"create" | "edit" | "password">(
    "create",
  );
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [formData, setFormData] = useState({
    FullName: "",
    PhoneNumber: "",
    Role: 1,
    password: "",
    permission: [] as string[],
    subDepartmentIds: [] as string[],
  });
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
    type: "success" | "error";
  }>({
    show: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await callApi<UserManagementResponse>(
        apiEndpoints.NulookErpManageUsers,
        "POST",
        { operation: "get_all" },
      );
      if (response.data && response.data.users) {
        setUsers(response.data.users.filter((user) => user.Role !== 0));
      }
    } catch (error) {
      showSnackbar("Failed to fetch users", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    if (name === "password" && modalMode === "password") {
      setNewPassword(value);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let operation = modalMode === "create" ? "create" : "update";
      let payload: any = {
        operation,
        ...formData,
        UserId: selectedUser?.UserId,
        permission: [],
        subDepartmentIds: [],
      };

      if (modalMode === "password") {
        operation = "change_password";
        payload = {
          operation,
          UserId: selectedUser?.UserId,
          NewPassword: newPassword,
        };
      }

      await callApi(apiEndpoints.NulookErpManageUsers, "POST", payload);
      fetchUsers();
      setIsModalOpen(false);
      setFormData({
        FullName: "",
        PhoneNumber: "",
        Role: 1,
        password: "",
        permission: [],
        subDepartmentIds: [],
      });
      setNewPassword("");
      showSnackbar(
        `User ${operation === "create" ? "created" : "updated"} successfully`,
        "success",
      );
    } catch (error) {
      showSnackbar(`Failed to ${modalMode} user`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = async (user: User) => {
    setIsLoading(true);
    try {
      const payload = {
        operation: "toggle_status",
        UserId: user.UserId,
        Status: !user.Status,
      };
      await callApi(apiEndpoints.NulookErpManageUsers, "POST", payload);
      fetchUsers();
      showSnackbar(
        `User status ${user.Status ? "disabled" : "enabled"} successfully`,
        "success",
      );
    } catch (error) {
      showSnackbar("Failed to change user status", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = (mode: "create" | "edit" | "password", user?: User) => {
    setModalMode(mode);
    setSelectedUser(user || null);
    if (user && mode === "edit") {
      setFormData({
        FullName: user.FullName,
        PhoneNumber: user.PhoneNumber,
        Role: user.Role,
        password: "",
        permission: [],
        subDepartmentIds: [],
      });
    } else {
      setFormData({
        FullName: "",
        PhoneNumber: "",
        Role: 1,
        password: "",
        permission: [],
        subDepartmentIds: [],
      });
    }
    setNewPassword("");
    setIsModalOpen(true);
  };

  const showSnackbar = (message: string, type: "success" | "error") => {
    setSnackbar({ show: true, message, type });
    setTimeout(
      () => setSnackbar({ show: false, message: "", type: "success" }),
      3000,
    );
  };

  const filteredUsers = users.filter((user) =>
    user.FullName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Container>
      <Header>
        <h2>User Management</h2>
        <SearchBar
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button onClick={() => openModal("create")}>Create New User</Button>
      </Header>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Role</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.UserId}>
                <td>{user.FullName}</td>
                <td>{user.PhoneNumber}</td>
                <td>{roles.find((r) => r.value === user.Role)?.label}</td>
                <td>{user.Status ? "Active" : "Inactive"}</td>
                <td>
                  <ActionButtons>
                    <Button onClick={() => openModal("edit", user)}>
                      Edit
                    </Button>
                    <Button onClick={() => openModal("password", user)}>
                      Change Password
                    </Button>
                    <Button onClick={() => handleStatusChange(user)}>
                      {user.Status ? "Disable" : "Enable"}
                    </Button>
                  </ActionButtons>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      {isModalOpen && (
        <Modal>
          <ModalContent>
            <h3>
              {modalMode === "create"
                ? "Create New User"
                : modalMode === "edit"
                  ? "Edit User"
                  : "Change Password"}
            </h3>
            <form onSubmit={handleSubmit}>
              {modalMode !== "password" && (
                <>
                  <input
                    type="text"
                    name="FullName"
                    placeholder="Full Name"
                    value={formData.FullName}
                    onChange={handleInputChange}
                    required
                  />
                  <input
                    type="tel"
                    name="PhoneNumber"
                    placeholder="Phone Number"
                    value={formData.PhoneNumber}
                    onChange={handleInputChange}
                    required
                  />
                  <select
                    name="Role"
                    value={formData.Role}
                    onChange={handleInputChange}
                    required
                  >
                    {roles.map((role) => (
                      <option key={role.value} value={role.value}>
                        {role.label}
                      </option>
                    ))}
                  </select>
                </>
              )}
              {(modalMode === "create" || modalMode === "password") && (
                <input
                  type="password"
                  name="password"
                  placeholder={
                    modalMode === "password" ? "New Password" : "Password"
                  }
                  value={
                    modalMode === "password" ? newPassword : formData.password
                  }
                  onChange={handleInputChange}
                  required
                />
              )}
              <Button type="submit">
                {modalMode === "create"
                  ? "Create"
                  : modalMode === "edit"
                    ? "Update"
                    : "Change Password"}
              </Button>
            </form>
            <Button
              onClick={() => setIsModalOpen(false)}
              style={{
                marginTop: "1rem",
                width: "100%",
              }}
            >
              Cancel
            </Button>
          </ModalContent>
        </Modal>
      )}
      {isLoading && (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      )}
      {snackbar.show && (
        <Snackbar type={snackbar.type}>{snackbar.message}</Snackbar>
      )}
    </Container>
  );
};

const Container = styled.div`
  font-family: "Space Grotesk", sans-serif;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 1200px;
`;

const SearchBar = styled.input`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 25px;
  transition: all 0.3s ease;
  width: 200px;

  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
    width: 220px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Table = styled.table`
  width: 100%;
  max-width: 1200px;
  border-collapse: separate;
  border-spacing: 0;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  th,
  td {
    text-align: center;
    padding: 1rem;
    border-bottom: 1px solid #eee;
  }

  th {
    background-color: #f8f8f8;
    color: #2c3e50;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  tr:last-child td {
    border-bottom: none;
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const Button = styled.button`
  background-color: #000000;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px;
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #4a4d4b;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
    min-width: 100px;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    min-width: 80px;
    padding: 0.4rem 0.8rem;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  animation: modalFadeIn 0.3s ease;

  @keyframes modalFadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  h3 {
    margin-bottom: 1rem;
    color: #2c3e50;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input,
    select {
      padding: 0.5rem;
      font-size: 1rem;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      transition: border-color 0.3s ease;

      &:focus {
        outline: none;
        border-color: #3498db;
      }
    }
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;

const Snackbar = styled.div<{ type: "success" | "error" }>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: ${(props) =>
    props.type === "success" ? "#4CAF50" : "#F44336"};
  color: white;
  border-radius: 4px;
  z-index: 1000;
`;

export default UserManagement;
