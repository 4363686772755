import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  Button,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import RawMaterialModal from "./RawMaterialModal";
import WastageModal from "./WastageModal";
import PassModal from "./PassModal";
import { Project, Sku, RawMaterial, User } from "./types";

interface ProcessingDashboardUIProps {
  projects: Project[];
  skus: Sku[];
  rawMaterials: RawMaterial[];
  isLoading: boolean;
  error: string | null;
  users: User[];
  onLogout: () => void;
}

const ProcessingDashboardUI: React.FC<ProcessingDashboardUIProps> = ({
  projects,
  skus,
  rawMaterials,
  isLoading,
  error,
  users,
  onLogout,
}) => {
  const [expandedProjectId, setExpandedProjectId] = useState<string | null>(
    null,
  );
  const [showTransactions, setShowTransactions] = useState<{
    [key: string]: boolean;
  }>({});
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [selectedSku, setSelectedSku] = useState<Sku | undefined>(undefined);

  const getSkuInfo = (skuId: string) => {
    const sku = skus.find((sku) => sku.SkuId === skuId);
    return {
      name: sku?.Name || "Unknown SKU",
      baseUnit: sku?.BaseUnit || "Unknown Unit",
    };
  };

  const getLastPastLocation = (project: Project) => {
    if (project.PastLocation && project.PastLocation.length > 0) {
      return project.PastLocation[project.PastLocation.length - 1];
    }
    return null;
  };

  const formatDate = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  const toggleProjectExpansion = (projectId: string) => {
    setExpandedProjectId(expandedProjectId === projectId ? null : projectId);
  };

  const getSelectedSku = (skuId: string): Sku | undefined => {
    return skus.find((sku) => sku.SkuId === skuId);
  };

  const openModal = (modalType: string, project: Project) => {
    setActiveModal(modalType);
    setSelectedProject(project);
    setSelectedSku(getSelectedSku(project.SkuId));
  };

  const closeModal = () => {
    setActiveModal(null);
    setSelectedProject(null);
    setSelectedSku(undefined);
  };

  const getUserName = (warehouseId: string): string => {
    const user = users.find((user) => user.UserId === warehouseId);
    return user ? user.FullName : "Unknown User";
  };

  const toggleTransactions = (projectId: string) => {
    setShowTransactions((prev) => ({
      ...prev,
      [projectId]: !prev[projectId],
    }));
  };

  return (
    <Box sx={{ bgcolor: "background.default", minHeight: "100vh", p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Active Projects
      </Typography>
      {isLoading && <Typography>Loading data...</Typography>}
      {error && <Typography color="error">{error}</Typography>}
      {!isLoading && !error && (
        <List>
          {projects.map((project) => {
            const lastPastLocation = getLastPastLocation(project);
            const isExpanded =
              expandedProjectId === project.ProductionProjectId;
            const skuInfo = getSkuInfo(project.SkuId);
            const showProjectTransactions =
              showTransactions[project.ProductionProjectId];

            return (
              <Paper
                key={project.ProductionProjectId}
                elevation={3}
                sx={{ mb: 2, overflow: "hidden" }}
              >
                <ListItem
                  onClick={() =>
                    toggleProjectExpansion(project.ProductionProjectId)
                  }
                  sx={{
                    bgcolor: "background.paper",
                    "&:hover": { bgcolor: "action.hover" },
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 16px",
                    borderRadius: 1,
                  }}
                >
                  <ListItemText
                    primary={skuInfo.name}
                    secondary={project.Status}
                  />
                  <IconButton edge="end">
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </ListItem>
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <Box sx={{ p: 2 }}>
                    {lastPastLocation && (
                      <>
                        <Typography variant="body2">
                          Start Time: {formatDate(lastPastLocation.StartTime)}
                        </Typography>
                        {lastPastLocation.EndTime && (
                          <Typography variant="body2">
                            End Time: {formatDate(lastPastLocation.EndTime)}
                          </Typography>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mt: 2,
                            mb: 1,
                            borderBottom: 1,
                            borderColor: "divider",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            toggleTransactions(project.ProductionProjectId)
                          }
                        >
                          <Typography variant="subtitle1">
                            Transactions History
                          </Typography>
                          <IconButton size="small">
                            {showProjectTransactions ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse
                          in={showProjectTransactions}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List dense>
                            {lastPastLocation.Transactions.map(
                              (transaction, index) => {
                                const transactionType =
                                  transaction.FromType === "warehouse" &&
                                  transaction.ToType === "production"
                                    ? "Credit"
                                    : transaction.FromType === "production" &&
                                        transaction.ToType === "warehouse"
                                      ? "Debit"
                                      : "Transfer";

                                return (
                                  <React.Fragment
                                    key={transaction.StockTransactionId}
                                  >
                                    {index > 0 && (
                                      <Divider variant="inset" component="li" />
                                    )}
                                    <ListItem alignItems="flex-start">
                                      <ListItemText
                                        primary={`${formatDate(transaction.CreatedAt)} - ${transactionType}`}
                                        secondary={
                                          <React.Fragment>
                                            <Typography
                                              component="span"
                                              variant="body2"
                                              color={
                                                transactionType === "Credit"
                                                  ? "success.main"
                                                  : "error.main"
                                              }
                                            >
                                              {transactionType}
                                            </Typography>
                                            <Typography
                                              component="div"
                                              variant="body2"
                                            >
                                              Materials:
                                              <ul>
                                                {transaction.Materials.map(
                                                  (material) => {
                                                    const rawMaterial =
                                                      rawMaterials.find(
                                                        (rm) =>
                                                          rm.RawMaterialId ===
                                                          material.RawMaterialId,
                                                      );
                                                    return (
                                                      <li
                                                        key={
                                                          material.RawMaterialId
                                                        }
                                                      >
                                                        {rawMaterial
                                                          ? rawMaterial.Name
                                                          : "Unknown"}
                                                        : {material.Quantity}{" "}
                                                        {rawMaterial
                                                          ? rawMaterial.BaseUnit
                                                          : ""}
                                                      </li>
                                                    );
                                                  },
                                                )}
                                              </ul>
                                            </Typography>
                                          </React.Fragment>
                                        }
                                      />
                                    </ListItem>
                                  </React.Fragment>
                                );
                              },
                            )}
                          </List>
                        </Collapse>
                      </>
                    )}
                    <Typography variant="body2">Current Quantity:</Typography>
                    <Typography variant="body2">
                      Fresh : {project.FreshQuantity} {skuInfo.baseUnit}
                    </Typography>
                    <Typography variant="body2">
                      B : {project.BQuantity} {skuInfo.baseUnit}
                    </Typography>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => openModal("rawMaterial", project)}
                      >
                        Raw Material
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => openModal("wastage", project)}
                      >
                        Wastage
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => openModal("pass", project)}
                      >
                        Pass
                      </Button>
                    </Box>
                  </Box>
                </Collapse>
              </Paper>
            );
          })}
        </List>
      )}
      {activeModal === "rawMaterial" && selectedProject && (
        <RawMaterialModal
          project={selectedProject}
          onClose={closeModal}
          rawMaterials={rawMaterials}
          sku={selectedSku}
          users={users}
        />
      )}
      {activeModal === "wastage" && selectedProject && (
        <WastageModal
          project={selectedProject}
          onClose={closeModal}
          rawMaterials={rawMaterials}
          sku={selectedSku}
          users={users}
        />
      )}
      {activeModal === "pass" && selectedProject && (
        <PassModal
          project={selectedProject}
          onClose={closeModal}
          rawMaterials={rawMaterials}
          sku={selectedSku}
          users={users}
        />
      )}
    </Box>
  );
};

export default ProcessingDashboardUI;
