import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { callApi } from "../../../../utils/apiHelper";
import { apiEndpoints } from "../../../../utils/apiEndpoints";
import { GodownStorage, Sku, User } from "./types";

interface GodownProps {
  skus: Sku[];
  users: User[];
}

type SortField = "updatedAt" | "quantity" | "bQuantity";
type SortOrder = "asc" | "desc";

const Godown: React.FC<GodownProps> = ({ skus, users }) => {
  const [skuStock, setSkuStock] = useState<GodownStorage[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<SortField>("updatedAt");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");

  async function getSkuStock() {
    try {
      setLoading(true);
      const response = await callApi<any>(apiEndpoints.DataFetcher, "POST", {
        operation: "getSkuStock",
      });
      if (response.data) {
        setSkuStock(response.data.data);
      } else if (response.error) {
        throw new Error(response.error.message);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getSkuStock();
  }, []);

  const getSkuName = (skuId: string) => {
    const sku = skus.find((s) => s.SkuId === skuId);
    return sku ? sku.Name : "Unknown SKU";
  };

  const getGodownName = (godownId: string) => {
    const user = users.find((u) => u.UserId === godownId);
    return user ? user.FullName : godownId;
  };

  const formatDate = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  const sortedAndFilteredSkuStock = useMemo(() => {
    let filteredStock = skuStock.filter((stock) => {
      const searchTermLower = searchTerm.toLowerCase();
      const skuNameMatch = getSkuName(stock.SkuId)
        .toLowerCase()
        .includes(searchTermLower);
      const godownNameMatch = getGodownName(stock.GodownId)
        .toLowerCase()
        .includes(searchTermLower);
      return skuNameMatch || godownNameMatch;
    });

    return filteredStock.sort((a, b) => {
      const compareValues = (valA: any, valB: any) => {
        if (valA < valB) return sortOrder === "asc" ? -1 : 1;
        if (valA > valB) return sortOrder === "asc" ? 1 : -1;
        return 0;
      };

      switch (sortField) {
        case "updatedAt":
          return compareValues(a.UpdatedAt, b.UpdatedAt);
        case "quantity":
          return compareValues(a.Quantity, b.Quantity);
        case "bQuantity":
          return compareValues(a.BQuantity, b.BQuantity);
        default:
          return 0;
      }
    });
  }, [skuStock, searchTerm, sortField, sortOrder, skus, users]);

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const SortButton = ({
    field,
    children,
  }: {
    field: SortField;
    children: React.ReactNode;
  }) => (
    <SortButtonStyled onClick={() => handleSort(field)}>
      {children} {sortField === field && (sortOrder === "asc" ? "▲" : "▼")}
    </SortButtonStyled>
  );

  if (loading) {
    return <div>Loading godown stock...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <GodownContainer>
      <h2>Godown Stock</h2>
      <SearchBar>
        <input
          type="text"
          placeholder="Search by SKU Name or Godown Name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchBar>
      {sortedAndFilteredSkuStock.length === 0 ? (
        <p>No stock found.</p>
      ) : (
        <ResponsiveTableContainer>
          <ResponsiveTable>
            <thead>
              <tr>
                <th>
                  <SortButton field="updatedAt">Updated At</SortButton>
                </th>
                <th>SKU Name</th>
                <th>Godown Name</th>
                <th>
                  <SortButton field="quantity">Quantity</SortButton>
                </th>
                <th>
                  <SortButton field="bQuantity">B Quantity</SortButton>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedAndFilteredSkuStock.map((stock) => (
                <tr key={stock.GodownStorageId}>
                  <td data-label="Updated At">{formatDate(stock.UpdatedAt)}</td>
                  <td data-label="SKU Name">{getSkuName(stock.SkuId)}</td>
                  <td data-label="Godown Name">
                    {getGodownName(stock.GodownId)}
                  </td>
                  <td data-label="Quantity">{stock.Quantity}</td>
                  <td data-label="B Quantity">{stock.BQuantity}</td>
                </tr>
              ))}
            </tbody>
          </ResponsiveTable>
        </ResponsiveTableContainer>
      )}
    </GodownContainer>
  );
};

const GodownContainer = styled.div`
  margin-top: 2rem;
  color: #333;
  font-family: Arial, sans-serif;
`;

const SearchBar = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 600px;

  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      font-size: 0.9rem;
      padding: 0.4rem;
    }
  }
`;

const ResponsiveTableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background-color: #fff;
  font-size: 0.9rem;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f8f8f8;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;

    th,
    td {
      padding: 6px;
    }
  }
`;

const SortButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export default Godown;
