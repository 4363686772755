// src/components/warehouseDashboard/WarehouseDashboard.tsx

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { callApi, checkCurrentAuthState, logout } from "../../utils/apiHelper";
import Header from "./WarehouseElements/Header";
import NotificationProcessing from "./WarehouseElements/NotificationProcessing";
import InventoryDisplay from "./WarehouseElements/InventoryDisplay";
import { apiEndpoints } from "../../utils/apiEndpoints";
import { ApiRawMaterial } from "./WarehouseElements/types";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InventoryIcon from "@mui/icons-material/Inventory";

const WarehouseDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [allRawMaterials, setAllRawMaterials] = useState<ApiRawMaterial[]>([]);
  const [value, setValue] = useState(0);

  const fetchAllRawMaterials = async () => {
    try {
      const response = await callApi(apiEndpoints.InventoryDisplay, "GET");
      console.log(response.data);
      if (response.data && (response.data as any).stock) {
        setAllRawMaterials((response.data as any).stock);
      }
    } catch (error) {
      console.error("Error fetching raw materials:", error);
    }
  };

  useEffect(() => {
    checkCurrentAuthState({
      onAuthenticated: async () => {
        // User is authenticated, fetch raw materials
        await fetchAllRawMaterials();
      },
      onUnauthenticated: () => {
        navigate("/login");
      },
    });
  }, [navigate]);

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <DashboardContainer>
      <Header onLogout={handleLogout} />
      <main>
        {value === 0 ? (
          <NotificationProcessing allRawMaterials={allRawMaterials} />
        ) : (
          <InventoryDisplay allRawMaterials={allRawMaterials} />
        )}
      </main>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="Notifications"
            icon={<NotificationsIcon />}
          />
          <BottomNavigationAction label="Inventory" icon={<InventoryIcon />} />
        </BottomNavigation>
      </Paper>
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  font-family: "Space Grotesk", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex: 1;
    padding: 1rem;
    margin-bottom: 56px; // Add margin to accommodate the bottom navigation bar
  }
`;

export default WarehouseDashboard;
