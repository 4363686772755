import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { callApi } from "../../../utils/apiHelper";
import { apiEndpoints } from "../../../utils/apiEndpoints";

interface RawMaterial {
  RawMaterialId: string;
  Name?: string;
  Quantity: number;
  Price: number;
}

interface RawMaterialWithBaseUnit extends RawMaterial {
  BaseUnit: string;
}

interface PurchaseOrder {
  PurchaseOrderId: string;
  VendorId: string;
  VendorName: string;
  TotalPrice: number;
  Status: string;
  CreatedAt: number;
  UpdatedAt: number;
  RawMaterials: RawMaterial[];
  HtmlUrl: string;
}

interface PurchaseOrderResponse {
  PurchaseOrders: PurchaseOrder[];
}
interface UserResponse {
  users: Vendor[];
}
interface Vendor {
  UserId: string;
  FullName: string;
  PhoneNumber: string;
  Role: Number;
}

interface RawMaterialResponse {
  rawMaterials: RawMaterialWithBaseUnit[];
}

const PurchaseOrderManagement: React.FC = () => {
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<"create" | "edit" | "view">(
    "create",
  );
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] =
    useState<PurchaseOrder | null>(null);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [rawMaterials, setRawMaterials] = useState<RawMaterialWithBaseUnit[]>(
    [],
  );
  const [formData, setFormData] = useState<Partial<PurchaseOrder>>({
    VendorId: "",
    RawMaterials: [],
  });
  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
    type: "success" | "error";
  }>({
    show: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    fetchPurchaseOrders();
    fetchVendors();
    fetchRawMaterials();
  }, []);

  const fetchPurchaseOrders = async () => {
    setIsLoading(true);
    try {
      const response = await callApi<PurchaseOrderResponse>(
        apiEndpoints.NulookErpPurchaseOrderManagement,
        "POST",
        { operation: "get_all" },
      );
      if (response.data) {
        setPurchaseOrders(response.data.PurchaseOrders);
      }
    } catch (error) {
      showSnackbar("Failed to fetch purchase orders", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchVendors = async () => {
    const response = await callApi<UserResponse>(
      apiEndpoints.getAllVisibleUsers,
      "POST",
      {
        operation: "getAllVisibleUsers",
      },
    );
    if (response.data) {
      setVendors(response.data.users.filter((user) => user.Role === 3));
    }
  };

  const fetchRawMaterials = async () => {
    const response = await callApi<RawMaterialResponse>(
      apiEndpoints.getAllVisibleRawMaterials,
      "GET",
    );
    console.log(response.data);
    if (response.data) {
      setRawMaterials(response.data.rawMaterials);
      console.log(response.data.rawMaterials);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRawMaterialChange = (
    index: number,
    field: string,
    value: string | number,
  ) => {
    setFormData((prevData) => {
      const updatedRawMaterials = [...(prevData.RawMaterials || [])];
      if (field === "RawMaterialId") {
        const selectedMaterial = rawMaterials.find(
          (rm) => rm.RawMaterialId === value,
        );
        updatedRawMaterials[index] = {
          ...updatedRawMaterials[index],
          RawMaterialId: value as string,
          Price: selectedMaterial ? selectedMaterial.Price : 0,
        };
      } else {
        updatedRawMaterials[index] = {
          ...updatedRawMaterials[index],
          [field]: Number(value),
        };
      }
      return { ...prevData, RawMaterials: updatedRawMaterials };
    });
  };

  const addRawMaterial = () => {
    setFormData((prevData) => ({
      ...prevData,
      RawMaterials: [
        ...(prevData.RawMaterials || []),
        { RawMaterialId: "", Quantity: 0, Price: 0 },
      ],
    }));
  };

  const removeRawMaterial = (index: number) => {
    setFormData((prevData) => ({
      ...prevData,
      RawMaterials: (prevData.RawMaterials || []).filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (modalMode === "edit" && selectedPurchaseOrder?.Status === "Cancelled") {
      showSnackbar("Cancelled purchase orders cannot be edited", "error");
      return;
    }

    setIsLoading(true);
    try {
      const operation = modalMode === "create" ? "create" : "update";
      const payload = {
        operation,
        ...formData,
        PurchaseOrderId: selectedPurchaseOrder?.PurchaseOrderId,
      };

      await callApi(
        apiEndpoints.NulookErpPurchaseOrderManagement,
        "POST",
        payload,
      );
      fetchPurchaseOrders();
      setIsModalOpen(false);
      setFormData({
        VendorId: "",
        RawMaterials: [],
      });
      showSnackbar(
        `Purchase order ${operation === "create" ? "created" : "updated"} successfully`,
        "success",
      );
    } catch (error) {
      showSnackbar(`Failed to ${modalMode} purchase order`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelPurchaseOrder = async (purchaseOrderId: string) => {
    setIsLoading(true);
    try {
      await callApi(apiEndpoints.NulookErpPurchaseOrderManagement, "POST", {
        operation: "cancel",
        PurchaseOrderId: purchaseOrderId,
      });
      fetchPurchaseOrders();
      showSnackbar("Purchase order cancelled successfully", "success");
    } catch (error) {
      showSnackbar("Failed to cancel purchase order", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = (
    mode: "create" | "edit" | "view",
    purchaseOrder?: PurchaseOrder,
  ) => {
    if (mode === "edit" && purchaseOrder?.Status === "Cancelled") {
      showSnackbar("Cancelled purchase orders cannot be edited", "error");
      return;
    }

    setModalMode(mode);
    setSelectedPurchaseOrder(purchaseOrder || null);
    if (purchaseOrder && mode !== "create") {
      setFormData({
        ...purchaseOrder,
      });
    } else {
      setFormData({
        VendorId: "",
        RawMaterials: [],
      });
    }
    setIsModalOpen(true);
  };

  const showSnackbar = (message: string, type: "success" | "error") => {
    setSnackbar({ show: true, message, type });
    setTimeout(
      () => setSnackbar({ show: false, message: "", type: "success" }),
      3000,
    );
  };

  const filteredPurchaseOrders = purchaseOrders.filter(
    (order) =>
      order.VendorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.PurchaseOrderId.includes(searchTerm),
  );

  const calculateTotal = () => {
    return (
      formData.RawMaterials?.reduce(
        (total, rm) => total + rm.Quantity * rm.Price,
        0,
      ) || 0
    );
  };

  return (
    <Container>
      <Header>
        <h2>Purchase Order Management</h2>
        <SearchBar
          type="text"
          placeholder="Search Purchase Orders..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button onClick={() => openModal("create")}>
          Create New Purchase Order
        </Button>
      </Header>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Vendor</th>
              <th>Total Price</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredPurchaseOrders.map((order) => (
              <tr key={order.PurchaseOrderId}>
                <td>{order.PurchaseOrderId}</td>
                <td>{order.VendorName}</td>
                <td>Rs {order.TotalPrice.toFixed(2)}</td>
                <td>{order.Status}</td>
                <td>{new Date(order.CreatedAt * 1000).toLocaleString()}</td>
                <td>
                  <ActionButtons>
                    <Button onClick={() => openModal("view", order)}>
                      View Details
                    </Button>
                    <Button
                      onClick={() => openModal("edit", order)}
                      disabled={order.Status === "Cancelled"}
                    >
                      Update
                    </Button>
                    {order.Status !== "Cancelled" && (
                      <Button
                        onClick={() =>
                          handleCancelPurchaseOrder(order.PurchaseOrderId)
                        }
                      >
                        Cancel Order
                      </Button>
                    )}
                  </ActionButtons>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      {isModalOpen && (
        <Modal>
          <ModalContent>
            <h3>
              {modalMode === "create"
                ? "Create New Purchase Order"
                : modalMode === "edit"
                  ? "Edit Purchase Order"
                  : "View Purchase Order"}
            </h3>
            <form onSubmit={handleSubmit}>
              <label>
                Vendor
                <select
                  name="VendorId"
                  value={formData.VendorId}
                  onChange={handleInputChange}
                  required
                  disabled={modalMode === "view"}
                >
                  <option value="">Select a vendor</option>
                  {vendors.map((vendor) => (
                    <option key={vendor.UserId} value={vendor.UserId}>
                      {vendor.FullName}
                    </option>
                  ))}
                </select>
              </label>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h4>Raw Materials</h4>
                {modalMode !== "view" && (
                  <Button
                    type="button"
                    onClick={addRawMaterial}
                    style={{
                      padding: "0.25rem 0.5rem",
                      fontSize: "0.8rem",
                      borderRadius: "4px",
                    }}
                  >
                    Add Raw Material
                  </Button>
                )}
              </div>
              {formData.RawMaterials?.map((rm, index) => (
                <RawMaterialItem key={index}>
                  <select
                    value={rm.RawMaterialId}
                    onChange={(e) =>
                      handleRawMaterialChange(
                        index,
                        "RawMaterialId",
                        e.target.value,
                      )
                    }
                    required
                    disabled={modalMode === "view"}
                  >
                    <option value="">Select a raw material</option>
                    {rawMaterials.map((material) => (
                      <option
                        key={material.RawMaterialId}
                        value={material.RawMaterialId}
                      >
                        {material.Name}
                      </option>
                    ))}
                  </select>
                  <QuantityInputWrapper>
                    <input
                      type="number"
                      value={rm.Quantity}
                      onChange={(e) =>
                        handleRawMaterialChange(
                          index,
                          "Quantity",
                          e.target.value,
                        )
                      }
                      placeholder="Quantity"
                      required
                      disabled={modalMode === "view"}
                    />
                    <BaseUnitLabel>
                      {rawMaterials.find(
                        (m) => m.RawMaterialId === rm.RawMaterialId,
                      )?.BaseUnit || ""}
                    </BaseUnitLabel>
                  </QuantityInputWrapper>

                  <PriceInputWrapper>
                    <PricePrefix>Rs</PricePrefix>
                    <input
                      type="number"
                      value={rm.Price}
                      onChange={(e) =>
                        handleRawMaterialChange(index, "Price", e.target.value)
                      }
                      placeholder="Price"
                      required
                      disabled={modalMode === "view"}
                      style={{ textAlign: "right" }}
                    />
                  </PriceInputWrapper>
                  {modalMode !== "view" && (
                    <Button
                      type="button"
                      onClick={() => removeRawMaterial(index)}
                    >
                      Remove
                    </Button>
                  )}
                </RawMaterialItem>
              ))}
              <TotalWrapper>
                <strong>Total:</strong> Rs {calculateTotal().toFixed(2)}
              </TotalWrapper>
              {modalMode !== "view" && (
                <Button type="submit">
                  {modalMode === "create" ? "Create" : "Update"}
                </Button>
              )}
            </form>
            {modalMode === "view" && selectedPurchaseOrder?.HtmlUrl && (
              <a
                href={selectedPurchaseOrder.HtmlUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Purchase Order PDF
              </a>
            )}
            <Button
              onClick={() => setIsModalOpen(false)}
              style={{
                marginTop: "20px",
                width: "100%",
              }}
            >
              Close
            </Button>
          </ModalContent>
        </Modal>
      )}
      {isLoading && (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      )}
      {snackbar.show && (
        <Snackbar type={snackbar.type}>{snackbar.message}</Snackbar>
      )}
    </Container>
  );
};

const Container = styled.div`
  font-family: "Space Grotesk", sans-serif;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 1200px;
`;

const SearchBar = styled.input`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 25px;
  transition: all 0.3s ease;
  width: 200px;

  &:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
    width: 220px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Table = styled.table`
  width: 100%;
  max-width: 1200px;
  border-collapse: separate;
  border-spacing: 0;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  th,
  td {
    text-align: center;
    padding: 1rem;
    border-bottom: 1px solid #eee;
  }

  th {
    background-color: #f8f8f8;
    color: #2c3e50;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  tr:last-child td {
    border-bottom: none;
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const Button = styled.button<{ disabled?: boolean }>`
  background-color: ${(props) => (props.disabled ? "#cccccc" : "#000000")};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.3s ease;
  min-width: 120px;
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${(props) => (props.disabled ? "#cccccc" : "#4a4d4b")};
    transform: ${(props) => (props.disabled ? "none" : "translateY(-2px)")};
    box-shadow: ${(props) =>
      props.disabled
        ? "0 2px 5px rgba(0, 0, 0, 0.1)"
        : "0 4px 8px rgba(0, 0, 0, 0.15)"};
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
    min-width: 100px;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    min-width: 80px;
    padding: 0.4rem 0.8rem;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;

const Snackbar = styled.div<{ type: "success" | "error" }>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: ${(props) =>
    props.type === "success" ? "#4CAF50" : "#F44336"};
  color: white;
  border-radius: 4px;
  z-index: 1000;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  h3 {
    margin-bottom: 1.5rem;
    color: #2c3e50;
    font-size: 1.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-weight: 600;
    color: #34495e;
  }

  select,
  input {
    padding: 0.3rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 0.9rem;
    transition: border-color 0.3s ease;

    &:focus {
      outline: none;
      border-color: #3498db;
    }
  }

  h4 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: #2c3e50;
  }
`;

const RawMaterialItem = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;

  select,
  input {
    flex: 1;
    padding: 0.3rem;
    font-size: 0.9rem;
  }

  button {
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
    min-width: auto;
  }
`;

const QuantityInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const BaseUnitLabel = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #7f8c8d;
  font-size: 0.9rem;
`;

const PriceInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
`;

const PricePrefix = styled.span`
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: #7f8c8d;
  font-size: 0.9rem;
`;

const TotalWrapper = styled.div`
  margin-top: 1rem;
  text-align: right;
  font-size: 1.1rem;
  color: #2c3e50;
`;

export default PurchaseOrderManagement;
