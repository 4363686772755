import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { checkCurrentAuthState, logout } from "../../utils/apiHelper";
import Header from "./SecurityElements/Header";
import PurchaseOrderProcessing from "./SecurityElements/PurchaseOrderProcessing";

const SecurityDashboard: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    checkCurrentAuthState({
      onAuthenticated: async () => {
        // User is authenticated, do nothing
      },
      onUnauthenticated: () => {
        navigate("/login");
      },
    });
  }, [navigate]);

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <DashboardContainer>
      <Header onLogout={handleLogout} />
      <main>
        <PurchaseOrderProcessing />
      </main>
    </DashboardContainer>
  );
};

const DashboardContainer = styled.div`
  font-family: "Space Grotesk", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex: 1;
    padding: 0.5rem;
  }
`;

export default SecurityDashboard;
