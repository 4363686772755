import React from "react";
import styled from "styled-components";

interface HeaderProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  onLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({
  activeTab,
  setActiveTab,
  onLogout,
}) => {
  const companyName = process.env.REACT_APP_COMPANY_NAME || "Company Name";

  return (
    <StyledHeader>
      <HeaderContent>
        <CompanyName>{companyName}</CompanyName>
        <NavMenu>
          <NavButton
            active={activeTab === "users"}
            onClick={() => setActiveTab("users")}
          >
            User
          </NavButton>
          <NavButton
            active={activeTab === "skus"}
            onClick={() => setActiveTab("skus")}
          >
            SKU
          </NavButton>
          <NavButton
            active={activeTab === "rawMaterials"}
            onClick={() => setActiveTab("rawMaterials")}
          >
            Raw Material
          </NavButton>
          <NavButton
            active={activeTab === "purchaseOrders"}
            onClick={() => setActiveTab("purchaseOrders")}
          >
            Purchase Order
          </NavButton>
          <NavButton
            active={activeTab === "notifications"}
            onClick={() => setActiveTab("notifications")}
          >
            Notification
          </NavButton>
          <NavButton
            active={activeTab === "transactions"}
            onClick={() => setActiveTab("transactions")}
          >
            Transaction
          </NavButton>
          <NavButton
            active={activeTab === "data"}
            onClick={() => setActiveTab("data")}
          >
            Data
          </NavButton>
          <LogoutButton onClick={onLogout}>Logout</LogoutButton>
        </NavMenu>
      </HeaderContent>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  padding: 1rem 2rem;
  font-family: "Space Grotesk", sans-serif;
  display: flex;
  justify-content: center;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const CompanyName = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #2c3e50;
`;

const NavMenu = styled.nav`
  display: flex;
  gap: 1rem;
`;

const NavButton = styled.button<{ active: boolean }>`
  background: none;
  border: none;
  font-family: "Space Grotesk", sans-serif;
  font-size: 1rem;
  color: ${(props) => (props.active ? "#2c3e50" : "#7f8c8d")};
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  border-radius: 25px;

  &:hover {
    background-color: ${(props) => (props.active ? "#e0e0e0" : "#f8f8f8")};
  }
`;

const LogoutButton = styled.button`
  background-color: #000000;
  color: white;
  border: none;
  font-family: "Space Grotesk", sans-serif;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
  min-width: 100px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #4a4d4b;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
`;

export default Header;
