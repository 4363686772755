import React, { useState } from "react";
import styled from "styled-components";
import { Notification, ApiRawMaterial, User } from "./types";
import { callApi } from "../../../utils/apiHelper";
import { apiEndpoints } from "../../../utils/apiEndpoints";

interface RawMaterialReturnNotificationProps {
  notification: Notification;
  allRawMaterials: ApiRawMaterial[];
  allUsers: User[];
  onProcessed: () => void;
}

const RawMaterialReturnNotification: React.FC<
  RawMaterialReturnNotificationProps
> = ({ notification, allRawMaterials, allUsers, onProcessed }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [approvedQuantities, setApprovedQuantities] = useState<
    Record<string, string>
  >({});
  const dataDump = JSON.parse(notification.DataDump);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const getRawMaterial = (id: string) => {
    return allRawMaterials.find((m) => m.RawMaterialId === id);
  };

  const getUserName = (id: string) => {
    const user = allUsers.find((u) => u.UserId === id);
    return user ? user.FullName : id;
  };

  const handleQuantityChange = (rawMaterialId: string, quantity: string) => {
    setApprovedQuantities((prev) => ({
      ...prev,
      [rawMaterialId]: quantity,
    }));
  };

  const processRequest = async (accept: boolean) => {
    setIsProcessing(true);
    try {
      const approvedQuantitiesNumber: Record<string, number> = {};
      for (const [key, value] of Object.entries(approvedQuantities)) {
        approvedQuantitiesNumber[key] = parseFloat(value);
      }

      const response = await callApi(
        apiEndpoints.AcceptRejectRawMaterialRequestProject,
        "POST",
        {
          NotificationId: notification.NotificationId,
          accept: accept,
          ApprovedQuantity: approvedQuantitiesNumber,
        },
      );

      if (response.error) {
        throw new Error(response.error.message);
      }

      alert(
        accept
          ? "Return accepted successfully"
          : "Return rejected successfully",
      );
      onProcessed();
    } catch (error) {
      console.error("Error processing return:", error);
      alert("Failed to process return. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <NotificationCard>
      <NotificationHeader onClick={toggleExpand}>
        <NotificationId>ID: {notification.NotificationId}</NotificationId>
        <NotificationType>Raw Material Return</NotificationType>
        <ExpandIcon>{isExpanded ? "▼" : "►"}</ExpandIcon>
      </NotificationHeader>
      {isExpanded && (
        <ExpandedContent>
          <InfoGrid>
            <InfoItem>
              <Label>Project ID:</Label>
              <Value>{dataDump.ProjectId}</Value>
            </InfoItem>
            <InfoItem>
              <Label>Created By:</Label>
              <Value>{getUserName(notification.CreatedBy)}</Value>
            </InfoItem>
            <InfoItem>
              <Label>Created At:</Label>
              <Value>
                {new Date(notification.CreatedAt * 1000).toLocaleString()}
              </Value>
            </InfoItem>
          </InfoGrid>
          <RawMaterialsList>
            <h4>Returned Raw Materials:</h4>
            {dataDump.RawMaterials.map((material: any, index: number) => {
              const rawMaterial = getRawMaterial(material.RawMaterialId);
              return (
                <RawMaterialItem key={index}>
                  <MaterialInfo>
                    <MaterialName>
                      {rawMaterial?.Name || "Unknown"}
                    </MaterialName>
                    <ReturnedQuantity>
                      Returned: {material.Quantity} {rawMaterial?.BaseUnit}
                    </ReturnedQuantity>
                  </MaterialInfo>
                  <QuantityInputWrapper>
                    <QuantityInput
                      type="number"
                      value={approvedQuantities[material.RawMaterialId] || ""}
                      onChange={(e) =>
                        handleQuantityChange(
                          material.RawMaterialId,
                          e.target.value,
                        )
                      }
                      placeholder="Approve"
                      min={0}
                      max={material.Quantity}
                      step="0.01"
                    />
                    <UnitLabel>{rawMaterial?.BaseUnit}</UnitLabel>
                  </QuantityInputWrapper>
                </RawMaterialItem>
              );
            })}
          </RawMaterialsList>
          <ButtonContainer>
            <ProcessButton
              onClick={() => processRequest(true)}
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Accept"}
            </ProcessButton>
            <RejectButton
              onClick={() => processRequest(false)}
              disabled={isProcessing}
            >
              {isProcessing ? "Processing..." : "Reject"}
            </RejectButton>
          </ButtonContainer>
        </ExpandedContent>
      )}
    </NotificationCard>
  );
};

const NotificationCard = styled.div`
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  overflow: hidden;
`;

const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f8f8;
  cursor: pointer;
`;

const NotificationId = styled.span`
  font-weight: bold;
  font-size: 0.9rem;
`;

const NotificationType = styled.span`
  color: #666;
  font-style: italic;
  font-size: 0.9rem;
`;

const ExpandIcon = styled.span`
  font-size: 1.2rem;
`;

const ExpandedContent = styled.div`
  padding: 1rem;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-size: 0.8rem;
  color: #666;
`;

const Value = styled.span`
  font-weight: bold;
  font-size: 0.9rem;
`;

const RawMaterialsList = styled.div`
  margin-top: 1rem;
`;

const RawMaterialItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 0.75rem;
  margin-bottom: 0.75rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const MaterialInfo = styled.div`
  flex: 1;
  margin-bottom: 0.5rem;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const MaterialName = styled.div`
  font-weight: bold;
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
`;

const ReturnedQuantity = styled.div`
  font-size: 0.8rem;
  color: #666;
`;

const QuantityInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const QuantityInput = styled.input`
  width: 80px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
`;

const UnitLabel = styled.span`
  margin-left: 0.5rem;
  font-size: 0.8rem;
  color: #666;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
`;

const ProcessButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  flex: 1;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  @media (min-width: 768px) {
    flex: 0 1 auto;
  }
`;

const RejectButton = styled(ProcessButton)`
  background-color: #f44336;

  &:hover {
    background-color: #d32f2f;
  }
`;

export default RawMaterialReturnNotification;
