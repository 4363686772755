import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { callApi } from "../../../../utils/apiHelper";
import { apiEndpoints } from "../../../../utils/apiEndpoints";
import { Notification, User } from "./types";

interface NotificationComponentProps {
  users: User[];
}

type SortField = "createdAt" | "status" | "notificationFor";
type SortOrder = "asc" | "desc";

const NotificationComponent: React.FC<NotificationComponentProps> = ({
  users,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState<SortField>("createdAt");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");

  async function getNotifications() {
    try {
      setLoading(true);
      const response = await callApi<any>(apiEndpoints.DataFetcher, "POST", {
        operation: "getNotifications",
      });
      if (response.data) {
        setNotifications(response.data.data);
      } else if (response.error) {
        throw new Error(response.error.message);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getNotifications();
  }, []);

  const formatDate = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  const getUserName = (userId: string) => {
    const user = users.find((u) => u.UserId === userId);
    return user ? user.FullName : userId;
  };

  const sortedAndFilteredNotifications = useMemo(() => {
    let filteredNotifications = notifications.filter((notification) => {
      const searchTermLower = searchTerm.toLowerCase();
      return (
        notification.Status.toLowerCase().includes(searchTermLower) ||
        getUserName(notification.NotificationFor)
          .toLowerCase()
          .includes(searchTermLower) ||
        getUserName(notification.CreatedBy)
          .toLowerCase()
          .includes(searchTermLower)
      );
    });

    return filteredNotifications.sort((a, b) => {
      const compareValues = (valA: any, valB: any) => {
        if (valA < valB) return sortOrder === "asc" ? -1 : 1;
        if (valA > valB) return sortOrder === "asc" ? 1 : -1;
        return 0;
      };

      switch (sortField) {
        case "createdAt":
          return compareValues(a.CreatedAt, b.CreatedAt);
        case "status":
          return compareValues(a.Status, b.Status);
        case "notificationFor":
          return compareValues(
            getUserName(a.NotificationFor),
            getUserName(b.NotificationFor),
          );
        default:
          return 0;
      }
    });
  }, [notifications, searchTerm, sortField, sortOrder, users]);

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const SortButton = ({
    field,
    children,
  }: {
    field: SortField;
    children: React.ReactNode;
  }) => (
    <SortButtonStyled onClick={() => handleSort(field)}>
      {children} {sortField === field && (sortOrder === "asc" ? "▲" : "▼")}
    </SortButtonStyled>
  );

  if (loading) {
    return <div>Loading notifications...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <NotificationContainer>
      <h2>Notifications</h2>
      <SearchBar>
        <input
          type="text"
          placeholder="Search by Status, Notification For, or Created By"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchBar>
      {sortedAndFilteredNotifications.length === 0 ? (
        <p>No notifications found.</p>
      ) : (
        <ResponsiveTableContainer>
          <ResponsiveTable>
            <thead>
              <tr>
                <th>
                  <SortButton field="createdAt">Created At</SortButton>
                </th>

                <th>
                  <SortButton field="status">Status</SortButton>
                </th>
                <th>
                  <SortButton field="notificationFor">
                    Notification For
                  </SortButton>
                </th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {sortedAndFilteredNotifications.map((notification) => (
                <tr key={notification.NotificationId}>
                  <td data-label="Created At">
                    {formatDate(notification.CreatedAt)}
                  </td>

                  <td data-label="Status">{notification.Status}</td>
                  <td data-label="Notification For">
                    {getUserName(notification.NotificationFor)}
                  </td>
                  <td data-label="Created By">
                    {getUserName(notification.CreatedBy)}
                  </td>
                </tr>
              ))}
            </tbody>
          </ResponsiveTable>
        </ResponsiveTableContainer>
      )}
    </NotificationContainer>
  );
};

const NotificationContainer = styled.div`
  margin-top: 2rem;
  color: #333;
  font-family: Arial, sans-serif;
`;

const SearchBar = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 600px;

  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 767px) {
      font-size: 0.9rem;
      padding: 0.4rem;
    }
  }
`;

const ResponsiveTableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background-color: #fff;
  font-size: 0.9rem;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f8f8f8;
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;

    th,
    td {
      padding: 6px;
    }
  }
`;

const SortButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

export default NotificationComponent;
