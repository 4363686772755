import React from "react";
import styled from "styled-components";

interface HeaderProps {
  onLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ onLogout }) => {
  const companyName = process.env.REACT_APP_COMPANY_NAME || "Company Name";

  return (
    <StyledHeader>
      <CompanyName>{companyName}</CompanyName>
      <NavMenu>
        <LogoutButton onClick={onLogout}>Logout</LogoutButton>
      </NavMenu>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompanyName = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
  color: #2c3e50;
`;

const NavMenu = styled.nav`
  display: flex;
  gap: 1rem;
`;

const LogoutButton = styled.button`
  background-color: #000000;
  color: white;
  border: none;
  font-family: "Space Grotesk", sans-serif;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 25px;
  min-width: 100px;

  &:hover {
    background-color: #4a4d4b;
  }
`;

export default Header;
